/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div
    class="relative flex flex-col justify-center items-center fadeInOut w-full h-full"
    :style="{ opacity: opacity }"
  >
    <div class="flex flex-wrap justify-between items-center relative text-white h-full">
      <div
        v-for="(image, i) in state.images"
        :key="i"
        class="flex borderedImage imageMedium overflow-hidden items-end"
        :class="imageClasses(image)"
        :style="imageStyles(image, fromOwnUser(image))"
      >
        <img
          v-cache
          class="flex self-center w-full h-full object-contain bg-white"
          :src="image.imageUrl"
          @click="clickAnswer(image)"
        />
        <div class="flex absolute z-50 justify-center items-center m-2">
          <div
            v-if="fromParticipantUser(image)"
            class="rounded-full bg-white p-1 relative h-11 w-11 flex justify-center items-center m-px"
            :style="imageStyles(image)"
          >
            <Avatar :avatar-ref="participantInformation.avatar.ref" />
          </div>
          <div
            v-if="fromOwnUser(image)"
            class="rounded-full bg-white p-1 relative h-11 w-11 flex justify-center items-center m-px"
            :style="imageStyles(image, true)"
          >
            <Avatar :avatar-ref="user.getters.myUser.value.avatar.ref" />
          </div>
        </div>
      </div>
    </div>

    <span class="borderedWordBox" @click="playWordAudio()">
      {{ taskRound.instructionBeforeWord }}
      <span class="wordHighlight">{{
        taskRound.word === StemType.Stem ? props.task.stem : props.task.morphedStem
      }}</span>
      {{ taskRound.instructionAfterWord }}</span
    >
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, computed, onMounted, reactive, watch } from 'vue'
import { TaskSync, Tracking, Choice } from '@/models/main'
import { useI18n } from 'vue-i18n'

import useState from '@/composition/useState'
import { shuffleItems } from '@/utilities'
import {
  SpeechSounds,
  TaskMode,
  StemType,
  TaskCallbackType,
  TaskCallback,
  UserTaskRole,
  TaskSyncImage,
  DialogMessageType,
  TaskCallbackParam,
  SyncType,
  FinalDecisionStatus
} from '@/constants'
import { Tasktype2mp } from '@/models/tasktypes/Tasktype2mp'
import { SolutionUnion, Image } from '@/models/tasktypes'

import useDialogStore from '@/composition/dialog'
import useMultiPlayerState from '@/composition/useMultiplayerState'
import useColorStore from '@/composition/colors'
import useUserStore from '@/store/useUserStore'

import Avatar from '@/components/base/Avatar.vue'
import { createSound } from '@/api/audioService'
import { WebAudio } from '@/models/audio'
import moment from 'moment'

const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()

const emit = defineEmits<{
  (e: 'completed', value: boolean, tracking: Tracking): void
  (e: 'addSync', taskId: string, solution: SolutionUnion, solutionId: string): void
  (e: 'updateShuffleOrder', shuffleOrder: string[]): void
  (e: 'registerCallback', type: TaskCallbackType, callback: TaskCallback): void
  (
    e: 'showResultMessage',
    messageType: DialogMessageType,
    solution: Image,
    callback: () => void
  ): void
}>()

const props = defineProps({
  task: { required: true, type: Object as PropType<Tasktype2mp> },
  myIndex: { required: false, type: Number, default: 0 },
  actionAllowed: { required: true, type: Boolean },

  firstDecision: { required: false, type: Object as PropType<TaskSyncImage | null>, default: null },
  advice: { required: false, type: Object as PropType<TaskSyncImage | null>, default: null },
  finalDecision: { required: false, type: Object as PropType<TaskSyncImage | null>, default: null },

  role: { required: true, type: String }
})

const state = reactive({
  images: [] as Image[],
  initialImages: [] as Image[]
})

const { t } = useI18n()
const dialog = useDialogStore()
const color = useColorStore()
const multiplayer = useMultiPlayerState()
const user = useUserStore()

const tracking = new Tracking(stateGetters.tracking.value)
stateSetters.trackingData = tracking
let choiceTimer = new Date()

// 'correct' is the count of correctly answered items. 'of' is the total allocated correct items
const roundData = { round1: { correct: 0, of: 0 }, round2: { correct: 0, of: 0 } }
const opacity = ref(0)
let playingAudio = false
let textAudio: WebAudio
let instructionAudio: WebAudio
const unforgivingTestMode = false
const round = ref(1)
let attempts = 0
let choiceAttempt = 1
let finished = false

const participantInformation = multiplayer.getters.participantInformation

const currentPhase = multiplayer.getters.phase

const taskRound = computed(() => {
  return round.value === 1 ? props.task.round1 : props.task.round2
})

/* HOOKS */

onMounted(async () => {
  emit('registerCallback', TaskCallbackType.FinalDecisionAccept, finalDecisionAcceptedCallback)
  emit('registerCallback', TaskCallbackType.FinalDecisionReject, finalDecisionRejectedCallback)
  emit('registerCallback', TaskCallbackType.Shuffle, shuffleCallback)
  emit('registerCallback', TaskCallbackType.ShuffleOnRequest, ShuffleOnRequestCallback)

  await setupTask()
})

/* MP STATE CALLBACKS */

const finalDecisionAcceptedCallback = (decision: TaskCallbackParam) => {
  submitFinalDecision((decision as TaskSync).solution as Image)
}

function finalDecisionRejectedCallback(args: TaskCallbackParam) {
  const solution = (args as TaskSync).solution as Image
  const choice = new Choice()
  choice.attempt = choiceAttempt
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.committed = true
  choice.round = round.value
  choice.response = 'Start oppgave på nytt'
  choice.content = state.images.map((i) => i.filename).join(';')
  choice.target = state.images // Order of this list shows what order they were presented to the user
    .filter((i) => (round.value === 1 ? i.correct1 : i.correct2))
    .map((j) => j.filename)
    .join(';')
  choice.correct = choice.target.includes(solution.filename)
  choice.phase = Object.values(SyncType).indexOf(currentPhase.value)
  choice.valid = true
  choice.finaldecisionstatus = 'Rejected' //I prefer hard coding, since I dont have full overview of the store value
  tracking.choices.push(choice)
  stateSetters.trackingData = tracking
  choiceTimer = new Date()

  choiceAttempt++
  multiplayer.actions.resetSync() // reset for current round
  resetImageSelection(args as TaskSync)
}

/* Advisor */
const shuffleCallback = (args: TaskCallbackParam): void => {
  const shuffledItems: Image[] = []
  let newIndex = 0
  for (const item of args as string[]) {
    const index = state.initialImages.findIndex((image) => image.id === item)
    shuffledItems[newIndex] = state.initialImages[index]
    newIndex++
  }
  state.images = shuffledItems.filter((i) => i)
}

const ShuffleOnRequestCallback = () => {
  // re-shuffle after the advisor has finished the task
  shuffleAndSync(state.images) // trigger shuffle parcel after advisor has switched task/round
}

const setupTask = async () => {
  // Choose random locations
  //let firstItem = Math.random() > 0.5 ? '' : 'itemB';
  //let secondItem = firstItem === 'itemA' ? 'itemB' : 'itemA';
  finished = false
  playingAudio = false
  state.images = []
  if (typeof props.task === 'undefined' || props.task === null) {
    alert('A Type 2MP task does not exist - check your Session layout in the CMS')
    return
  }

  const tempImages = []
  for (let i = 0; i < props.task.images.length; i++) {
    const entry = props.task.images[i]
    const image: Image = {
      id: `image-id-${i}`,
      index: i,
      filename: entry.filename,
      imageUrl: entry.file || '',
      correct1: entry.correct1,
      correct2: entry.correct2,
      enabled: true,
      borderGreen: false,
      borderRed: false
    }

    if (image.correct1 && round.value === 1) {
      roundData.round1.of++
    }
    if (image.correct2 && round.value === 2) {
      roundData.round2.of++
    }
    tempImages.push(image)
  }

  for (const item of state.images) {
    item.borderGreen = false
    item.borderRed = false
  }

  stateActions.progress.progressShow(round.value === 1 ? roundData.round1.of : roundData.round2.of)
  if (taskRound.value.wordAudio) {
    textAudio = await createSound(taskRound.value.wordAudio)
    textAudio.onended = () => {
      playingAudio = false
    }
  }

  setTimeout(() => {
    opacity.value = 1
    introduceChallenge()
  }, 1000)
  shuffleAndSync(tempImages)
}

// Reset the tracking timer when the phase changes, so we are not including the other player's time
watch(multiplayer.getters.phase, () => {
  choiceTimer = new Date()
})

const shuffleAndSync = (images: Image[]) => {
  const shuffledItems = shuffleItems(images) as Image[]
  if (state.initialImages.length === 0) state.initialImages = shuffledItems
  state.images = shuffledItems // set this value, regardless of the role -> this is also used to set the initial images for both participants
  if (props.role === UserTaskRole.Leader) {
    // only the leader gets to sync their order
    const shuffleOrder = shuffledItems.map((image) => {
      return image.id
    })
    emit('updateShuffleOrder', shuffleOrder) // sync the re-shuffled words
  }
}

const fromParticipantUser = (image: Image) => {
  return (
    (props.role === UserTaskRole.Leader && image.id === props.advice?.solution_id) ||
    (props.role === UserTaskRole.Advisor && image.id === props.firstDecision?.solution_id)
  )
}

const fromOwnUser = (image: Image) => {
  return (
    (props.role === UserTaskRole.Leader && image.id === props.firstDecision?.solution_id) ||
    (props.role === UserTaskRole.Advisor && image.id === props.advice?.solution_id)
  )
}

const imageClasses = (image: Image) => {
  return { borderGreen: image.borderGreen, borderRed: image.borderRed }
}

const imageStyles = (image: Image, ownUser = false) => {
  if (
    [
      props.firstDecision?.solution_id,
      props.advice?.solution_id,
      props.finalDecision?.solution_id
    ].some((id) => id === image.id)
  ) {
    const userName = ownUser
      ? user.getters.myUser.value.profile.username
      : participantInformation.value.name
    return `border: 5px solid ${color.actions.selectColor(userName)};`
  }
}

const introduceChallenge = async () => {
  attempts = 0
  stateSetters.speakerIsPlaying = true
  if (taskRound.value.instructionAudio) {
    instructionAudio = await createSound(taskRound.value.instructionAudio)
    instructionAudio.onended = () => {
      stateActions.setSpeakerSound([taskRound.value.instructionAudio])
      stateSetters.speakerIsPlaying = false
    }
  }
  setTimeout(() => {
    if (taskRound.value.instructionAudio) {
      instructionAudio.playWhenReady()
    }
  }, 500)
}

const playWordAudio = () => {
  if (!playingAudio && textAudio) {
    playingAudio = true
    textAudio.playWhenReady()
    tracking.use_audio_content_items++
  }
}

const resetImageSelection = (decision: TaskSync) => {
  const index = state.images.findIndex((i) => i.id === decision.solution_id)
  if (index !== -1) {
    // remove any borders (if existing)
    const image = state.images[index]
    image.borderGreen = false
    image.borderRed = false
  }
}

const clickAnswer = (item: Image) => {
  if (item.borderRed) {
    return //Ignore clicks on already tried and wrong images (causes bug if attempted as answer)
  }
  if (props.actionAllowed) {
    const choice = new Choice()
    choice.attempt = choiceAttempt
    choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
    choice.response = item.filename
    choice.committed = true
    choice.round = round.value
    choice.content = state.images.map((i) => i.filename).join(';')
    choice.target = state.images // Order of this list shows what order they were presented to the user
      .filter((i) => (round.value === 1 ? i.correct1 : i.correct2))
      .map((j) => j.filename)
      .join(';')
    choice.correct = choice.target.includes(item.filename)
    choice.phase = Object.values(SyncType).indexOf(currentPhase.value)
    choice.valid = true
    choice.finaldecisionstatus = 'NotSubmitted' //I prefer hard coding, since I dont have full overview of the store value
    tracking.choices.push(choice)
    choiceTimer = new Date()
    stateSetters.trackingData = tracking
    emit('addSync', props.task.id, item, item.id)
  } else dialog.actions.pushMessage(DialogMessageType.Warning, t('waitforturn'))
}

const submitFinalDecision = (item: Image) => {
  //item is either the leader or the advisor pick, depending on value of FinalDecisionStatus (.Accepted vs .AcceptedAdvice vs .Confirmed),
  //which is set depending on what was clicked in DecisionConfirmation.vue
  const choice = new Choice()
  choice.attempt = choiceAttempt
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.committed = true
  choice.round = round.value
  choice.content = state.images.map((i) => i.filename).join(';')
  choice.response = item.filename
  choice.target = state.images // Order of this list shows what order they were presented to the user
    .filter((i) => (round.value === 1 ? i.correct1 : i.correct2))
    .map((j) => j.filename)
    .join(';')
  choice.correct = choice.target.includes(item.filename)
  choice.phase = Object.values(SyncType).indexOf(currentPhase.value)
  choice.valid = true
  choice.finaldecisionstatus = multiplayer.getters.finalDecisionStatus.value
  tracking.choices.push(choice)
  stateSetters.trackingData = tracking
  choiceTimer = new Date()

  const resultsRound = round.value === 1 ? roundData.round1 : roundData.round2

  if (item.enabled && !finished) {
    attempts++
    const round1Correct = item.correct1 && round.value === 1
    const round2Correct = item.correct2 && round.value === 2
    const listItem = state.initialImages.find((i) => i.id === item.id)
    if (listItem && props.role === UserTaskRole.Advisor) item = listItem // this will force the rendered item to be the currently selected one, otherwise border highlighting does not work
    if (round1Correct || round2Correct) {
      resultsRound.correct++
      stateActions.progress.completeAStar() // complete star
      const callback = () => {
        item.borderGreen = true
        // this ensures that the right image gets the border... order in array could be different
        if (props.role === UserTaskRole.Advisor) {
          const index = state.images.findIndex((i) => i.id === item.id)
          if (index > -1) state.images[index].borderGreen = true // update value for state vars
        }
        // the stars are going to be "reset" (due to round progression) here
        multiplayer.actions.proceedRoundIndex()
        nextRound()
      }
      emit('showResultMessage', DialogMessageType.ResultSplashCorrect, item, callback)
    } else {
      //wasAcceptedAdviceClicked must be calculated outside the callback, since callback excecutes when FinalDecisionStatus may be reset.
      const wasAcceptedAdviceClicked =
        multiplayer.getters.finalDecisionStatus.value === FinalDecisionStatus.AcceptedAdvice
          ? true
          : false
      const wasConfirmClicked =
        multiplayer.getters.finalDecisionStatus.value === FinalDecisionStatus.Confirmed
          ? true
          : false
      const callback = () => {
        multiplayer.actions.resetSync() // reset for current round because selection was wrong
        choiceAttempt++
        //item.borderRed = true makes the border red when clicking "Accept first choice" for leader,
        //but not when accepting a wrong advice. Hence the code below.
        item.borderRed = true
        if (props.role === UserTaskRole.Advisor || wasAcceptedAdviceClicked || wasConfirmClicked) {
          const index = state.images.findIndex((i) => i.id === item.id)
          if (index > -1) state.images[index].borderRed = true // update value for state vars
        }
        if (unforgivingTestMode && attempts === resultsRound.of) nextRound()
      }
      emit('showResultMessage', DialogMessageType.ResultSplashWrong, item, callback)
    }
    item.enabled = false
  }
}

const nextRound = () => {
  finished = true

  if (round.value === 1) {
    round.value = 2
    choiceAttempt = 1
    //This fixes a bug, where red borders persisted for advisor on round 2.
    for (const image of state.images) {
      image.borderGreen = false
      image.borderRed = false
    }
    setupTask()
  } else {
    if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
      stateActions.speakLocalised(
        SpeechSounds.instructions.warmups.T2,
        () => {
          opacity.value = 0
          completeTask()
        },
        1000,
        false
      )
    } else {
      opacity.value = 0
      completeTask()
    }
  }
}

const completeTask = () => {
  setTimeout(() => {
    emit('completed', true, tracking)
  }, 1000)
}
</script>

<style scoped></style>
