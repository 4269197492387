/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div
    class="relative flex flex-col justify-center items-center fadeInOut w-full h-full"
    :style="{ opacity: opacity }"
  >
    <div class="scanner-content flex flex-col justify-center border-2 rounded-2xl p-11">
      <div class="flex text-white flex-row justify-around scanner-padding">
        <div class="flex flex-col justify-start">
          <div class="flex flex-row justify-start">
            <span class="text-2xl lg:text-3xl" @click="clickFirstSentence()">{{
              task.sentence1text
            }}</span>
          </div>

          <div class="flex flex-row justify-start items-center">
            <div class="flex flex-col justify-center">
              <span class="text-2xl lg:text-3xl" @click="clickSecondSentenceBefore()"
                >{{ task.sentence2TextBefore }}&nbsp;</span
              >
            </div>

            <transition mode="out-in" name="fade">
              <div v-if="showPlaceholders" class="flex flex-col justify-center">
                <span
                  class="wordHighlight text-2xl lg:text-3xl"
                  @click="clickSecondSentenceBefore()"
                  >{{ task.sentence2TrailingWord }}</span
                >
              </div>
            </transition>

            <div class="flex flex-col">
              <!-- Drop boxes -->
              <div
                v-if="!droppedItem1 && showPlaceholders && !box1disabled"
                id="word-box-1"
                class="borderedTaskWordBox relative rounded-full m-2 w-36 h-16 drop-target"
                @start="onStart"
                @stop="onDrop"
                @touchstart="preventDropOnTap"
              ></div>

              <transition mode="out-in" name="fade">
                <div v-if="droppedItem1 && showPlaceholders" class="flex flex-col justify-center">
                  <span
                    class="wordHighlight text-2xl lg:text-3xl"
                    :class="{ yellowWordText: droppedItem1.id !== 'drag-word-preselected' }"
                    @click.stop="playWordAudio(droppedItem1)"
                    >{{ droppedItem1.text || '' }}</span
                  >
                </div>
              </transition>

              <div
                v-if="
                  (props.role === UserTaskRole.Advisor && props.firstDecision) ||
                  (props.role === UserTaskRole.Leader && props.advice && showPlaceholders)
                "
                class="rounded-3xl mx-2"
              >
                <transition mode="out-in" name="fade">
                  <div
                    v-if="
                      props.role === UserTaskRole.Advisor &&
                      props.firstDecision &&
                      !combinedWord &&
                      getDecisionDetails('firstDecision', 'word-box-1')
                    "
                    class="flex justify-center dialog-bg border-2 rounded-full items-center mt-2"
                  >
                    <Avatar
                      class="w-12 h-12 p-1 m-2 rounded-full"
                      :style="userStyle(participantInformation.name)"
                      :avatar-ref="participantInformation.avatar.ref"
                    />
                    <p
                      class="wordHighlight text-2xl lg:text-3xl p-3"
                      :style="wordStyles()"
                      @click.stop="playWordAudio(firstDecision)"
                    >
                      {{ getDecisionDetails('firstDecision', 'word-box-1') }}
                    </p>
                  </div>
                </transition>

                <transition mode="out-in" name="fade">
                  <div
                    v-if="
                      props.role === UserTaskRole.Leader &&
                      props.advice &&
                      !combinedWord &&
                      getDecisionDetails('advice', 'word-box-1')
                    "
                    class="flex justify-center dialog-bg border-2 rounded-full items-center mt-2"
                  >
                    <Avatar
                      class="w-12 h-12 p-1 m-2 rounded-full"
                      :style="userStyle(participantInformation.name)"
                      :avatar-ref="participantInformation.avatar.ref"
                    />
                    <p
                      class="wordHighlight text-2xl lg:text-3xl p-3"
                      :style="wordStyles()"
                      @click.stop="playWordAudio(advice)"
                    >
                      {{ getDecisionDetails('advice', 'word-box-1') }}
                    </p>
                  </div>
                </transition>
              </div>
            </div>

            <div class="flex flex-col">
              <div
                v-if="!droppedItem2 && showPlaceholders && !box2disabled"
                id="word-box-2"
                class="borderedTaskWordBox relative rounded-full m-2 w-36 h-16 drop-target"
                @start="onStart"
                @stop="onDrop"
                @touchstart="preventDropOnTap"
              ></div>

              <transition mode="out-in" name="fade">
                <div v-if="droppedItem2 && showPlaceholders" class="flex flex-col justify-center">
                  <p
                    class="wordHighlight text-2xl lg:text-3xl"
                    :class="{ yellowWordText: droppedItem2.id !== 'drag-word-preselected' }"
                    @click.stop="playWordAudio(droppedItem2)"
                  >
                    {{ droppedItem2.text || '' }}&nbsp;
                  </p>
                </div>
              </transition>

              <div
                v-if="
                  (props.role === UserTaskRole.Advisor && props.firstDecision) ||
                  (props.role === UserTaskRole.Leader && props.advice && showPlaceholders)
                "
                class="rounded-3xl mx-2"
              >
                <transition mode="out-in" name="fade">
                  <div
                    v-if="
                      props.role === UserTaskRole.Advisor &&
                      props.firstDecision &&
                      !combinedWord &&
                      getDecisionDetails('firstDecision', 'word-box-2')
                    "
                    class="flex justify-center dialog-bg border-2 rounded-full items-center mt-2"
                  >
                    <Avatar
                      class="w-12 h-12 p-1 m-2 rounded-full"
                      :style="userStyle(participantInformation.name)"
                      :avatar-ref="participantInformation.avatar.ref"
                    />
                    <p
                      class="wordHighlight text-2xl lg:text-3xl p-3"
                      :style="wordStyles()"
                      @click.stop="playWordAudio(firstDecision)"
                    >
                      {{ getDecisionDetails('firstDecision', 'word-box-2') }}
                    </p>
                  </div>
                </transition>

                <transition mode="out-in" name="fade">
                  <div
                    v-if="
                      props.role === UserTaskRole.Leader &&
                      props.advice &&
                      !combinedWord &&
                      getDecisionDetails('advice', 'word-box-2')
                    "
                    class="flex justify-center dialog-bg border-2 rounded-full items-center mt-2"
                  >
                    <Avatar
                      class="w-12 h-12 p-1 m-2 rounded-full"
                      :style="userStyle(participantInformation.name)"
                      :avatar-ref="participantInformation.avatar.ref"
                    />
                    <p
                      class="wordHighlight text-2xl lg:text-3xl p-3"
                      :style="wordStyles()"
                      @click.stop="playWordAudio(advice)"
                    >
                      {{ getDecisionDetails('advice', 'word-box-2') }}
                    </p>
                  </div>
                </transition>
              </div>
            </div>

            <!-- Combined word -->
            <transition mode="out-in" name="fade">
              <div v-if="combinedWord && showCombinedWord" class="flex flex-col justify-center">
                <span
                  class="text-2xl lg:text-3xl wordHighlight"
                  :class="{ yellowWordText: !mergeCombinedWord }"
                  >{{ combinedWord }}&nbsp;</span
                >
              </div>
            </transition>

            <transition mode="out-in" name="fade">
              <span
                v-if="showPlaceholders"
                class="text-2xl lg:text-3xl wordHighlight"
                @click="clickSecondSentenceAfter()"
                >{{ task.sentence2SecondTrailingWord }}&nbsp;</span
              >
            </transition>
            <div class="flex flex-col justify-center">
              <span class="text-2xl lg:text-3xl" @click="clickSecondSentenceAfter()">{{
                task.sentence2TextAfter
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="props.actionAllowed && currentPhase !== SyncType.FinalDecision"
      class="flex flex-row justify-around fadeInOut mt-4 absolute bottom-11"
      :style="{ opacity: opacityWords }"
    >
      <!-- Draggable Items -->
      <Draggable
        v-for="word in state.words"
        :id="`word-index-${word.id}`"
        :key="word.id"
        :position="word.position"
        :class="`borderedTaskWordBox mx-2 cursor-grab ${activeDrags ? 'pointer-events-none touch-none' : ''}`"
        @pointerdown="onPointerDown"
        @start="(e) => onStart(e)"
        @stop="(e) => onDrop(e, word)"
      >
        <span class="taskNoUserSelect" @click.stop="playWordAudio(word)">{{ word.text }}</span>
      </Draggable>
    </div>
    <div
      v-if="
        props.role === UserTaskRole.Advisor &&
        !props.actionAllowed &&
        currentPhase !== SyncType.FinalDecision
      "
      class="flex flex-row justify-around fadeInOut mt-4 absolute bottom-11"
      :style="{ opacity: opacityWords }"
    >
      <!-- Non-draggable Items (for advisor) -->
      <div
        v-for="word in state.words"
        :id="`word-index-${word.id}`"
        :key="word.id"
        :position="word.position"
        :class="`borderedTaskWordBox mx-2 cursor-grab ${activeDrags ? 'pointer-events-none touch-none' : ''}`"
      >
        <span class="taskNoUserSelect" @click.stop="playWordAudio(word)">{{ word.text }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, Ref, onMounted, reactive, computed, toRefs, watch } from 'vue'
import { DraggableEvent } from '@braks/revue-draggable'
import { Draggable } from '@braks/revue-draggable'
import { useI18n } from 'vue-i18n'

import useState from '@/composition/useState'

import { Choice, Tracking } from '@/models/main'
import { shuffleItems } from '@/utilities'

import { Tasktype10mp } from '@/models/tasktypes/Tasktype10mp'
import { SolutionUnion, TT10Word, Type10CorrectType } from '@/models/tasktypes'
import {
  SpeechSounds,
  TaskCallbackType,
  TaskMode,
  TaskSyncWord,
  UserTaskRole,
  SyncType,
  DialogMessageType,
  TaskCallbackParam,
  TaskCallback,
  FinalDecisionStatus
} from '@/constants'

import Avatar from '@/components/base/Avatar.vue'

import useMultiPlayerState from '@/composition/useMultiplayerState'
import useColorStore from '@/composition/colors'
import { createSound } from '@/api/audioService'
import { WebAudio } from '@/models/audio'
import useDialogStore from '@/composition/dialog'
import moment from 'moment'

type DraggableDIVElementEvent = DraggableEvent & { event: { target: HTMLDivElement } }

const emit = defineEmits<{
  (e: 'completed', value: boolean, tracking: Tracking): void
  (
    e: 'addSync',
    taskId: string,
    solution: SolutionUnion | null,
    solutionId: string,
    additionalSolutions?: SolutionUnion[]
  ): void
  (e: 'updateShuffleOrder', shuffleOrder: string[]): void
  (e: 'registerCallback', type: TaskCallbackType, callback: TaskCallback): void
  (
    e: 'showResultMessage',
    messageType: DialogMessageType,
    solution: string,
    callback: () => void
  ): void
}>()

// const { t } = useI18n()

const props = defineProps({
  task: { required: true, type: Object as PropType<Tasktype10mp> },
  myIndex: { required: false, type: Number, default: 0 },
  actionAllowed: { required: true, type: Boolean },
  firstDecision: { required: false, type: Object as PropType<TaskSyncWord | null>, default: null },
  advice: { required: false, type: Object as PropType<TaskSyncWord | null>, default: null },
  finalDecision: { required: false, type: Object as PropType<TaskSyncWord | null>, default: null },
  role: { required: true, type: String }
})
const { task } = toRefs(props)

const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
const { t } = useI18n()

const multiplayer = useMultiPlayerState()
const dialog = useDialogStore()
const color = useColorStore()

const tracking = new Tracking(stateGetters.tracking.value)
stateSetters.trackingData = tracking

const opacity = ref(0)
const opacityWords = ref(0)
const activeDrags = ref(0)
const participantInformation = multiplayer.getters.participantInformation
const currentPhase = multiplayer.getters.phase
let choiceTimer = new Date()
const roundData = { correct: 0, of: 0 }

let attempts = 0
let choiceAttempt = 1
let cancelDropEvent = false

// Models for words
const droppedItem1: Ref<TT10Word | undefined> = ref(undefined)
const droppedItem2: Ref<TT10Word | undefined> = ref(undefined)
const combinedWord = ref('')

let finishDone = false
let taskCompleted = false
let audioPlaying = false

const capitaliseDroppedItem = ref(false)
const showPlaceholders = ref(true)
const showCombinedWord = ref(false)
const mergeCombinedWord = ref(false)
const box1disabled = ref(false)
const box2disabled = ref(false)

const audioSentenceQueue: (string | WebAudio)[] = []
let queuedAudio: WebAudio | undefined = undefined

let sentence1audio: WebAudio
let sentence2AudioBefore: WebAudio
let sentence2AudioAfter: WebAudio | undefined

interface State {
  initialWords: TT10Word[]
  words: TT10Word[]
  solutions: SolutionUnion[]
}

const state: State = reactive({
  initialWords: [],
  words: [],
  solutions: []
})

const firstDecision = computed(() =>
  props.firstDecision ? (props.firstDecision.solution as TT10Word) : undefined
)
const advice = computed(() => (props.advice ? (props.advice.solution as TT10Word) : undefined))

/* HOOKS */

onMounted(() => {
  emit('registerCallback', TaskCallbackType.FinalDecisionAccept, finalDecisionAcceptedCallback)
  emit('registerCallback', TaskCallbackType.FinalDecisionReject, finalDecisionRejectedCallback)
  emit('registerCallback', TaskCallbackType.Shuffle, shuffleCallback)
  emit('registerCallback', TaskCallbackType.ShuffleOnRequest, ShuffleOnRequestCallback)
  emit('registerCallback', TaskCallbackType.Phase, phaseCallback)

  setupTask()
})

// Reset the tracking timer when the phase changes, so we are not including the other player's time
watch(multiplayer.getters.phase, () => {
  choiceTimer = new Date()
})

/* MP STATE CALLBACKS */

const phaseCallback = () => {
  if (!props.firstDecision) state.solutions = []
}

const checkWordCombination = (): string => {
  let status = 'incorrect'

  const a = droppedItem1.value ? droppedItem1.value.correct : undefined
  const b = droppedItem2.value ? droppedItem2.value.correct : undefined

  if (
    (box2disabled.value && a === Type10CorrectType.First) ||
    (box1disabled.value && b === Type10CorrectType.Second)
  )
    status = 'correct'
  else if ((!a && b === Type10CorrectType.Second) || (a === Type10CorrectType.First && !b))
    status = 'some'
  else if (a === Type10CorrectType.First && b === Type10CorrectType.Second) status = 'correct'
  return status
}

const checkWordCombinationForAdvisor = (advice: TaskSyncWord | null): string => {
  if (!advice) {
    return 'incorrect'
  }
  let status = 'incorrect'
  const box1Advice = (advice.additionalSolutions as TT10Word[]).find(
    (w) => w.referenceId === 'word-box-1'
  )
  const box2Advice = (advice.additionalSolutions as TT10Word[]).find(
    (w) => w.referenceId === 'word-box-2'
  )

  let a = undefined //Eval to "First" if preselected or correct advice given for first word. Otherwise "None", "First" or undefined
  if (!box1Advice) {
    a = droppedItem1.value ? droppedItem1.value.correct : undefined
  } else {
    a = box1Advice ? box1Advice.correct : undefined
  }

  let b = undefined //Eval to "Second" if preselected or correct advice given for first word. Otherwise "None", "First" or undefined
  if (!box2Advice) {
    b = droppedItem2.value ? droppedItem2.value.correct : undefined
  } else {
    b = box2Advice ? box2Advice.correct : undefined
  }

  if (
    (box2disabled.value && a === Type10CorrectType.First) ||
    (box1disabled.value && b === Type10CorrectType.Second)
  ) {
    status = 'correct'
  } else if ((!a && b === Type10CorrectType.Second) || (a === Type10CorrectType.First && !b)) {
    status = 'some'
  } else if (a === Type10CorrectType.First && b === Type10CorrectType.Second) {
    status = 'correct'
  }
  return status
}

const finalDecisionAcceptedCallback = () => {
  if (multiplayer.getters.currentRole.value === UserTaskRole.Advisor && props.firstDecision) {
    const box1 = props.firstDecision.additionalSolutions as TT10Word[]
    const box1sol = box1.find((w) => w.referenceId === 'word-box-1')

    const box2 = props.firstDecision.additionalSolutions as TT10Word[]
    const box2sol = box2.find((w) => w.referenceId === 'word-box-2')

    // force set the droppedItems for the advisor
    if (props.task.preselectedCorrect !== Type10CorrectType.First) droppedItem1.value = box1sol // try to replace
    if (props.task.preselectedCorrect !== Type10CorrectType.Second) droppedItem2.value = box2sol // try to replace
  }

  let status = checkWordCombination()
  const statusAdvisor = checkWordCombinationForAdvisor(props.advice)

  const choice = new Choice()
  choice.attempt = choiceAttempt
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.content = ''
  if (multiplayer.getters.finalDecisionStatus.value === FinalDecisionStatus.AcceptedAdvice) {
    choice.correct = statusAdvisor.toLowerCase() !== 'incorrect'
  } else {
    choice.correct = status.toLowerCase() !== 'incorrect'
  }
  choice.phase = Object.values(SyncType).indexOf(currentPhase.value)
  choice.target = task.value.correctWords
  choice.response = ''
  choice.valid = true
  choice.finaldecisionstatus = multiplayer.getters.finalDecisionStatus.value
  choice.committed = true
  tracking.choices.push(choice)
  choiceTimer = new Date()

  if (multiplayer.getters.finalDecisionStatus.value === FinalDecisionStatus.AcceptedAdvice) {
    status = statusAdvisor //status, and thus downstream behaviour, is determined by advisors correctness
    //To make advisors chosen words animate: swap droppedItem1 and droppedItem2 to his
    const box1Advice = (props.advice?.additionalSolutions as TT10Word[]).find(
      (w) => w.referenceId === 'word-box-1'
    )
    const box2Advice = (props.advice?.additionalSolutions as TT10Word[]).find(
      (w) => w.referenceId === 'word-box-2'
    )
    if (box1Advice) {
      droppedItem1.value = box1Advice
    }
    if (box2Advice) {
      droppedItem2.value = box2Advice
    }
  }

  if (props.task.unforgiving) {
    //draggingNow = false
    const correctSolutions: TT10Word[] = []
    for (const word of state.initialWords) {
      if (word.correct === Type10CorrectType.First) correctSolutions.push(word)
      if (word.correct === Type10CorrectType.Second) correctSolutions.push(word)
    }
    if (status === 'correct') {
      const callback = () => {
        stateActions.progress.completeAStar()
        multiplayer.actions.proceedRoundIndex() // round is completed -> proceed

        roundData.correct++
      }
      emit('showResultMessage', DialogMessageType.ResultSplashCorrect, combinedWord.value, callback)
    } else if (status === 'some') {
      // Do nothing
    } else if (status === 'incorrect') {
      const callback = () => {
        finalDecisionRejectedCallback() // perform same actions as on rejection
      }
      emit('showResultMessage', DialogMessageType.ResultSplashWrong, combinedWord.value, callback)
    }

    if (attempts === roundData.of && !finishDone) {
      if (queuedAudio) queuedAudio.pause()
      fadeOut()
    }
  }
  //Below is forgiving mode, i.e. training sessions for Andreas RCT
  else {
    if (status === 'correct') {
      const callback = () => {
        setTimeout(() => {
          showPlaceholders.value = false
          combinedWord.value =
            props.task.sentence2TrailingWord +
            (droppedItem1.value ? droppedItem1.value.text : '') +
            (droppedItem2.value ? droppedItem2.value.text : '') +
            props.task.sentence2SecondTrailingWord
          stateActions.progress.completeAStar()
          roundData.correct++
          // Fuse into one word
          if (
            roundData.correct === roundData.of ||
            (props.task.unforgiving && attempts === roundData.of && !finishDone)
          )
            opacityWords.value = 0

          // Read completed word audio
          playCompleteSentence()
          setTimeout(() => {
            showCombinedWord.value = true
            setTimeout(() => {
              mergeCombinedWord.value = true
              completeTask()
            }, 1000)
          }, 1000)
        }, 1000)
      }
      emit('showResultMessage', DialogMessageType.ResultSplashCorrect, combinedWord.value, callback)
    } else if (status === 'some') {
      // draggingNow = false
      // Deactivate the boxIndex position
    } else if (status === 'incorrect') {
      const callback = () => {
        startSameTaskAgain() // perform same actions as on rejection
      }
      emit('showResultMessage', DialogMessageType.ResultSplashWrong, combinedWord.value, callback)
    }
  }
  stateSetters.trackingData = tracking
}

const startSameTaskAgain = () => {
  multiplayer.actions.resetSync() // reset for current round
  state.solutions = []
  resetTaskValues()
  resetWords()
  choiceAttempt++
  removeAllWords()
}

const finalDecisionRejectedCallback = () => {
  const status = checkWordCombination()
  const choice = new Choice()
  choice.attempt = choiceAttempt
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.content = 'Start oppgave på nytt;Bekreft beslutning'
  choice.response = 'Start oppgave på nytt'
  choice.correct = status.toLowerCase() !== 'incorrect'
  choice.phase = Object.values(SyncType).indexOf(currentPhase.value)
  choice.target = task.value.correctWords
  choice.valid = true
  choice.committed = true
  choice.finaldecisionstatus = 'Rejected' //I prefer hard coding, since I dont have full overview of the store value
  tracking.choices.push(choice)
  choiceTimer = new Date()

  startSameTaskAgain()
}

const shuffleCallback = (args: TaskCallbackParam) => {
  const items = args as string[]
  const shuffledItems = [] as TT10Word[]
  let newIndex = 0
  for (const item of items) {
    const index = state.initialWords.findIndex((word) => word.text === item)
    shuffledItems[newIndex] = state.initialWords[index]
    newIndex++
  }
  state.words = shuffledItems.filter((i) => i)
}

const ShuffleOnRequestCallback = () => {
  shuffleAndSync(false) // trigger shuffle parcel after advisor has switched task/round
}

/* TASK FUNCTIONS */

const getDecisionDetails = (type: string, id: string) => {
  const words =
    type === 'advice'
      ? (props.advice?.additionalSolutions as TT10Word[])
      : (props.firstDecision?.additionalSolutions as TT10Word[])
  return words.length > 0 ? words.find((w) => w.referenceId === id)?.text : ''
}

const wordStyles = () => {
  return `color: ${color.actions.selectColor(multiplayer.getters.participantInformation.value.name)};`
}

const resetWords = () => {
  state.words = state.initialWords
}

const shuffleAndSync = (shuffle = true) => {
  //state.words.unshift(word)
  if (props.role === UserTaskRole.Leader) {
    if (shuffle) state.words = shuffleItems(state.words)
    const shuffleOrder = state.words.map((word) => {
      return word.text
    })
    emit('updateShuffleOrder', shuffleOrder) // sync the re-shuffled words
  }
}

// A 'dropbox' element should incude the class 'drop-target'
const getDropboxElement = (e: DraggableEvent): Element | undefined => {
  let x = 0
  let y = 0
  if (!e.event) return
  if (e.event.type.includes('touch')) {
    const te = e.event as TouchEvent
    x = te.changedTouches[0].clientX
    y = te.changedTouches[0].clientY
  } else {
    const me = e.event as MouseEvent
    x = me.clientX
    y = me.clientY
  }
  const el = document.elementFromPoint(x, y)
  return el && el.classList.contains('drop-target') ? el : undefined
}

const onStart = (e: DraggableEvent) => {
  if (props.actionAllowed) {
    e.event.preventDefault()
    activeDrags.value++
  } else dialog.actions.pushMessage(DialogMessageType.Warning, t('waitforturn'))
}

const onPointerDown = (e: PointerEvent) => {
  if (props.actionAllowed) {
    const el = e.target as HTMLDivElement
    if (e.pointerId && e.target) el.releasePointerCapture(e.pointerId)
  } else dialog.actions.pushMessage(DialogMessageType.Warning, t('waitforturn'))
}

const resetTaskValues = async () => {
  finishDone = false
  audioSentenceQueue.length = 0
  showPlaceholders.value = true
  showCombinedWord.value = false
  mergeCombinedWord.value = false

  audioPlaying = false
  taskCompleted = false

  const preselectedText = props.task.preselectedText.trim()
  const preselectedCorrect = props.task.preselectedCorrect

  droppedItem1.value = undefined
  droppedItem2.value = undefined

  const audio = await createSound(task.value.preselectedAudio)
  const preselectedWord: TT10Word = {
    enabled: true,
    visible: true,
    draggable: true,
    opacity: 1,
    audio,
    text: preselectedText,
    correct: preselectedCorrect,
    index: -1,
    id: 'drag-word-preselected',
    referenceId: '',
    position: { x: 0, y: 0 }
  }

  if (preselectedCorrect === Type10CorrectType.First) {
    droppedItem1.value = preselectedWord
    droppedItem2.value = undefined
  } else if (preselectedCorrect === Type10CorrectType.Second) {
    droppedItem1.value = undefined
    droppedItem2.value = preselectedWord
  }
}

const setupTask = async () => {
  state.solutions = [] // reset on re-load or new round

  resetTaskValues()
  combinedWord.value = ''
  if (typeof props.task === 'undefined' || props.task === null) {
    alert('A Type 10 task does not exist - check your Session layout in the CMS')
    return
  }
  const preselectedText = props.task.preselectedText.trim()
  const preselectedCorrect = props.task.preselectedCorrect
  let correctBox1 = Type10CorrectType.None
  let correctBox2 = Type10CorrectType.None

  if (preselectedText && preselectedCorrect !== Type10CorrectType.None) {
    const container = preselectedCorrect === Type10CorrectType.First ? droppedItem1 : droppedItem2
    const audio = await createSound(task.value.preselectedAudio)
    const preselectedWord: TT10Word = {
      enabled: true,
      visible: true,
      draggable: true,
      opacity: 1,
      audio,
      text: preselectedText,
      correct: preselectedCorrect,
      index: -1,
      id: 'drag-word-preselected',
      referenceId: '',
      position: { x: 0, y: 0 }
    }
    container.value = preselectedWord
    if (preselectedCorrect === Type10CorrectType.First) correctBox1 = preselectedCorrect
    else if (preselectedCorrect === Type10CorrectType.Second) correctBox2 = preselectedCorrect
  }

  // Capitalise first word if no prior sentence
  if (!props.task.sentence2TextBefore) capitaliseDroppedItem.value = true

  const tempWords: TT10Word[] = []
  for (let i = 0; i < task.value.words.length; i++) {
    const w = task.value.words[i]
    const audio = await createSound(w.audio)
    if (w.text) {
      const word: TT10Word = {
        enabled: true,
        visible: true,
        draggable: true,
        opacity: 1,
        audio,
        text: w.text,
        correct: w.correct,
        index: i,
        id: `drag-word-${i}`,
        referenceId: '',
        position: { x: 0, y: 0 }
      }
      tempWords.push(word)
      state.initialWords.push(word)
      state.words.push(word)
    }
    if (w.correct === Type10CorrectType.First) correctBox1 = w.correct
    else if (w.correct === Type10CorrectType.Second) correctBox2 = w.correct
  }

  // If there is no preselected item and less than two other correct items, we need to disable one (or both!) drop boxes
  if (correctBox1 === Type10CorrectType.None) box1disabled.value = true
  if (correctBox2 === Type10CorrectType.None) box2disabled.value = true

  sentence1audio = await createSound(task.value.sentence1audio)
  sentence2AudioBefore = await createSound(task.value.sentence2AudioBefore)
  sentence2AudioAfter = task.value.sentence2AudioAfter
    ? await createSound(task.value.sentence2AudioAfter)
    : undefined

  // Tracking
  roundData.of = 1
  stateActions.progress.progressShow(roundData.of)
  shuffleAndSync()
  introduceChallenge()
}

function preventDropOnTap() {
  cancelDropEvent = true
  setTimeout(() => {
    cancelDropEvent = false
  }, 1000)
}

function playWordAudio(word: TT10Word | undefined) {
  if (word && !audioPlaying) {
    audioPlaying = true
    if (word.audio) {
      word.audio.onended = async () => {
        audioPlaying = false
        if (word === droppedItem2.value && task.value.sentence2AudioAfter) {
          queueAudio(task.value.sentence2AudioAfter, 500)
        }
      }
      word.audio.playWhenReady()
    }
  }
  tracking.use_audio_content_items++
}

function clickFirstSentence() {
  if (props.task.sentence1audio) {
    queueAudio(sentence1audio)
    tracking.use_audio_content_items++
  }
}

function clickSecondSentenceBefore() {
  if (props.task.sentence2AudioBefore) {
    queueAudio(sentence2AudioBefore)
    tracking.use_audio_content_items++
  }
}

async function clickSecondSentenceAfter() {
  if (task.value.sentence2AudioAfter) {
    if (droppedItem2.value) {
      queueAudio(droppedItem2.value.audio)
    }
    queueAudio(sentence2AudioAfter)
    tracking.use_audio_content_items++
  }
}

async function playCompleteSentence() {
  if (task.value.sentence2AudioComplete) {
    if (queuedAudio) queuedAudio.pause()
    queuedAudio = await createSound(task.value.sentence2AudioComplete)
    queuedAudio.onerror = (error) => {
      console.log(error.toString())
      audioPlaying = false
      checkForWarmup()
    }
    audioPlaying = true
    queuedAudio.playWhenReady()
    queuedAudio.onended = () => {
      audioPlaying = false
      // Check the allowed number of attempts here - cancel the previous audio if ending
      // If number of attempts are limited to correct answers we will finish the task
      checkForWarmup()
    }
  } else {
    checkForWarmup()
  }
}

async function queueAudio(audio: string | WebAudio | undefined, delay = 0) {
  const qaCallback = async () => {
    if (queuedAudio) queuedAudio.removeEventListener('ended', qaCallback)
    audioPlaying = false
    const nextAudio = audioSentenceQueue.pop()
    if (nextAudio) await queueAudio(nextAudio, 500)
  }
  if (audioSentenceQueue.length <= 2 && !finishDone && audio) {
    // Don't allow too many sounds to queue
    if (audioPlaying) audioSentenceQueue.push(audio)
    else {
      queuedAudio = typeof audio === 'string' ? await createSound(audio) : audio
      queuedAudio.addEventListener('ended', qaCallback)
      audioPlaying = true
      setTimeout(() => {
        if (queuedAudio) queuedAudio.playWhenReady()
      }, delay)
    }
  }
}

function removeAllWords() {
  if (droppedItem1.value && droppedItem1.value.id !== 'drag-word-preselected') {
    //const index = state.words.indexOf(droppedItem1.value)
    //if (index !== -1) state.words.splice(index, 1)
    droppedItem1.value.referenceId = ''
    droppedItem1.value = undefined
  }
  if (droppedItem2.value && droppedItem2.value.id !== 'drag-word-preselected') {
    //const index = state.words.indexOf(droppedItem2.value)
    //if (index !== -1) state.words.splice(index, 1)
    droppedItem2.value.referenceId = ''
    droppedItem2.value = undefined
  }
  shuffleAndSync()
}

const onDrop = (e: DraggableDIVElementEvent, word?: TT10Word) => {
  const status = checkWordCombination()
  const choice = new Choice()
  choice.attempt = choiceAttempt
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.content = state.words.map((i) => i.text).join(';')
  choice.correct = status.toLowerCase() !== 'incorrect'
  choice.phase = Object.values(SyncType).indexOf(currentPhase.value)
  choice.target = task.value.correctWords
  tracking.choices.push(choice)
  choiceTimer = new Date()

  if (props.actionAllowed) {
    activeDrags.value = 0
    const el = getDropboxElement(e)
    if (word && el) {
      attempts++
      const boxIndex = getBoxIndex(el)
      if (cancelDropEvent || boxIndex === 0) {
        word.position = { x: 0, y: 0 }
        return
      }
      choice.valid = true

      const container = boxIndex === 1 ? droppedItem1 : droppedItem2
      container.value = word
      const i = state.words.indexOf(word)
      if (i > -1) state.words.splice(i, 1) // remove from selectable list

      word.referenceId = el.id

      state.solutions.push(word)
      choice.response = state.solutions.map((s) => (s as TT10Word).text).join(';')
      choice.correct = choice.response == choice.target
      choice.response += `;STATUS=${status}`
      if (
        state.solutions.length === 2 ||
        (state.solutions.length && props.task.preselectedText) ||
        (state.solutions.length === 1 && (box1disabled.value || box2disabled.value))
      ) {
        choice.committed = true
        emit('addSync', props.task.id, null, word.id, state.solutions)
      }
    } else if (word) {
      choice.response = word.text
      playWordAudio(word)
      word.position = { x: 0, y: 0 }
    }
  }
  stateSetters.trackingData = tracking
}

function checkForWarmup() {
  if (
    roundData.correct === roundData.of ||
    (task.value.unforgiving && attempts === roundData.of && !finishDone)
  ) {
    if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
      stateActions.speakLocalised(
        SpeechSounds.instructions.warmups.T10,
        () => {
          fadeOut()
        },
        1000,
        false
      )
    } else {
      fadeOut(true)
    }
  }
}

function getBoxIndex(target: Element) {
  return parseInt(target.id.substring(9), 10) || 0
}

function introduceChallenge() {
  attempts = 0
  setTimeout(async () => {
    opacity.value = 1
    opacityWords.value = 1
    audioPlaying = true
    if (task.value.introductionAudio) {
      const instructionAudio = await createSound(task.value.introductionAudio)
      instructionAudio.onerror = (error) => {
        console.log(error.toString())
        audioPlaying = false
      }
      instructionAudio.onended = async () => {
        audioPlaying = false
        stateActions.setSpeakerSound([task.value.introductionAudio])
        stateSetters.speakerIsPlaying = false
        await queueAudio(task.value.sentence1audio, 500)
        await queueAudio(task.value.sentence2AudioBefore)
      }
      instructionAudio.playWhenReady()
      stateSetters.speakerIsPlaying = true
    } else {
      await queueAudio(task.value.sentence1audio, 500)
      await queueAudio(task.value.sentence2AudioBefore)
    }
  }, 1000)
}

function fadeOut(long?: boolean) {
  if (!finishDone) {
    finishDone = true
    opacityWords.value = 0
    setTimeout(
      () => {
        opacity.value = 0
        completeTask()
      },
      long ? 3000 : 1500
    )
  }
}

function completeTask() {
  if (!taskCompleted) {
    taskCompleted = true
    setTimeout(() => {
      emit('completed', true, tracking)
    }, 1000)
  }
}

function userStyle(name: string) {
  return `background-color: ${color.actions.selectColor(name)};`
}
</script>

<style scoped lang="postcss">
.yellowWordText {
  font-size: 24pt;
  color: #ffd700;
  margin: 0 10px;
}
</style>
