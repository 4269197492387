import gql from 'graphql-tag'
import { LanguageCodes, TASK_TYPES } from '@/constants'
import { Session } from '../navigationModels'
import { LC, CommonTaskAttributes, TasktypeData, Tasktype } from '../questionModels'

export const Tasktype3MPQuery = gql`
  fragment Tasktype3 on Tasktype3 {
    __typename
    id
    data {
      slug {
        iv
      }
      reference {
        iv
      }
      morph {
        __language
      }
      stem {
        __language
      }
      morphedStem {
        __language
      }
      affix {
        iv
      }
      category {
        iv
      }
      audioDone {
        iv
      }
      # specific attibutes start here..
      instructionAudioText {
        __language
      }
      audio {
        __language {
          url
          slug
        }
      }
      audio2 {
        __language {
          url
          slug
        }
      }
      options {
        __language {
          text
          audio {
            url
            slug
          }
          correct
          repeated
        }
      }
    }
  }
`
export interface Type3Options {
  text: string
  audio: string
  correct: string
  repeated: number
}
interface Type3Attributes {
  instructionAudioText: LC<string>
  audio: LC<{ url: string; slug: string }[]>
  audio2: LC<{ url: string; slug: string }[]>
  options: LC<
    {
      text: string
      audio: { url: string; slug: string }[]
      correct: string
      repeated: number
    }[]
  >
}
export interface Tasktype3MPData extends TasktypeData {
  data: CommonTaskAttributes & Type3Attributes
}
export class Tasktype3mp extends Tasktype {
  instructionAudioText = ''
  audio = '' // URL
  audio2 = '' // URL
  options: Type3Options[] = []

  constructor(spec: Tasktype3MPData, language: LanguageCodes, parent?: Session) {
    super(spec, language, parent)
    this.type = TASK_TYPES.Tasktype3mp
    this.parent = parent

    this.instructionAudioText = spec.data.instructionAudioText[language] || ''
    const audio = spec.data.audio[language]
    if (audio) this.audio = audio[0]?.url + audio[0]?.slug || ''
    const audio2 = spec.data.audio2[language]
    if (audio2) this.audio2 = audio2[0]?.url + audio2[0]?.slug || ''
    console.log(spec.data)
    spec.data.options[language]?.forEach((option) =>
      this.options.push({
        text: option.text,
        audio: option.audio[0]?.url + option.audio[0]?.slug || '', // URL
        //audio: '',
        correct: option.correct,
        repeated: option.repeated
      })
    )
  }

  get assetList(): string[] {
    const list = [this.audio, this.audio2]
    this.options.forEach((o) => list.push(o.audio))
    return list
  }
}
