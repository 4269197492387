<template>
  <div class="flex justify-center z-101" :class="backgroundStyle">
    <div
      v-if="state.message"
      class="dialog-bg border-2 message fadein absolute mt-11 p-3 w-1/3 xl:w-1/4 flex justify-center flex-col items-center rounded-md text-white z-50"
      :class="messageStyle()"
    >
      <p class="text-sm">{{ state.message.message }}</p>
      <Button
        v-if="state.message.type === DialogMessageType.Confirmation && state.message.callback"
        id="button-accept"
        textcolour="text-white"
        backgroundcolour="bg-green-600"
        class="my-2 mr-2 border-none rounded-3xl"
        @click.prevent="invokeCallback()"
        @touchstart.prevent="invokeCallback()"
        >{{ t('accept') }}</Button
      >
    </div>
    <div
      v-if="state.multiplayerMessage"
      ref="multiplayerMessage"
      class="message dialog-bg border-2 flyin absolute py-3 px-7 w-2/3 h-auto flex justify-center flex-col items-center rounded-3xl text-white z-50"
      :style="state.multiplayerMessageStyle"
      :class="messageStyle()"
    >
      <p
        v-if="
          ![
            DialogMessageType.Splash,
            DialogMessageType.ResultSplashCorrect,
            DialogMessageType.ResultSplashWrong
          ].some((type) => type === state.multiplayerMessage?.type)
        "
        class="text-4xl font-bold my-5"
      >
        {{ multiplayerPhaseHeadline }}
      </p>
      <div
        v-if="
          ![
            DialogMessageType.Splash,
            DialogMessageType.ResultSplashCorrect,
            DialogMessageType.ResultSplashWrong
          ].some((type) => type === state.multiplayerMessage?.type)
        "
        class="flex"
      >
        <div class="p-5 flex flex-col justify-center items-center mr-11">
          <div class="flex">
            <div
              class="rounded-full p-7 w-44 h-44 flex justify-center items-center"
              :style="userStyle(userStore.getters.myUser.value.profile.username)"
            >
              <AvatarVue :avatar-ref="userStore.getters.myUser.value.avatar.ref" />
            </div>
            <div
              v-if="currentRole === UserTaskRole.Leader"
              class="absolute p-2 w-12 h-12 bg-yellow-400 rounded-full"
            >
              <img class="invert -rotate-12" :src="CrownIcon" alt="crown" />
            </div>
          </div>
          <p class="text-lg mt-3">{{ userStore.getters.myUser.value.profile.username }}</p>
        </div>
        <div class="p-5 flex flex-col justify-center items-center">
          <div class="flex">
            <div
              class="rounded-full p-7 w-44 h-44 flex justify-center items-center"
              :style="userStyle(participantInformation.name || 'no name available')"
            >
              <AvatarVue :avatar-ref="participantInformation.avatar.ref" />
            </div>
            <div
              v-if="currentRole !== UserTaskRole.Leader"
              class="absolute p-2 w-12 h-12 bg-yellow-400 rounded-full"
            >
              <img class="invert -rotate-12" :src="CrownIcon" alt="crown" />
            </div>
          </div>
          <p class="text-lg mt-3">{{ participantInformation.name || 'no name available' }}</p>
        </div>
      </div>
      <div class="mt-7 rounded-2xl w-2/3 flex flex-col mb-7">
        <h1 class="text-xl p-7">{{ state.multiplayerMessage.message }}</h1>
        <div
          v-if="state.multiplayerMessage.type === DialogMessageType.ResultSplashCorrect"
          class="text-green-400 rounded-2xl p-3 w-full flex flex-col justify-center items-center"
        >
          <h2 class="font-bold text-3xl">{{ t('correct') }}</h2>
          <img v-if="solutionElement.includes('http')" class="rounded-2xl" :src="solutionElement" />
          <h2 v-else class="text-2xl">{{ solutionElement }}</h2>
        </div>
        <div
          v-if="state.multiplayerMessage.type === DialogMessageType.ResultSplashWrong"
          class="text-red-400 rounded-2xl p-3 w-full flex flex-col justify-center items-center"
        >
          <h2 class="font-bold text-3xl">{{ t('wrong') }}</h2>
          <img v-if="solutionElement.includes('http')" class="rounded-2xl" :src="solutionElement" />
          <h2 v-else class="text-2xl">{{ solutionElement }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { DialogMessageType, DialogMessage, SyncType, UserTaskRole } from '@/constants'
import { TT10Word, TT3Word, Word, Image } from '@/models/tasktypes'

import Button from '@/components/base/Button.vue'

import useMultiPlayerState from '@/composition/useMultiplayerState'
import useColorStore from '@/composition/colors'
import useDialogStore from '@/composition/dialog'
import useUserStore from '@/store/useUserStore'

import CrownIcon from '@/assets/icons/fontawesome/crown-solid.svg'
import AvatarVue from '@/components/base/Avatar.vue'

const multiplayerMessage = ref<HTMLElement | null>()

const color = useColorStore()
const userStore = useUserStore()
const dialogStore = useDialogStore()
const multiplayer = useMultiPlayerState()

const { t } = useI18n()

const currentRole = multiplayer.getters.currentRole
const participantInformation = multiplayer.getters.participantInformation

const state = reactive({
  isProcessing: false,
  multiplayerMessageStyle: '',
  message: {} as DialogMessage | undefined,
  multiplayerMessage: {} as DialogMessage | undefined
})

onMounted(() => {
  setMultiplayerMessageStyle()
  window.addEventListener('resize', setMultiplayerMessageStyle)

  state.message = undefined
  state.multiplayerMessage = undefined
})

onUnmounted(() => {
  window.removeEventListener('resize', setMultiplayerMessageStyle)
})

watch(dialogStore.state, () => {
  if (!state.isProcessing) {
    prepareIncomingMessage()
  } else {
    setTimeout(() => {
      prepareIncomingMessage()
    }, 2000)
  }
  /*} else {
      if (state.message) {
        setTimeout(() => {
          state.message = undefined
          prepareIncomingMessage()
        }, state.message.duration)
      }
      if (state.multiplayerMessage) {
        setTimeout(() => {
          state.multiplayerMessage = undefined
          prepareIncomingMessage()
        }, state.multiplayerMessage.duration)
      }
    }*/
})

const multiplayerPhaseHeadline = computed(() => {
  const phase = multiplayer.getters.phase.value
  switch (phase) {
    case SyncType.FirstDecision:
      return t('firstdecision')
    case SyncType.Advice:
      return t('advice')
    case SyncType.FinalDecision:
      return t('finaldecision')
    default:
      return 'Error'
  }
})

const backgroundStyle = computed(() => {
  return state.multiplayerMessage?.message ? 'absolute w-full h-full z-101' : ''
})

const solutionElement = computed(() => {
  const element = state.multiplayerMessage?.element
  let correctString = ''
  if (element) {
    if ((element as TT3Word).text !== undefined) correctString = (element as TT3Word).text
    else if ((element as TT10Word).text !== undefined) correctString = (element as TT10Word).text
    else if ((element as Image).imageUrl !== undefined) correctString = (element as Image).imageUrl
    else if ((element as Word).text !== undefined) correctString = (element as Word).text
    else if (element) correctString = element.toString()
  }
  return correctString
})

function setMultiplayerMessageStyle() {
  const height = window.innerHeight
  if (multiplayerMessage.value) {
    const messageHeight = multiplayerMessage.value.clientHeight
    state.multiplayerMessageStyle = `top: ${height / 2 - messageHeight / 2}px;`
  } else state.multiplayerMessageStyle = `top: ${height / 5}px;` // default to 1/3 of the height as top offset
}

function prepareIncomingMessage() {
  const message = dialogStore.actions.currentMessage()
  const multiplayerMessage = dialogStore.actions.currentMultiplayerMessage()
  if (message) handleIncomingMessage(message)
  if (multiplayerMessage) handleIncomingMessage(multiplayerMessage, true)
}

function handleIncomingMessage(message: DialogMessage, isMultiPlayerMessage = false) {
  multiplayerMessage.value?.classList.add('flyin')
  multiplayerMessage.value?.classList.remove('flyout')
  if (message) {
    if (!isMultiPlayerMessage) state.message = message
    else {
      state.message = undefined
      state.multiplayerMessage = message
    }

    if (state.multiplayerMessage?.callback) state.multiplayerMessage.callback.call(undefined)

    state.isProcessing = true
    let count = 0
    setMultiplayerMessageStyle() // re-calculate top offset after message is displayed

    const addFlyoutAnimation = setInterval(() => {
      if (count >= message.duration - 500) {
        multiplayerMessage.value?.classList.add('flyout')
        multiplayerMessage.value?.classList.remove('flyin')
      }
      if (count >= message.duration) {
        clearInterval(addFlyoutAnimation)
        //invokeMultiplayerCallback() // invoke callback prior to dialog display to ensure that the delay is minimal
        state.multiplayerMessage = undefined
        state.isProcessing = false
      }
      count += 100
    }, 100) // every tenth of a second
  }
}

function userStyle(name: string) {
  return `background-color: ${color.actions.selectColor(name)};`
}

function messageStyle() {
  if (state.message) {
    switch (state.message.type) {
      case DialogMessageType.Information:
        return 'dialog-info'
      case DialogMessageType.Success:
        return 'dialog-success'
      case DialogMessageType.Warning:
        return 'dialog-warn'
      case DialogMessageType.Error:
        return 'dialog-err'
      default:
        return 'dialog-info'
    }
  }
  if (state.multiplayerMessage) {
    switch (state.multiplayerMessage.type) {
      case DialogMessageType.Information:
        return 'dialog-info'
      case DialogMessageType.Success:
        return 'dialog-success'
      case DialogMessageType.Warning:
        return 'dialog-warn'
      case DialogMessageType.Error:
        return 'dialog-err'
      default:
        return 'dialog-info'
    }
  }
}

function invokeCallback() {
  if (state.message?.callback) state.message.callback.call(undefined)
  state.message = undefined
}
</script>

<style scoped>
.flyin {
  animation: fly-in ease-in-out 0.5s forwards;
}

.flyout {
  animation: fly-out ease-in-out 0.5s forwards;
}

@keyframes fly-in {
  from {
    transform: translateX(-50vw);
  }
  to {
    transform: translateX(0vw);
  }
}

@keyframes fly-out {
  from {
    transform: translateX(0vw);
  }
  to {
    transform: translateX(100vw);
  }
}
</style>
