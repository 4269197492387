/* eslint-disable @typescript-eslint/no-unused-vars */
<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div
    class="relative flex flex-col justify-center items-center fadeInOut"
    :style="{ opacity: opacity }"
  >
    <div class="scanner-content flex flex-col flex-grow justify-center">
      <div
        class="flex text-white flex-row justify-around scanner-padding border-2 rounded-2xl p-11"
      >
        <div class="flex flex-col justify-start">
          <div class="flex flex-row justify-start">
            <span class="text-2xl lg:text-3xl" @click="clickFirstSentence()">{{
              task.sentence1text
            }}</span>
          </div>

          <div class="flex flex-row justify-start items-center">
            <div class="flex flex-col justify-center">
              <span class="text-2xl lg:text-3xl" @click="clickSecondSentenceBefore()"
                >{{ task.sentence2TextBefore }}&nbsp;</span
              >
            </div>

            <transition mode="out-in" name="fade">
              <div v-if="showPlaceholders" class="flex flex-col justify-center">
                <span
                  class="wordHighlight text-2xl lg:text-3xl"
                  @click="clickSecondSentenceBefore()"
                  >{{ task.sentence2TrailingWord }}</span
                >
              </div>
            </transition>

            <div class="flex flex-col">
              <!-- Drop boxes -->
              <div
                v-if="!droppedItem1 && showPlaceholders && !box1disabled"
                id="word-box-1"
                class="borderedTaskWordBox yellow-border relative rounded-full m-2 w-36 h-16 drop-target"
                @start="onStart"
                @stop="onDrop"
                @touchstart="preventDropOnTap"
              ></div>

              <transition mode="out-in" name="fade">
                <div v-if="droppedItem1 && showPlaceholders" class="flex flex-col justify-center">
                  <span
                    class="wordHighlight text-2xl lg:text-3xl"
                    :class="{ yellowWordText: droppedItem1.id !== 'drag-word-preselected' }"
                    @click.stop="playWordAudio(droppedItem1)"
                    >{{ droppedItem1.text || '' }}</span
                  >
                </div>
              </transition>
            </div>

            <div class="flex flex-col">
              <div
                v-if="!droppedItem2 && showPlaceholders && !box2disabled"
                id="word-box-2"
                class="borderedTaskWordBox yellow-border relative rounded-full m-2 w-36 h-16 drop-target"
                @start="onStart"
                @stop="onDrop"
                @touchstart="preventDropOnTap"
              ></div>

              <transition mode="out-in" name="fade">
                <div v-if="droppedItem2 && showPlaceholders" class="flex flex-col justify-center">
                  <p
                    class="wordHighlight text-2xl lg:text-3xl"
                    :class="{ yellowWordText: droppedItem2.id !== 'drag-word-preselected' }"
                    @click.stop="playWordAudio(droppedItem2)"
                  >
                    {{ droppedItem2.text || '' }}&nbsp;
                  </p>
                </div>
              </transition>
            </div>

            <!-- Combined word -->
            <transition mode="out-in" name="fade">
              <div v-if="combinedWord && showCombinedWord" class="flex flex-col justify-center">
                <span
                  class="text-2xl lg:text-3xl wordHighlight"
                  :class="{ yellowWordText: !mergeCombinedWord }"
                  >{{ combinedWord }}&nbsp;</span
                >
              </div>
            </transition>

            <transition mode="out-in" name="fade">
              <span
                v-if="showPlaceholders"
                class="text-2xl lg:text-3xl wordHighlight"
                @click="clickSecondSentenceAfter()"
                >{{ task.sentence2SecondTrailingWord }}&nbsp;</span
              >
            </transition>
            <div class="flex flex-col justify-center">
              <span class="text-2xl lg:text-3xl" @click="clickSecondSentenceAfter()">{{
                task.sentence2TextAfter
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="fadeInOut flex flex-row justify-between w-full px-8 my-8 place-self-end"
      :style="{ opacity: opacityWords }"
    >
      <!-- Draggable Items -->
      <Draggable
        v-for="word in words"
        :id="`word-index-${word.id}`"
        :key="word.id"
        :position="word.position"
        :class="`mx-2 cursor-grab ${activeDrags ? 'pointer-events-none touch-none' : ''}`"
        @pointerdown="onPointerDown"
        @start="(e) => onStart(e)"
        @stop="(e) => onDrop(e, word)"
      >
        <span
          class="taskNoUserSelect borderedWordBoxFlex touch-auto pointer-events-auto"
          @click.stop="playWordAudio(word)"
          >{{ word.text }}</span
        >
      </Draggable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, toRefs, Ref, nextTick } from 'vue'
import { ControlPosition, DraggableEvent } from '@braks/revue-draggable'
import { Draggable } from '@braks/revue-draggable'
import { Choice, Tracking } from '@/models/main'
import useState from '@/composition/useState'
import { shuffleItems } from '@/utilities'
import { SpeechSounds, TaskMode } from '@/constants'
import { Tasktype10, Type10CorrectType } from '@/models/tasktypes/Tasktype10'
import { WebAudio } from '@/models/audio'
import { createSound } from '@/api/audioService'
import moment from 'moment'

type DraggableDIVElementEvent = DraggableEvent & { event: { target: HTMLDivElement } }

interface Word {
  index: number
  visible: boolean
  enabled: boolean
  draggable: boolean
  opacity: number
  element?: HTMLElement
  id: string
  position: ControlPosition

  audio: WebAudio | undefined
  text: string
  correct: Type10CorrectType
}

const emit = defineEmits(['completed'])
const props = defineProps({
  task: { required: true, type: Object as PropType<Tasktype10> },
  myIndex: { required: false, type: Number, default: 0 }
})
const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
const { task } = toRefs(props)
const tracking = new Tracking(stateGetters.tracking.value)
stateSetters.trackingData = tracking
let choiceTimer = new Date()

const roundData = { correct: 0, of: 0 }
const opacity = ref(0)
const opacityWords = ref(0)
const activeDrags = ref(0)

let attempts = 0
let attemptLimit = 0
let cancelDropEvent = false

// Models for words
const words: Ref<Word[]> = ref([])

const droppedItem1: Ref<Word | undefined> = ref(undefined)
const droppedItem2: Ref<Word | undefined> = ref(undefined)
const combinedWord = ref('')

let finishDone = false
let taskCompleted = false
let audioPlaying = false

const capitaliseDroppedItem = ref(false)
const showPlaceholders = ref(true)
const showCombinedWord = ref(false)
const mergeCombinedWord = ref(false)
const box1disabled = ref(false)
const box2disabled = ref(false)

const audioSentenceQueue: (string | WebAudio)[] = []
let queuedAudio: WebAudio | undefined = undefined

let sentence1audio: WebAudio
let sentence2AudioBefore: WebAudio
let sentence2AudioAfter: WebAudio | undefined

// A 'dropbox' element should incude the class 'drop-target'
const getDropboxElement = (e: DraggableEvent): Element | undefined => {
  let x = 0
  let y = 0
  if (!e.event) return
  if (e.event.type.includes('touch')) {
    const te = e.event as TouchEvent
    x = te.changedTouches[0].clientX
    y = te.changedTouches[0].clientY
  } else {
    const me = e.event as MouseEvent
    x = me.clientX
    y = me.clientY
  }
  const el = document.elementFromPoint(x, y)
  return el && el.classList.contains('drop-target') ? el : undefined
}

const onStart = (e: DraggableEvent) => {
  if (!e.event) return
  e.event.preventDefault()
  activeDrags.value++
}

const onPointerDown = (e: PointerEvent) => {
  const el = e.target as HTMLDivElement
  if (e.pointerId && e.target) el.releasePointerCapture(e.pointerId)
}

const setupTask = async () => {
  finishDone = false
  audioSentenceQueue.length = 0
  droppedItem1.value = undefined
  droppedItem2.value = undefined
  showPlaceholders.value = true
  showCombinedWord.value = false
  mergeCombinedWord.value = false

  audioPlaying = false
  taskCompleted = false
  combinedWord.value = ''
  if (typeof task.value === 'undefined' || task.value === null) {
    alert('A Type 10 task does not exist - check your Session layout in the CMS')
    return
  }
  const preselectedText = task.value.preselectedText.trim()
  const preselectedCorrect = task.value.preselectedCorrect
  let correctBox1 = Type10CorrectType.None
  let correctBox2 = Type10CorrectType.None

  if (preselectedText && preselectedCorrect !== Type10CorrectType.None) {
    const container = preselectedCorrect === Type10CorrectType.First ? droppedItem1 : droppedItem2
    const audio = await createSound(task.value.preselectedAudio)
    const preselectedWord: Word = {
      enabled: true,
      visible: true,
      draggable: true,
      opacity: 1,
      audio,
      text: preselectedText,
      correct: preselectedCorrect,
      index: -1,
      id: 'drag-word-preselected',
      position: { x: 0, y: 0 }
    }
    container.value = preselectedWord
    if (preselectedCorrect === Type10CorrectType.First) correctBox1 = preselectedCorrect
    else if (preselectedCorrect === Type10CorrectType.Second) correctBox2 = preselectedCorrect
  }

  // Capitalise first word if no prior sentence
  if (!task.value.sentence2TextBefore) capitaliseDroppedItem.value = true

  const tempWords: Word[] = []
  for (let i = 0; i < task.value.words.length; i++) {
    const w = task.value.words[i]
    const audio = await createSound(w.audio)
    if (w.text) {
      const word: Word = {
        enabled: true,
        visible: true,
        draggable: true,
        opacity: 1,
        audio,
        text: w.text,
        correct: w.correct,
        index: i,
        id: `drag-word-${i}`,
        position: { x: 0, y: 0 }
      }
      tempWords.push(word)
    }
    if (w.correct === Type10CorrectType.First) {
      correctBox1 = w.correct
      attemptLimit++
    } else if (w.correct === Type10CorrectType.Second) {
      correctBox2 = w.correct
      attemptLimit++
    }
  }

  // If there is no preselected item and less than two other correct items, we need to disable one (or both!) drop boxes
  if (correctBox1 === Type10CorrectType.None) box1disabled.value = true
  if (correctBox2 === Type10CorrectType.None) box2disabled.value = true

  sentence1audio = await createSound(task.value.sentence1audio)
  sentence2AudioBefore = await createSound(task.value.sentence2AudioBefore)
  // Note: If this value is empty string, a sound decoding error will be printed
  sentence2AudioAfter = task.value.sentence2AudioAfter
    ? await createSound(task.value.sentence2AudioAfter)
    : undefined

  // Tracking
  stateActions.progress.progressShow(roundData.of)
  words.value = shuffleItems(tempWords)
  introduceChallenge()
}

function preventDropOnTap() {
  cancelDropEvent = true
  setTimeout(() => {
    cancelDropEvent = false
  }, 1000)
}

function playWordAudio(word?: Word) {
  if (word && !audioPlaying) {
    audioPlaying = true
    if (word.audio) {
      word.audio.onended = async () => {
        audioPlaying = false
        if (word === droppedItem2.value && task.value.sentence2AudioAfter) {
          await queueAudio(task.value.sentence2AudioAfter, 500)
        }
      }
      word.audio.playWhenReady()
    }
  }
  tracking.use_audio_content_items++
}

async function clickFirstSentence() {
  if (task.value.sentence1audio) {
    await queueAudio(sentence1audio)
    tracking.use_audio_content_items++
  }
}
async function clickSecondSentenceBefore() {
  if (task.value.sentence2AudioBefore) {
    await queueAudio(sentence2AudioBefore)
    tracking.use_audio_content_items++
  }
}
async function clickSecondSentenceAfter() {
  if (task.value.sentence2AudioAfter) {
    if (droppedItem2.value) {
      await queueAudio(droppedItem2.value.audio)
    }
    await queueAudio(sentence2AudioAfter)
    tracking.use_audio_content_items++
  }
}
async function playCompleteSentence() {
  if (task.value.sentence2AudioComplete) {
    if (queuedAudio) queuedAudio.pause()
    queuedAudio = await createSound(task.value.sentence2AudioComplete)
    queuedAudio.onerror = (error) => {
      console.log(error.toString())
      audioPlaying = false
      checkForWarmup()
    }
    audioPlaying = true
    queuedAudio.playWhenReady()
    queuedAudio.onended = () => {
      audioPlaying = false
      // Check the allowed number of attempts here - cancel the previous audio if ending
      // If number of attempts are limited to correct answers we will finish the task
      checkForWarmup()
    }
  } else {
    checkForWarmup()
  }
}
async function queueAudio(audio: string | WebAudio | undefined, delay = 0) {
  const qaCallback = async () => {
    if (queuedAudio) queuedAudio.removeEventListener('ended', qaCallback)
    audioPlaying = false
    const nextAudio = audioSentenceQueue.pop()
    if (nextAudio) await queueAudio(nextAudio, 500)
  }
  if (audioSentenceQueue.length <= 2 && !finishDone && audio) {
    // Don't allow too many sounds to queue
    if (audioPlaying) audioSentenceQueue.push(audio)
    else {
      queuedAudio = typeof audio === 'string' ? await createSound(audio) : audio
      queuedAudio.addEventListener('ended', qaCallback)
      audioPlaying = true
      setTimeout(() => {
        if (queuedAudio) queuedAudio.playWhenReady()
      }, delay)
    }
  }
}

function removeAllWords() {
  if (droppedItem1.value && droppedItem1.value.id !== 'drag-word-preselected') {
    words.value.push(droppedItem1.value)
    droppedItem1.value = undefined
  }
  if (droppedItem2.value && droppedItem2.value.id !== 'drag-word-preselected') {
    words.value.push(droppedItem2.value)
    droppedItem2.value = undefined
  }
  const tempWords = shuffleItems(words.value)
  words.value = []
  nextTick(() => {
    words.value = tempWords
  })
  //draggingNow = false
}

const onDrop = (e: DraggableDIVElementEvent, theWord?: Word) => {
  activeDrags.value = 0
  const el = getDropboxElement(e)

  const startDate = moment(choiceTimer)
  const endDate = moment()
  const choice = new Choice()
  choice.createdAt = new Date()
  choice.duration = endDate.diff(startDate, 'milliseconds')
  choice.response = theWord?.text ?? '(no word)'
  choice.target = task.value.correctWords
  choice.content = task.value.words.map((w) => w.text).join(';')
  choice.round = roundData.correct

  if (theWord && el) {
    attempts++
    const boxIndex = getBoxIndex(el)
    if (cancelDropEvent || boxIndex === 0) {
      theWord.position = { x: 0, y: 0 }
      return
    } else {
      choice.valid = true
      const container = boxIndex === 1 ? droppedItem1 : droppedItem2
      container.value = theWord
      const i = words.value.indexOf(theWord)
      words.value.splice(i, 1)
    }
    const checkWordCombination = (): string => {
      let status = 'incorrect'

      const a = droppedItem1.value ? droppedItem1.value.correct : undefined
      const b = droppedItem2.value ? droppedItem2.value.correct : undefined
      if (
        (box2disabled.value && a === Type10CorrectType.First) ||
        (box1disabled.value && b === Type10CorrectType.Second)
      )
        status = 'correct'
      else if ((!a && b === Type10CorrectType.Second) || (a === Type10CorrectType.First && !b)) {
        status = 'some'
      } else if (a === Type10CorrectType.First && b === Type10CorrectType.Second) {
        status = 'correct'
      }
      return status
    }

    const status = checkWordCombination()

    const response = []
    if (droppedItem1.value) response.push(droppedItem1.value.text)
    if (droppedItem2.value) response.push(droppedItem2.value.text)
    choice.response = response.join(';') //+ `;STATUS=${status}`

    if (task.value.unforgiving) {
      //draggingNow = false

      if (status === 'correct') {
        roundData.correct++
        choice.correct = true
      } else if (status === 'some') {
        choice.correct = true
        // Do nothing
      } else if (status === 'incorrect') {
        choice.correct = false
      }

      if (attempts === attemptLimit && !finishDone) {
        stateActions.progress.completeAStar()
        if (queuedAudio) queuedAudio.pause()
        fadeOut()
      }
    } else {
      if (status === 'correct') {
        roundData.correct++
        choice.correct = true
        setTimeout(() => {
          showPlaceholders.value = false
          combinedWord.value =
            task.value.sentence2TrailingWord +
            (droppedItem1.value ? droppedItem1.value.text : '') +
            (droppedItem2.value ? droppedItem2.value.text : '') +
            task.value.sentence2SecondTrailingWord
          stateActions.progress.completeAStar()
          roundData.correct++
          // Fuse into one word
          if (
            roundData.correct === roundData.of ||
            (task.value.unforgiving && attempts === roundData.of && !finishDone)
          ) {
            opacityWords.value = 0
          }
          // Read completed word audio
          playCompleteSentence()
          setTimeout(() => {
            showCombinedWord.value = true
            setTimeout(() => {
              mergeCombinedWord.value = true
              completeTask()
            }, 1000)
          }, 1000)
        }, 1000)
      } else if (status === 'some') {
        choice.correct = true
        // Deactivate the boxIndex position
      } else if (status === 'incorrect') {
        choice.correct = false
        setTimeout(() => {
          removeAllWords()
        }, 100)
      }
    }
  } else if (theWord) {
    playWordAudio(theWord)
    theWord.position = { x: 0, y: 0 }
  }
  tracking.choices.push(choice)
  stateSetters.trackingData = tracking
  choiceTimer = new Date()
}

function checkForWarmup() {
  if (
    roundData.correct === roundData.of ||
    (task.value.unforgiving && attempts === roundData.of && !finishDone)
  ) {
    if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
      stateActions.speakLocalised(
        SpeechSounds.instructions.warmups.T10,
        () => {
          fadeOut()
        },
        1000,
        false
      )
    } else {
      fadeOut(true)
    }
  }
}

function getBoxIndex(target: Element) {
  return parseInt(target.id.substring(9), 10) || 0
}

function introduceChallenge() {
  attempts = 0
  setTimeout(async () => {
    opacity.value = 1
    opacityWords.value = 1
    audioPlaying = true
    if (task.value.introductionAudio) {
      const instructionAudio = await createSound(task.value.introductionAudio)
      instructionAudio.onerror = (error) => {
        console.log(error.toString())
        audioPlaying = false
      }
      instructionAudio.onended = async () => {
        audioPlaying = false
        stateActions.setSpeakerSound([task.value.introductionAudio])
        stateSetters.speakerIsPlaying = false
        await queueAudio(task.value.sentence1audio, 500)
        await queueAudio(task.value.sentence2AudioBefore)
      }
      instructionAudio.playWhenReady()
      stateSetters.speakerIsPlaying = true
    } else {
      await queueAudio(task.value.sentence1audio, 500)
      await queueAudio(task.value.sentence2AudioBefore)
    }
  }, 1000)
}

function fadeOut(long?: boolean) {
  if (!finishDone) {
    finishDone = true
    opacityWords.value = 0
    setTimeout(
      () => {
        opacity.value = 0
        completeTask()
      },
      long ? 3000 : 1500
    )
  }
}

function completeTask() {
  if (!taskCompleted) {
    taskCompleted = true
    setTimeout(() => {
      emit('completed', true, tracking)
    }, 1000)
  }
}

setupTask()

// ----------------- TASK 5 -----------------------
</script>

<style scoped lang="postcss">
.yellowWordText {
  font-size: 24pt;
  color: #ffd700;
  margin: 0 10px;
}
.yellow-border {
  border-color: #fff000;
}
</style>
@/draggable
