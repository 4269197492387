/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */

import {
  Image,
  SolutionUnion,
  TT3Word,
  TT10Word,
  TT11Image,
  STSolutionUnion,
  QuestionUnion
} from '@/models/tasktypes'
import { Avatar, Group, TaskSync, User } from './models/main'
import { Activity, Episode, Session } from './models/navigationModels'

const theHost = import.meta.env.VITE_SERVER_HOST as string
const thePort = import.meta.env.VITE_SERVER_PORT as string
let baseUrl = `${theHost}`
// Local development hosting requires the port for "hot reload" and for mobile app to server access over LAN
if (
  import.meta.env.DEV &&
  theHost &&
  (theHost.includes('localhost') || theHost.includes('10.0.0.') || theHost.includes('192.168.'))
) {
  baseUrl = `${theHost}:${thePort}`
}
const cmsUrl: string = (import.meta.env.VITE_CMS_HOST as string) || ''
const assetRoot: string = import.meta.env.VITE_CMS_HOST + '/api/assets' || ''
const cmsTokenUrl = import.meta.env.VITE_SQUIDEX_TOKEN_URL || ''
const cmsClientId = import.meta.env.VITE_SQUIDEX_CLIENT_ID || ''
const cmsClientSecret = import.meta.env.VITE_SQUIDEX_CLIENT_SECRET || ''
const userRolesString = (import.meta.env.VITE_USER_ROLES as string) || ''
const userRoles = userRolesString.split(',')
const consentStatesString = (import.meta.env.VITE_CONSENT_STATES as string) || ''
const consentStates = consentStatesString.split(',')
const projectNamesString = (import.meta.env.VITE_PROJECT_NAMES as string) || ''
const projectNames = projectNamesString.split(',') || []
let appVersion: string = document.documentElement.getAttribute('data-appversion') || ''

const useLocalAssets = !!window['cordova']
const localAssetUrl = 'assets/localCMSAssets' // No trailing slash in path!
const SESSION_TYPES = 'Test, Introduction'
const SKIP_SESSION_PASSWORD = 'Sesameåpne'
const mqttReconnectAttempts = 5

// The web-based app will always be the latest version, set the version directly from .env
// If not built with Cordova, 'data-appversion' will === '%%VERSION%%'
if (appVersion === '%%VERSION%%') appVersion = (import.meta.env.VITE_VERSION as string) || ''

projectNames.push('none')

const deviceType: string = window.location.protocol == 'file:' ? 'mobile' : 'web'

enum CordovaPathName {
  root = '.',
  users = 'users',
  players = 'players',
  games = 'games'
}
enum MediaType {
  audio = 'audio',
  video = 'video'
}
const cordovaConstants = {
  videoRecordingMaxDuration: 300000, // 5 minutes
  audioRecordingMaxDuration: 300000 // 5 minutes
}

const taskColours = ['#A861A6', '#F84016', '#009F4B', '#A9BD50', '#FFDE01']

// User roles determine what is displayed on screen UI
enum USER_ROLE {
  user = 'user',
  monitor = 'monitor',
  admin = 'admin',
  logs = 'logs',
  teacher = 'teacher'
}
// Ensure enums match those defined in env file
const t = Object.values(USER_ROLE)
if (
  !userRoles.every((e: string) => t.indexOf(e as USER_ROLE) > -1) ||
  userRoles.length !== t.length
) {
  console.error('USER_ROLE enum mismatch', { t, userRoles })
}

enum ACTIVITY_IDS {
  'ST' = '99703f21-9f2c-49a4-bb10-dcef91e802a7',
  'Larvik' = '1de814f5-f49e-491c-843d-a4c5987ad272',
  'Hybrid' = 'd5e26cee-04a8-4a47-83e9-6bd417d97a5d',
  'Test' = 'f9460ded-1c8e-42c4-8988-9457a0f6b670',
  'Broad Release' = '74b94f1c-4e15-4a9c-b646-9acb587a2d23',
  'Broad Release - testing' = '3431ff54-6a6d-4104-a216-2dc7e64bafb0',
  'RCT 2018 (Norway)' = 'eedb60a3-2e21-49fd-a4c5-dfc92ba02aaf',
  'RCT 2022 (Sweden)' = '2f55c6e5-7ead-46f7-80e2-e61e3e78567e',
  'Demo' = '427adfa8-d5b3-4de5-b0ed-471921d08190',
  'EngageLab' = '17da718d-5010-4bde-bdde-f153f01d9dfc'
}
const ACTIVITY_NAMES_BY_ID: Record<string, string> = {}
Object.entries(ACTIVITY_IDS).forEach((entry) => (ACTIVITY_NAMES_BY_ID[entry[1]] = entry[0]))

enum MORFOLOGICAL_INTROS {
  'None' = 0,
  'Sammensatt' = 1,
  'Avledninger' = 2,
  'Komplekse' = 3
}

enum LanguageCodes {
  iv = 'iv', // Invariant code
  nn = 'nn',
  no = 'no',
  // en = 'en', // english is not currently supported
  sv = 'sv'
}
// The keys used for i18n selection (except for 'system') should correlate to LanguageCodes (used for Squidex GraphQL calls)
enum LanguageNames {
  system = 'system', // System default
  //ar = 'العربية', // Arabic
  //bs = 'bosanski', // Bosnian
  //nb_NO = 'Norsk Bokmål',
  nn = 'Nynorsk',
  no = 'Norsk',
  sv = 'Svensk'
  /*  nn_NO = 'Norsk Nynorsk', */
  // en = 'English',
  //ku = 'Soranî', // Kurdish
  //pl = 'Polski', // Polish
  //so = 'af Soomaali', // Somali
  /*   sq = 'shqip', // Albanian */
  //ta = 'தமிழ்', // Tamil
  //tr = 'Türkçe',
  //ur = 'اردو', // Urdu
  /*  vi = 'tiếng việt', // Vietnamese */
}

interface LF {
  [key: string]: Array<LanguageCodes>
}
// Note that every language must have a fallback defined!
const LanguageFallbacks: LF = {
  en: [LanguageCodes.no],
  nn: [LanguageCodes.no],
  no: [],
  sv: [LanguageCodes.no]
}

const SpeechSounds = {
  narrator: {
    N01: 'computer_narrator/01N.mp3',
    N02: 'computer_narrator/02N.mp3',
    N03: 'computer_narrator/03N.mp3',
    N04: 'computer_narrator/04N.mp3',
    N06: 'computer_narrator/06N.mp3',
    N11: 'computer_narrator/11N.mp3',
    N29: 'computer_narrator/29N.mp3',
    N31: 'computer_narrator/31N.mp3',
    N34: 'computer_narrator/34N.mp3',
    N35: 'computer_narrator/35N.mp3',
    N37: 'computer_narrator/37N.mp3',
    N39: 'computer_narrator/39N.mp3',
    N45: 'computer_narrator/45N.mp3',
    N56: 'computer_narrator/56N.mp3',
    N64: 'computer_narrator/64N.mp3'
  },
  computer: {
    C05: 'computer_narrator/05C.mp3',
    C07: 'computer_narrator/07C.mp3',
    C08: 'computer_narrator/08C.mp3',
    C10: 'computer_narrator/10C.mp3',
    C12: 'computer_narrator/12C.mp3',
    C13: 'computer_narrator/13C.mp3',
    C14: 'computer_narrator/14C.mp3',
    C15: 'computer_narrator/15C.mp3',
    C16: 'computer_narrator/16C.mp3',
    C17: 'computer_narrator/17C.mp3',
    C18: 'computer_narrator/18C.mp3',
    C19: 'computer_narrator/19C.mp3',
    C20: 'computer_narrator/20C.mp3',
    C21: 'computer_narrator/21C.mp3',
    C22: 'computer_narrator/22C.mp3',
    C23: 'computer_narrator/23C.mp3',
    C24: 'computer_narrator/24C.mp3',
    C25: 'computer_narrator/25C.mp3',
    C26: 'computer_narrator/26C.mp3',
    C27: 'computer_narrator/27C.mp3',
    C28: 'computer_narrator/28C.mp3',
    C30: 'computer_narrator/30C.mp3',
    C32: 'computer_narrator/32C.mp3',
    C36: 'computer_narrator/36C.mp3',
    C38: 'computer_narrator/38C.mp3',
    C40: 'computer_narrator/40C.mp3',
    C41: 'computer_narrator/41C.mp3',
    C41_44: 'computer_narrator/41_44C.mp3',
    C42: 'computer_narrator/42C.mp3',
    C43: 'computer_narrator/43C.mp3',
    C44: 'computer_narrator/44C.mp3',
    C46: 'computer_narrator/46C.mp3',
    C47: 'computer_narrator/47C.mp3',
    C48: 'computer_narrator/48C.mp3',
    C49: 'computer_narrator/49C.mp3',
    C50: 'computer_narrator/50C.mp3',
    C51: 'computer_narrator/51C.mp3',
    C52: 'computer_narrator/52C.mp3',
    C53: 'computer_narrator/53C.mp3',
    C54: 'computer_narrator/54C.mp3',
    C55: 'computer_narrator/55C.mp3',
    C56: 'computer_narrator/56C.mp3',
    C57: 'computer_narrator/57C.mp3',
    C58: 'computer_narrator/58C.mp3',
    C59: 'computer_narrator/59C.mp3',
    C60: 'computer_narrator/60C.mp3',
    C61: 'computer_narrator/61C.mp3',
    C62: 'computer_narrator/62C.mp3',
    C63: 'computer_narrator/63C.mp3',
    C65: 'computer_narrator/65C.mp3',
    C66: 'computer_narrator/66C.mp3',

    C69: 'computer_narrator/69C.mp3',
    C70: 'computer_narrator/70C.mp3'
  },
  child: {
    A09: 'computer_narrator/09A.mp3'
  },
  morfological: {
    MI33: 'computer_narrator/33MI-lavt.mp3',
    MI33_high: 'computer_narrator/33MI_høyt.mp3',
    MI33_best: 'computer_narrator/33MIbest.mp3',
    MI33_org: 'computer_narrator/33MIorg.mp3',
    MI33_orgtest: 'computer_narrator/33MIorgtest2.mp3',
    MI33_test: 'computer_narrator/33MItest.mp3'
  },
  instructions: {
    tasks: {
      T3: 'task_instructions/tasks/type3.mp3',
      T4: 'task_instructions/tasks/type4.mp3',
      T6: 'task_instructions/tasks/type6.mp3',
      T7: 'task_instructions/tasks/type7.mp3',
      T9: 'task_instructions/tasks/type9.mp3',
      T10: 'task_instructions/tasks/type10.mp3',
      T10C: 'task_instructions/tasks/type10_compound.mp3',
      T12C: 'task_instructions/tasks/type12_compound.mp3',
      T12E1: 'task_instructions/tasks/type12_ending_alt1.mp3',
      T12E2: 'task_instructions/tasks/type12_ending_alt2.mp3'
    },
    warmups: {
      T1: 'task_instructions/warmups/type1.mp3',
      T2: 'task_instructions/warmups/type2.mp3',
      T3: 'task_instructions/warmups/type3.mp3',
      T4: 'task_instructions/warmups/type4.mp3',
      T5: 'task_instructions/warmups/type5.mp3',
      T6: 'task_instructions/warmups/type6.mp3',
      T7: 'task_instructions/warmups/type7.mp3',
      T8: 'task_instructions/warmups/type8.mp3',
      T9: 'task_instructions/warmups/type9.mp3',
      T10: 'task_instructions/warmups/type10.mp3',
      T11: 'task_instructions/warmups/type11.mp3'
    }
  }
}

enum QUESTION_TYPES {
  question = 'question',
  mastery = 'mastery',
  picturebook = 'picturebook'
}

enum TASK_TYPES {
  Tasktype1 = 'Tasktype1',
  Tasktype2 = 'Tasktype2',
  Tasktype2mp = 'Tasktype2mp',
  Tasktype22st = 'Tasktype22st',
  Tasktype23st = 'Tasktype23st',
  Tasktype24st = 'Tasktype24st',
  Tasktype3 = 'Tasktype3',
  Tasktype3mp = 'Tasktype3mp',
  Tasktype4 = 'Tasktype4',
  Tasktype5 = 'Tasktype5',
  Tasktype6 = 'Tasktype6',
  Tasktype7 = 'Tasktype7',
  Tasktype8 = 'Tasktype8',
  Tasktype9 = 'Tasktype9',
  Tasktype9mp = 'Tasktype9mp',
  Tasktype10 = 'Tasktype10',
  Tasktype10mp = 'Tasktype10mp',
  Tasktype11 = 'Tasktype11',
  Tasktype11st = 'Tasktype11st',
  Tasktype12 = 'Tasktype12',
  Tasktype13 = 'Tasktype13'
}

enum CMS_MP_TASK_NAMES {
  Tasktype2mp = 'type2mp',
  Tasktype3mp = 'type3mp',
  Tasktype9mp = 'type9mp',
  Tasktype10mp = 'type10mp'
}

enum CMS_ST_TASK_NAMES {
  Tasktype11st = 'type11st',
  Tasktype22st = 'type22st',
  Tasktype23st = 'type23st',
  Tasktype24st = 'type24st'
}

enum CMS_SP_TASK_NAMES {
  Tasktype1 = 'type1sp',
  Tasktype2 = 'type2sp',
  Tasktype3 = 'type3sp',
  Tasktype4 = 'type4sp',
  Tasktype5 = 'type5sp',
  Tasktype6 = 'type6sp',
  Tasktype7 = 'type7sp',
  Tasktype8 = 'type8sp',
  Tasktype9 = 'type9sp',
  Tasktype10 = 'type10sp',
  Tasktype11 = 'type11sp',
  Tasktype12 = 'type12sp',
  Tasktype13 = 'type13sp'
}

const CMS_TASK_NAMES_UNIFICATION = {
  ...CMS_MP_TASK_NAMES,
  ...CMS_SP_TASK_NAMES,
  ...CMS_ST_TASK_NAMES
}

type CMS_TASK_NAMES = keyof typeof CMS_TASK_NAMES_UNIFICATION

enum WordIndexName {
  None = 'None',
  Word1 = 'Word 1',
  Word2 = 'Word 2',
  Word3 = 'Word 3',
  Word4 = 'Word 4',
  Word5 = 'Word 5',
  Word6 = 'Word 6',
  Preselected = 'Preselected'
}
/* --------- State Variables ---------  */

enum ViewState {
  Login = 'Login',
  Loggedin = 'Loggedin',
  Lobby = 'Lobby',
  Delay = 'Delay',
  Tasks = 'Tasks',
  Dashboard = 'Dashboard'
}
enum LoginMode {
  AppStarted = 'AppStarted',
  LoggedOut = 'LoggedOut'
}

enum LobbyMode {
  SessionLocked = 'SessionLocked',
  SessionUnlocked = 'SessionUnlocked',
  SessionCompleted = 'SessionCompleted'
}
enum TaskMode {
  Tests = 'Tests',
  Warmups = 'Warmups'
}
enum DelayMode {
  None = 'None',
  AwaitingDownload = 'AwaitingDownload',
  NoActivitiesFound = 'NoActivitiesFound',
  NoEpisodesFound = 'NoEpisodesFound',
  NoTasksFound = 'NoTasksFound',
  NoCollectionsFound = 'NoCollectionsFound',
  NoSessionsFound = 'NoSessionsFound',
  DemoComplete = 'DemoComplete',
  WarmupsStarting = 'WarmupsStarting',
  WarmupsFinished = 'WarmupsFinished',
  NoWarmups = 'NoWarmups',
  AccessDenied = 'AccessDenied'
}

enum SpeakerType {
  narrator,
  computer
}

enum QUESTION_MODE {
  warmup = 'warmup',
  task = 'task'
}

export enum EPISODE_TYPE {
  pretest = 'pretest',
  posttest = 'posttest',
  regular = 'regular'
}

const availableAvatars = [
  {
    name: 'Alpha',
    ref: 'avatar_alien.svg'
  },
  {
    name: 'Beta',
    ref: 'Avatar_bear.svg'
  },
  {
    name: 'Gamma',
    ref: 'Avatar_dog.svg'
  },
  {
    name: 'Delta',
    ref: 'avatar_horse.svg'
  },
  {
    name: 'Episilon',
    ref: 'avatar_mouse.svg'
  },
  {
    name: 'Zeta',
    ref: 'Avatar_rabbit.svg'
  },
  {
    name: 'Eta',
    ref: 'Avatar_raton.svg'
  },
  {
    name: 'Theta',
    ref: 'avatar_tiger.svg'
  }
] as Avatar[]

export default availableAvatars

export interface ActivityOptionListItem {
  itemName: string
  item: Omit<Activity, 'episodes'>
}

export interface ExternalSquidexData {
  url: string
  slug: string
}

export interface StateVariables {
  viewState?: ViewState
  loginMode?: LoginMode
  lobbyMode?: LobbyMode
  taskMode?: TaskMode
  delayMode?: DelayMode
}

export interface UserOptionListItem {
  itemName: string
  item: User | undefined
}

export interface GroupOptionListItem {
  itemName: string
  item: Group | undefined
}

export interface GameTypeListItem {
  itemName: string
  item: string
}

export interface OptionItem {
  id: string
  title: string
}

const categoryTypes = [
  { value: 0, label: 'Real word' },
  { value: 1, label: 'Trained item' },
  { value: 2, label: 'Non-word' }
]

enum CategoryType {
  RealWord = 'Real word',
  TrainedItem = 'Trained item',
  NonWord = 'Non-word'
}

const stemTypes = [
  { value: 1, label: 'Stem' },
  { value: 2, label: 'Morphed Stem' }
]

enum StemType {
  Stem = 'Stem',
  MorphedStem = 'Morphed Stem',
  None = 'None'
}

enum ParticipantReadyState {
  NotReady = 'NotReady',
  Initiated = 'Initiated',
  Paired = 'Paired',
  ProceedToGame = 'ProceedToGame'
}

enum ParcelType {
  Initial = 'initial', // used as default parcel type

  // User connection (to game session)
  UserConnect = 'userConnect', // Sent out by the connecting user after connecting / joining game -> paired ( I)
  UserDisconnect = 'userDisconnect', // Sent out by the disconnecting user before leaving / leaving game -> unpaired
  UserReconnect = 'userReconnect', // sent out once a player is ready to continue a MP task (in HB session or warmup)

  // Tasks ( syncing purposes in MP session)
  TaskSync = 'taskSync', // used for syncing first decision, advice and final decision in MP session
  TaskSyncST = 'taskSyncST', // used for syncing student-teacher content that does not follow the decision loop
  TaskShuffleSync = 'taskShuffleSync', // used for non-TaskSync related sync operations -> sync shuffled images, words etc.

  FinalDecisionStatus = 'finalDecisionStatus', // used after final decision was sent to notify both participants if the solution was correct
  TaskProceed = 'taskProceed', // used to let the leader know that the advisor changed task -> triggers another shuffleAndSync operation

  // Audio --ST mode
  AudioSnippet = 'audioSnippet', // used to transfer audio bits from one user to another

  // Observer  --ST mode
  ScreenState = 'screenState', // used to transfer a screenshot from student to teacher

  // Pairing - changes made in monitor section
  PairingUpdate = 'pairingUpdate' // a teacher has updated the pairing for the student, this forces a page refresh on the dashboard
}

enum TaskCallbackType {
  FinalDecisionAccept = 'FinalDecisionAccept',
  FinalDecisionReject = 'FinalDecisionReject',
  Shuffle = 'Shuffle',
  ShuffleOnRequest = 'ShuffleOnRequest',
  Phase = 'Phase',
  StudentTeacherAction = 'StudentTeacherAction'
}

export type SortUnionType = Episode | Session | QuestionUnion

export type TaskCallbackParam =
  | TaskSync
  | TaskSyncST
  | FinalDecisionStatus
  | string
  | string[]
  | boolean
  | number
  | undefined
export interface TaskCallback {
  (args: TaskCallbackParam): void
}

enum UserTaskRole {
  Leader = 'Leader',
  Advisor = 'Advisor',
  Student = 'Student',
  Teacher = 'Teacher',
  Default = 'Default'
}

enum FinalDecisionStatus {
  NotSubmitted = 'NotSubmitted',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  AcceptedAdvice = 'AcceptedAdvice',
  Confirmed = 'Confirmed' //When leader and advisor choses same, and leader pick that solution.
}

enum DialogMessageType {
  Confirmation = 'Confirmation', // dialog with message and button to invoke a specified callback
  ResultSplashWrong = 'ResultSplashWrong', // splash screen to indicate a result after a final decision has been submitted -> wrong
  ResultSplashCorrect = 'ResultSplashCorrect', // splash screen to indicate a result after a final decision has been submitted -> correct
  Splash = 'Splash', // splash screen to indicate role changes or new tasks
  Information = 'Information',
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error'
}

enum SyncType {
  Pairing = 'Pairing',
  FirstDecision = 'FirstDecision', // client side parcel to send leaders first solution
  Advice = 'Advice', // client side parcel to send advisors solution
  FinalDecision = 'FinalDecision' // client side parcel to send leaders final solution
}

enum STSyncType {
  PathSync = 'PathSync',
  PathComplete = 'PathComplete',
  CardFlip = 'CardFlip',
  StatusUpdate = 'StatusUpdate',
  ResultStatus = 'ResultStatus'
}

export interface DialogMessage {
  message: string
  element?: SolutionUnion
  duration: number
  type: DialogMessageType
  timestamp: Date
  callback?: () => void
}

export interface TaskSender {
  sentAs: string // leaders parcels might have a priority over a parcel from an advisor regarding certain attributes
}

export interface FinalDecisionStatusData {
  sender: TaskSender
  status: FinalDecisionStatus
}

export interface TaskSyncST {
  sender: TaskSender
  type: STSyncType
  task_id: string
  solution: STSolutionUnion
}

export interface TaskSyncWord extends TaskSync {
  solution: TT3Word | TT10Word
}

export interface TaskSyncImage extends TaskSync {
  solution: Image
}

export interface TaskSyncTT11Image extends TaskSync {
  solution: TT11Image
}

export interface TaskShuffleSync {
  sender: TaskSender
  shuffleOrder: string[]
  type?: string
}

export interface ParticipantInformation {
  name: string
  avatar: Avatar
}

export {
  deviceType,
  baseUrl,
  cmsUrl,
  assetRoot,
  userRoles,
  USER_ROLE,
  SESSION_TYPES,
  ACTIVITY_IDS,
  ACTIVITY_NAMES_BY_ID,
  consentStates,
  cmsTokenUrl,
  cmsClientId,
  cmsClientSecret,
  cordovaConstants,
  appVersion,
  taskColours,
  LanguageCodes,
  LanguageNames,
  LanguageFallbacks,
  useLocalAssets,
  localAssetUrl,
  SKIP_SESSION_PASSWORD,
  MORFOLOGICAL_INTROS,
  SpeechSounds,
  stemTypes,
  StemType,
  categoryTypes,
  CategoryType,
  QUESTION_MODE,
  QUESTION_TYPES,
  SpeakerType,
  DelayMode,
  TaskMode,
  LobbyMode,
  LoginMode,
  ViewState,
  CMS_TASK_NAMES,
  CMS_ST_TASK_NAMES,
  CMS_SP_TASK_NAMES,
  CMS_MP_TASK_NAMES,
  CMS_TASK_NAMES_UNIFICATION,
  TASK_TYPES,
  WordIndexName,
  CordovaPathName,
  MediaType,
  ParticipantReadyState,
  ParcelType,
  mqttReconnectAttempts,
  DialogMessageType,
  UserTaskRole,
  SyncType,
  STSyncType,
  FinalDecisionStatus,
  TaskCallbackType
}
