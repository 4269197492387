import { computed, ComputedRef, ref } from 'vue'
import { apiRequest } from '../api/apiRequest.js'
import { XHR_REQUEST_TYPE } from '@/models/main.js'

// Export the interface for settings
export interface Settings {
  globalPassword: string
  globalPasswordToggle: boolean
  globalMessage: string
  globalMessageToggle: boolean
  feideLoginPassword: string
  feideLoginPasswordToggle: boolean
  smsLoginPassword: string
  smsLoginPasswordToggle: boolean
  showWalkieTalkie: boolean
}

// State with typed settings
const state = ref<{ settings: Settings }>({
  settings: {
    globalPassword: '',
    globalPasswordToggle: false,
    globalMessage: '',
    globalMessageToggle: false,
    feideLoginPassword: '',
    feideLoginPasswordToggle: true,
    smsLoginPassword: '',
    smsLoginPasswordToggle: false,
    showWalkieTalkie: true
  }
})

const getters = {
  get settings(): ComputedRef<Settings> {
    return computed(() => state.value.settings)
  }
}

const actions = {
  async fetchSettings() {
    try {
      const response = await apiRequest<Settings>({
        route: '/api/settings', // Your API route for fetching settings
        method: XHR_REQUEST_TYPE.GET
      })
      if (response) state.value.settings = response
      return response
    } catch (error: any) {
      console.error('Failed to fetch settings', error)
      const errorMessage = error.message || 'An unknown error occurred'
      alert(
        `Failed to fetch settings: ${errorMessage}.\n\nCheck your network connection and restart the app.`
      )
    }
  },

  async updateSettings(updatedSettings: Settings) {
    try {
      await apiRequest({
        route: '/api/settings', // Your API route for updating settings
        method: XHR_REQUEST_TYPE.PUT,
        credentials: true,
        body: updatedSettings
      })
      state.value.settings = updatedSettings // Update local state
    } catch (error: any) {
      console.error('Failed to update settings', error)
      const errorMessage = error.message || 'An unknown error occurred'
      alert(`Failed to update settings: ${errorMessage}`)
    }
  }
}

export default function useSettingsStore() {
  return {
    getters,
    actions
  }
}
