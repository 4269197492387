/*
 Designed and developed by Richard Nesnass

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see <http://www.gnu.org/licenses/>.
 */
import moment from 'moment'
import { nanoid } from 'nanoid'
import { uuid } from '@/utilities'
import { SpriteSheet, Stage } from 'createjs-module'
import {
  USER_ROLE,
  ParticipantInformation,
  LanguageNames,
  TASK_TYPES,
  CMS_TASK_NAMES,
  QUESTION_MODE,
  ParcelType,
  FinalDecisionStatusData,
  TaskShuffleSync,
  TaskSyncST,
  CMS_SP_TASK_NAMES,
  CMS_MP_TASK_NAMES,
  CMS_ST_TASK_NAMES,
  UserTaskRole,
  TaskSender,
  SyncType
} from '@/constants'
import { STSolutionUnion, SolutionUnion } from './tasktypes'
import { ColDef } from 'ag-grid-community'

// ---------------  Utility -----------------

export interface ColumnDef {
  headerName: string
  field: string

  children?: {
    field: string
    headerName: string
    columnGroupShow?: string
    // eslint-disable-next-line
    [x: string]: any
  }[]
  hide?: boolean | unknown
  editable?: boolean | unknown
  // eslint-disable-next-line
  [x: string]: any
}

export interface LottieOptions {
  loop?: boolean
  autoplay?: boolean
  // eslint-disable-next-line
  animationData?: string | object
  path?: string
  src?: string
  rendererSettings?: {
    preserveAspectRatio: boolean
    clearCanvas: boolean
    progressiveLoad: boolean
    hideOnTransparent: boolean
  }
}

export interface Manifest {
  src: string
  id: string
}
export interface Metadata {
  name: string
  frames: unknown
}
export interface Library {
  properties: {
    id: string
    width: number
    height: number
    fps: number
    color: string
    opacity: number
    manifest: Manifest[]
    preloads: string[]
  }
  ssMetadata: Metadata[]
  Stage: Stage
}
export interface Composition {
  getLibrary: () => Library
  getSpriteSheet: () => SpriteSheet
  getImages: () => Record<string, unknown>
}
export interface AdobeAn {
  compositions: Record<string, Composition>
  getComposition: (key: string) => Composition
  bootstrapCallback: (callback: (compId: string) => void) => void
  compositionLoaded: (id: string) => void
}

// ---------------  Models -----------------

export interface CallbackOneParam<T, U = void> {
  (arg?: T): U
}
export interface Callback {
  (...args: unknown[]): unknown
}

// This defined the additional functions available on a Question Type component
// This allows Question.vue to control the question type child
// The child should implement appropriate code that runs when these functions are called
/* export interface AugmentedQuestionType extends Vue {
   forwardInternal: () => void // Called when the user clicks the white 'forward' arrow
   onIntroductionStart: () => void // Called when introduction begins
   onIntroductionEnd: () => void // Called when introduction ends
 } */

export interface LocalUser extends Record<string, unknown> {
  _id: string
  jwt: string
  lastLogin: Date
  pin: string
  name: string
  selected: boolean
}
// General App settings that should be saved to disk
export interface PersistedAppState extends Record<string, unknown> {
  localUsers: Record<string, LocalUser>
}

export interface DialogConfig {
  title: string
  text: string
  visible: boolean
  confirm: Callback
  confirmText: string
  cancel: Callback
  cancelText: string
}

// ------------- Classes -----------------

export interface CordovaDataType {
  readFile?: boolean // Returns the content if true, returns a FileEntry if false  (read)
  asText?: boolean // false   Set to true if reading a text or JSON file, otherwise binary will be used  (read/write)
  asJSON?: boolean // true   Set to false to read/write a file without parsing or stringifying JSON  (read/write)
  overwrite?: boolean // false   Set to true to overwrite an existing file (open file)
  append?: boolean // false   Set to true to append data to the end of the file (write)
  path?: string[] // Path to the file below the root as an array of directory names (read/write)
  fileName?: string // name of the file on disk (read/write)
  data?: unknown | Blob | string // the content to be written  (write)
  file?: FileEntry // the FileEntry object in memory
  fileToMove?: FileEntry | MediaFile // the file entry object in momory for the file to be moved
}
export class CordovaData {
  readFile = false
  asText = false
  asJSON = true
  overwrite = false
  append = false
  path: string[]
  fileName = ''
  data?: unknown | string | Blob
  file?: FileEntry
  fileToMove?: /* data */ FileEntry | MediaFile /* video */

  constructor(data: CordovaDataType) {
    this.path = []
    if (data) {
      this.readFile = data.readFile ? data.readFile : false
      this.asText = data.asText ? data.asText : false
      this.asJSON = data.asJSON ? data.asJSON : true
      this.overwrite = data.overwrite ? data.overwrite : false
      this.append = data.append ? data.append : false
      this.path = data.path ? data.path : []
      this.fileName = data.fileName ? data.fileName : ''
      this.data = data.data
      this.file = data.file
      this.fileToMove = data.fileToMove
    }
  }
}

// ---------------  Base CMS model classes ------------------
// --- Extend these to represent real Squidex model types ---

export enum DISPLAY_MODE {
  linear = 'linear',
  shuffle = 'shuffle',
  mastery = 'mastery'
}

export enum SESSION_TYPE {
  singlePlayer = 'single player',
  multiPlayer = 'multi player',
  studentTeacher = 'student teacher'
}

export interface QuestionData {
  id: string
  __typename: string
  name?: string
  type?: TASK_TYPES
  thumbnail?: string
  recordAudio?: boolean
  flatData?: unknown
  data?: unknown
}

// Question class should be extended to represent a Project's actual Question shape (see questionModels.ts)
export abstract class Question {
  id: string
  name: string
  __typename: string // This string is used to generate components. The component name must match it
  type: TASK_TYPES
  mode: QUESTION_MODE
  disabled = false
  word = ''
  thumbnail: string
  recordAudio: boolean

  constructor(spec: QuestionData, mode?: QUESTION_MODE) {
    this.id = spec.id
    this.name = spec.name || ''
    this.__typename = spec.__typename
    this.type = spec.type ? spec.type : TASK_TYPES.Tasktype1
    this.mode = mode || QUESTION_MODE.task
    this.recordAudio = !!spec.recordAudio
    this.thumbnail = spec.thumbnail || ''
  }
}

// ----------  Squidex response shapes --------------

// Data level of a Squidex GraphQL response. Can be supplied as single or array
// Extend this interface to represent different responses for various Sett and Question types
export interface CmsGQLData {
  __typename: string
  id?: string
  flatData?: Record<string, unknown>
  data?: Record<string, unknown>
}
// Shape of the Sett -> Question response

export type CmsQuestionUnionType = {
  [key in CMS_TASK_NAMES]: CmsGQLData[]
}

export interface CmsQuestionData extends CmsGQLData {
  flatData: {
    warmups: { [key in CMS_TASK_NAMES]: CmsGQLData[] }
    tasksMp: { [key in CMS_MP_TASK_NAMES]: CmsGQLData[] }
    tasksSp: { [key in CMS_SP_TASK_NAMES]: CmsGQLData[] }
    tasksSt: { [key in CMS_ST_TASK_NAMES]: CmsGQLData[] }
  }
}

// Top level of a Squidex GrapghQL response
export interface CmsGQLQuery {
  data?: {
    results: CmsGQLData[] | CmsGQLData | CmsQuestionData
    items?: CmsGQLData[]
  }
  errors?: []
  access_token?: string
}

// ---------------  User & Player -----------------

interface ProgressData {
  itemId: string
  description: string
  parentId: string
  userId: string // user ID or 'multiplayer'
  completed?: boolean
  completions?: string[] | Date[]
  attempts?: string[] | Date[]
}
// Progress follows the flattened shape of CMS Sett and Question data
// meaning: Progress tracks the Player's completion status on a particular Sett or Question
export class Progress {
  itemId: string // CMS ID of the tracked item
  description: string // Textual description of the item
  parentId: string // CMS ID of the current parent of this item
  userId: string // MongoDB user ID or 'multiplayer'
  completed = false
  completions: Date[] = [] // Completions marked only if item was not previously completed
  attempts: Date[] = [] // Attempts on this item (increments if already completed, may be larger than completions[])
  constructor(data: ProgressData) {
    this.itemId = data.itemId
    this.description = data.description
    this.parentId = data.parentId
    this.userId = data.userId || 'multiplayer'
    this.completed = !!data.completed
    if (data.completions && data.completions.length > 0) {
      data.completions.forEach((cp: string | Date) => {
        this.completions.push(new Date(cp))
      })
    }
    if (data.attempts && data.attempts.length > 0) {
      data.attempts.forEach((cp: string | Date) => {
        this.attempts.push(new Date(cp))
      })
    }
  }

  // Return the most recent completion
  get latestCompletion(): Date {
    return this.completions[this.completions.length - 1]
  }

  // Return the most recent attempt
  get latestAttempt(): Date {
    return this.attempts[this.attempts.length - 1]
  }

  // Set this Progress to be 'completed'
  // Add a new timestamp for this completion
  // Returns the total current number of completions
  complete(): number {
    const newDate = new Date()
    if (!this.completed) {
      this.completed = true
      this.completions.push(newDate)
    }
    this.attempts.push(newDate)
    return this.completions.length
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): ProgressData {
    const pojo = { ...this }
    return pojo
  }
}

// TASK SYNC PARCEL
export interface TaskSync {
  sender: TaskSender
  type: SyncType
  task_id: string
  solution_id: string
  solution: SolutionUnion | STSolutionUnion
  additionalSolutions?: SolutionUnion[] | STSolutionUnion[]
}

export interface TrackingSearch {
  taskID?: string // ID of the associated question, set or picturebook etc. (from Squidex CMS)
  gameID?: string // ID of the associated Game
  isMedia: boolean // Tracking is usable as a 'media' item
}

export class Choice {
  attempt: number = 1 // # of tries to find an accepted/correct answer: start at 1, increment if leader decides to try again OR 'phase 3' decision was incorrect
  phase: number = 1 // 1=leader's initial choice, 2 = follower's suggestion, 3 = leader's decision   /  Only 1 phase for single player tasks
  round: number = 1 // current 'round' in a multi round task type
  createdAt: Date | string = new Date() // the creation stamp for this choice
  duration: number = 0 // Duration of the choice
  committed: boolean = false // answer was committed via the send button (tts except 2), or sent for judgment automatically (tt2)
  correct: boolean = false // This particular answer is considered correct
  /* Answer was valid, i.e. it could have been passed for judgment if the player sent it.
     Invalid answer are all interface actions which started with a selection of a target item,
     but would not allow the phase to finish - i.e. Dropping a word in the wrong place (tt3,tt10),
     releasing the arrow where there is no word (tt9).
     TT2 answers are always valid (and are currently sent immediately).
   */
  valid: boolean = false

  // Dependent on taskType:
  target: string = '' // Delimited with ';'
  content: string = '' // Delimited with ';'
  response: string = ''
  finaldecisionstatus: string = ''
}

export interface TrackingData {
  id: string // Unique key used to map this item
  createdAt: Date | string // the creation stamp for this Tracking
  duration: number

  userID: string
  userName: string
  userRole: UserTaskRole
  pairName: string // IDs of the two participants
  gameID: string // ID of the associated Game
  activityID: string // ID of the activity in use
  episodeID: string // ID of the episode in use
  collectionIndex: number // Index of the collection as it appears in Squidex
  collectionPos: number // Position of the collection in the game (post-shuffle)
  sessionID: string // ID of the session in use
  sessionPos: number // Position of the Session in the game (post-shuffle)
  sessionName: string // Squidex name of the Session in use
  taskID: string // ID of the task in use
  taskPos: number // Position of the Task in the game (post-shuffle)
  taskName: string // Squidex name of the Task in use
  taskType: TASK_TYPES
  affix: string
  stem: string
  exited: boolean

  choices: Choice[]

  inactive_count: number
  inactive_duration: number
  use_audio_instructions: number
  use_audio_content_items: number
}

export class Tracking implements TrackingData {
  id = uuid() // Unique key used to map this item
  createdAt: Date | string = new Date() // the creation stamp for this Tracking
  duration: number = 0

  userID = ''
  userName: string = ''
  userRole: UserTaskRole = UserTaskRole.Leader
  pairName: string = '' // IDs of the two participants
  gameID = '' // ID of the associated Game
  activityID = '' // ID of the activity in use
  episodeID = '' // ID of the episode in use
  collectionIndex = 0 // Index of the collection as it appears in Squidex
  collectionPos = 0 // Position of the collection in the game (post-shuffle)
  sessionID = '' // ID of the session in use
  sessionPos = 0 // Position of the Session in the game (post-shuffle)
  sessionName = '' // Squidex name of the Session in use
  taskID = '' // ID of the task in use
  taskPos = 0 // Position of the Task in the game (post-shuffle)
  taskName = '' // Squidex name of the Task in use
  taskType: TASK_TYPES = TASK_TYPES.Tasktype10mp
  affix = ''
  stem = ''
  exited = false

  choices: Choice[] = []

  inactive_count: number = 0
  inactive_duration: number = 0
  use_audio_instructions: number = 0
  use_audio_content_items: number = 0

  // Status
  localSynced = false // saved to disk locally
  serverSynced = false // saved to our server successfully
  storageSynced = false // sent to TSD successfully

  lastCall = Date.now()
  completed = false

  constructor(data?: Partial<Tracking>) {
    this.choices = []
    if (data) this.update(data)
  }

  update(data: Partial<Tracking>): void {
    if (this.completed)
      return console.log(`Warning: Tracking ${this.id} was already marked completed`)

    if (data.id) this.id = data.id
    if (data.createdAt) this.createdAt = new Date(data.createdAt)
    if (data.duration) this.duration = data.duration

    if (data.userID) this.userID = data.userID
    if (data.userName) this.userName = data.userName
    if (data.userRole) this.userRole = data.userRole
    if (data.pairName) this.pairName = data.pairName

    if (data.gameID) this.gameID = data.gameID
    if (data.activityID) this.activityID = data.activityID
    if (data.episodeID) this.episodeID = data.episodeID
    if (data.collectionIndex) this.collectionIndex = data.collectionIndex
    if (data.collectionPos) this.collectionPos = data.collectionPos
    if (data.sessionID) this.sessionID = data.sessionID
    if (data.sessionPos) this.sessionPos = data.sessionPos
    if (data.sessionName) this.sessionName = data.sessionName
    if (data.taskID) this.taskID = data.taskID
    if (data.taskPos) this.taskPos = data.taskPos
    if (data.taskName) this.taskName = data.taskName
    if (data.taskType) this.taskType = data.taskType
    if (data.affix) this.affix = data.affix
    if (data.exited) this.exited = data.exited

    if (data.inactive_count) this.inactive_count = data.inactive_count
    if (data.inactive_duration) this.inactive_duration = data.inactive_duration
    if (data.use_audio_instructions) this.use_audio_instructions = data.use_audio_instructions
    if (data.use_audio_content_items) this.use_audio_content_items = data.use_audio_content_items

    this.localSynced = !!data.localSynced
    this.serverSynced = !!data.serverSynced
    this.storageSynced = !!data.storageSynced

    if (data.choices) {
      this.choices = []
      data.choices.forEach((c: Partial<Choice>) => {
        const choice: Choice = {
          attempt: c.attempt ?? 1,
          correct: c.correct ?? false,
          duration: c.duration ?? 0,
          target: c.target ?? '',
          content: c.content ?? '',
          phase: c.phase ?? 0,
          round: c.round ?? 0,
          createdAt: c.createdAt ? new Date(c.createdAt) : new Date(),
          committed: c.committed ?? false,
          response: c.response ?? '',
          valid: c.valid ?? false,
          finaldecisionstatus: c.finaldecisionstatus ?? ''
        }
        this.choices.push(choice)
      })
    }
  }

  // Complete this Tracking by setting its duration and possibly 'data'
  complete(): void {
    const startDate = moment(this.createdAt)
    const endDate = moment()
    this.duration = endDate.diff(startDate, 'seconds')
    this.completed = true
    this.localSynced = false
    this.serverSynced = false
  }

  get elapsedTimeSinceLastCall(): number {
    const t = Date.now() - this.lastCall
    this.lastCall = Date.now()
    return t
  }

  public get answerDetailsAsString(): string {
    const s = JSON.stringify(this.choices)
    console.log(s)
    return s
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): Tracking {
    const pojo = { ...this }
    return pojo
  }

  duplicate(): Tracking {
    return { ...this } as Tracking
  }

  static get columnDefs(): ColDef<TrackingData>[] {
    return [
      {
        field: 'id',
        headerName: 'id',
        filter: false,
        cellRenderer: 'agGroupCellRenderer'
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        filter: true
      },
      {
        field: 'duration',
        headerName: 'Duration',
        filter: true
      },
      {
        field: 'userName',
        headerName: 'User Name',
        filter: true
      },
      {
        field: 'userRole',
        headerName: 'User Role',
        filter: true
      },
      {
        field: 'pairName',
        headerName: 'Pair Name',
        filter: true
      },
      {
        field: 'gameID',
        headerName: 'Game ID',
        filter: true
      },
      {
        field: 'activityID',
        headerName: 'Activity ID',
        filter: true
      },
      {
        field: 'episodeID',
        headerName: 'Episode ID',
        filter: true
      },
      {
        field: 'collectionIndex',
        headerName: 'Collection Index in Squidex',
        filter: false
      },
      {
        field: 'collectionPos',
        headerName: 'Collection Displayed Position',
        filter: false
      },
      {
        field: 'sessionID',
        headerName: 'Session ID',
        filter: true
      },
      {
        field: 'sessionPos',
        headerName: 'Session Displayed Position',
        filter: false
      },
      {
        field: 'sessionName',
        headerName: 'Session Name',
        filter: true
      },
      {
        field: 'taskID',
        headerName: 'Task ID',
        filter: true
      },
      {
        field: 'taskPos',
        headerName: 'Task Displayed Position',
        filter: false
      },
      {
        field: 'taskName',
        headerName: 'Task Name',
        filter: false
      },
      {
        field: 'taskType',
        headerName: 'Task Type',
        filter: true
      },
      {
        field: 'affix',
        headerName: 'Affix',
        filter: true
      },
      {
        field: 'inactive_count',
        headerName: 'Inactive Count',
        filter: false
      },
      {
        field: 'inactive_duration',
        headerName: 'Inactive Duration',
        filter: false
      },
      {
        field: 'use_audio_instructions',
        headerName: 'Use Audio Instructions',
        filter: false
      },
      {
        field: 'use_audio_content_items',
        headerName: 'Use Audio Content Items',
        filter: false
      }
    ]
  }

  static get detailColumnDefs(): ColDef[] {
    return [
      {
        field: 'retries',
        headerName: 'Retries',
        filter: false
      },
      {
        field: 'phase',
        headerName: 'Phase',
        filter: false
      },
      {
        field: 'round',
        headerName: 'Round',
        filter: false
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        filter: true
      },
      {
        field: 'duration',
        headerName: 'Duration',
        filter: false
      },
      {
        field: 'committed',
        headerName: 'Committed',
        filter: true
      },
      {
        field: 'correct',
        headerName: 'Correct',
        filter: true
      },
      {
        field: 'target',
        headerName: 'Target',
        filter: false
      },
      {
        field: 'content',
        headerName: 'Content',
        filter: false
      },
      {
        field: 'response',
        headerName: 'Response',
        filter: false
      },
      {
        field: 'rejected',
        headerName: 'Rejected',
        filter: false
      },
      {
        field: 'finaldecisionstatus',
        headerName: 'FinalDecisionStatus',
        filter: false
      }
    ]
  }

  public get created(): Date {
    return new Date(this.createdAt)
  }
}

export interface GroupData {
  _id: string
  name: string
  location: string
}
export class Group {
  _id: string
  name: string
  location: string

  constructor(data?: GroupData | Group) {
    this._id = ''
    this.name = ''
    this.location = ''
    if (data) this.update(data)
  }

  update(data: GroupData | Group): void {
    this._id = data._id
    this.name = data.name
    this.location = data.location
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): Record<string, unknown> {
    return { _id: this._id, name: this.name, location: this.location }
  }
}
export interface GameData {
  _id?: string
  consent: Consent[] // user id -> consent for each participant
  progress: Record<string, Progress> // progress tracking
  status: {
    deleted: boolean
    controlActive: boolean // If true, settings in this section will affect the Game (adjust using Monitor)
    redoTasks: boolean // If true, players in the Game can re-do already completed tasks. NOTE: This affects judgement of 'Set completion'
    skipTasks: boolean // If true, the Participant can open tasks that come after the current incomplete task
    allowedSets: string[] // IDs of sets (as strings) Game is allowed to access. Used in combination with 'active'.
    lastAdjustedByAdmin: string | undefined
    updatedProgressAt: string | undefined
    introsSeen: string[]
  }
  details: {
    currentActivityId: string
    gameType: GameType
    participants: string[] // list of user id's that are part of this game
    leaderAffixes: Record<string, string> // map of affixes played by the leader -> affix(key), id (value)
    shuffleDetails: ShuffledContentDetails
    dyadSplit: boolean // false -> two active users; true -> one or more users are not available for the activity -> split dyad
    lastLeader: string // id of the user that played leader the last time a task was played
  }
  profile: {
    ref: string
    name: string
  }
  sharing: {
    // Deactivated if empty
    groups: string[] // Group IDs
    users: string[] // User IDs
    pinCode: string // Unique PIN to this game.
    url: string // Unique link to this game.
  }
}
export interface SpecialRequestData {
  game: GameData
  data: Record<string, Record<number, { total: number; correct: number }>>
}

export enum SPECIAL_REQUEST_TYPE {
  successresults = 'successresults'
}

export enum ConsentState {
  yes = 'yes',
  no = 'no',
  revoked = 'revoked'
}
export interface Consent {
  id: string
  userId: string
  state: ConsentState
}

export enum GameType {
  SP = 'SP',
  MP = 'MP',
  ST = 'ST'
}

export interface ShuffledContentDetails {
  episodes: {
    id: string
    shuffle: boolean
    collections: {
      index: number
      shuffle: boolean
      sessions: {
        id: string
        shuffle: boolean
        type: string // string (SESSION_TYPE) to determine 'context' in which referenced tasks are supposed to be played in
        tasks: {
          id: string
          type: string
          morph: string
        }[]
      }[]
    }[]
  }[]
}

export class Game {
  _id: string
  consent: Consent[] // user id -> consent for each participant
  progress: Map<string, Progress> // progress tracking
  status: {
    deleted: boolean
    controlActive: boolean // If true, settings in this section will affect the Participant (adjust using Monitor)
    redoTasks: boolean // If true, the Participant can re-do already completed tasks. NOTE: This affects judgement of 'Set completion'
    skipTasks: boolean // If true, the Participant can open tasks that come after the current incomplete task
    allowedSets: string[] // IDs of sets (as strings) Participant is allowed to access. Used in combination with 'active'.
    lastAdjustedByAdmin: Date | undefined
    updatedProgressAt: Date | undefined
    introsSeen: string[]
  }
  details: {
    currentActivityId: string
    gameType: GameType
    participants: string[] // list of user id's that are part of this game
    leaderAffixes: Record<string, string> // map of affixes played by the leader -> affix(key), id (value)
    shuffleDetails: ShuffledContentDetails
    dyadSplit: boolean // false -> two active users; true -> one or more users are not available for the activity -> split dyad
    lastLeader: string // id of the user that played leader the last time a task was played
    language: string // language set by the teacher
  }
  profile: {
    ref: string
    name: string
  }
  sharing: {
    // Deactivated if empty
    groups: string[] // Group IDs
    users: string[] // User IDs
    pinCode: string // Unique PIN to this game.
    url: string // Unique link to this game.
  }

  // Front end control
  selected = false
  deleted = false
  notSyncedProgress: Progress[] = []

  constructor(data?: GameData | Game) {
    this._id = ''
    this.progress = new Map()
    this.consent = [] as Consent[]
    this.status = {
      deleted: false,
      controlActive: false,
      allowedSets: [],
      lastAdjustedByAdmin: undefined,
      updatedProgressAt: undefined,
      redoTasks: false,
      skipTasks: false,
      introsSeen: []
    }
    this.details = {
      currentActivityId: '',
      gameType: GameType.MP, // default to MP
      participants: [],
      leaderAffixes: {} as Record<string, string>,
      shuffleDetails: {} as ShuffledContentDetails,
      dyadSplit: true, // initially "split" since no participants are assigned
      lastLeader: '',
      language: 'no'
    }
    this.profile = {
      name: 'unknown',
      ref: ''
    }
    this.sharing = {
      groups: [],
      users: [],
      pinCode: '',
      url: ''
    }
    if (data) {
      this.updateParticipantData(data)
      this.updateData(data)
      this.updateProgress(data)
    }
  }

  updateParticipantData(data: GameData | Game): void {
    this.details.currentActivityId = data.details.currentActivityId ?? ''
    this.details.participants = data.details.participants ?? []
    this.details.leaderAffixes = data.details.leaderAffixes ?? {}
    this.details.lastLeader = data.details.lastLeader ?? ''
    this.details.dyadSplit = data.details.dyadSplit ?? false
    this.details.gameType = data.details.gameType ?? GameType.MP
    this.consent = data.consent ?? []
    this.details.shuffleDetails = data.details.shuffleDetails ?? {}
  }

  // PRIVATE member to update progress class attribute
  // using 'private' keyword causes problems with TS compile..
  updateProgress(data: GameData | Game): void {
    this.status.lastAdjustedByAdmin = data.status.lastAdjustedByAdmin
      ? new Date(data.status.lastAdjustedByAdmin)
      : undefined
    if (data instanceof Game) this.progress = data.progress
    else {
      for (const pKey in data.progress) {
        if (data.progress[pKey]) {
          const d = data.progress[pKey]
          this.progress.set(pKey, new Progress(d))
        }
      }
    }
  }

  getName(data: GameData | Game): string {
    if (data.profile.name) return data.profile.name
    else if (data.profile.ref) return data.profile.ref
    else if (data._id) return data._id.substring(0, 6) + '...'
    else return 'unknown name'
  }

  updateData(data: GameData | Game): void {
    if (data._id) this._id = data._id

    this.status.deleted = !!data.status.deleted
    this.status.controlActive = data.status.controlActive
    this.status.allowedSets = data.status.allowedSets
    this.status.lastAdjustedByAdmin = data.status.lastAdjustedByAdmin
      ? new Date(data.status.lastAdjustedByAdmin)
      : undefined
    this.status.redoTasks = data.status.redoTasks
    this.status.skipTasks = data.status.skipTasks
    this.status.introsSeen = data.status.introsSeen || []

    this.sharing.groups = data.sharing.groups
    this.sharing.users = data.sharing.users
    this.sharing.pinCode = data.sharing.pinCode
    this.sharing.url = data.sharing.url

    if (data.profile) {
      this.profile.ref = data.profile.ref
      this.profile.name = this.getName(data)
    }
    this.consent = data.consent
  }

  get restrictProgress(): boolean {
    return this.status.controlActive
  }
  get allowRedoTasks(): boolean {
    return this.status.redoTasks
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO(): Record<string, unknown> {
    const progress: Record<string, ProgressData> = {}
    const progressArray = Array.from(this.progress.entries())
    progressArray.forEach((p) => {
      const [key, prog] = p
      progress[key] = prog.asPOJO()
    })
    return {
      _id: this._id,
      consent: this.consent,
      profile: this.profile,
      status: this.status,
      details: this.details,
      sharing: this.sharing,
      progress
    }
  }

  // Server response or Monitor update
  update(data: GameData | Game): void {
    this.updateProgress(data)
    this.updateData(data)
    this.updateParticipantData(data)
  }

  // Return the current number of completions for a given item
  // supplied IDs should be the ids of CMS objects
  /* itemAttempts(itemId: string, parentId: string): number {
    const id = itemId + (parentId ? ':' + parentId : '')
    if (this.progress.has(id)) {
      const p = this.progress.get(id) as Progress
      return p.attempts.length
    } else return 0
  } */

  // Return the current number of completions for a given item
  // supplied IDs should be the ids of CMS objects
  /* itemCompletions(itemId: string, parentId: string): number {
    const id = itemId + (parentId ? ':' + parentId : '')
    if (this.progress.has(id)) {
      const p = this.progress.get(id) as Progress
      return p.completions.length
    } else return 0
  } */

  // Return the 'completed' boolean status for a given item
  itemIsComplete(itemId: string, parentId: string, userId?: string): boolean {
    const id = itemId + (parentId ? ':' + parentId : '') + (userId ? ':' + userId : '')
    return !!(this.progress.has(id) && this.progress.get(id)?.completed)
  }

  completedChildren(parentId: string, userId = ''): string[] {
    const completed = Array.from(this.progress.values())
    const comparison = (p: Progress) =>
      p.parentId === parentId && (p.userId === 'multiplayer' || p.userId === userId) && p.completed
    return completed.filter(comparison).map((p) => p.itemId)
  }
  // Return the most recent completion date for the given ID
  // itemId & parentId should be the IDs of CMS Sett or Question objects
  /* latestCompletionDateFor(itemId: string, parentId: string): Date | undefined {
    const id = itemId + (parentId ? ':' + parentId : '')
    if (this.progress.has(id)) {
      const p = this.progress.get(id) as Progress
      return p.latestCompletion
    } else return
  } */

  /* latestCompletionDate(): Date | undefined {
    return Array.from(this.progress.values())
      .sort((a, b) => a.latestCompletion.valueOf() - b.latestCompletion.valueOf())
      .pop()?.latestCompletion
  } */

  // Return the most recent attempt date for the given item
  // itemId & parentId should be the IDs of CMS Sett or Question objects
  /* latestAttemptDate(itemId: string, parentId: string): Date | undefined {
    const id = itemId + (parentId ? ':' + parentId : '')
    if (this.progress.has(id)) {
      const p = this.progress.get(id) as Progress
      return p.latestAttempt
    } else return
  } */

  // Get or create a Progress, and return it
  // itemId: the CMS ID of a Sett or Question
  // returns: Current number of attempts at this item
  createProgress(itemId: string, parentId: string, description = '', userId = ''): Progress {
    let p: Progress
    const id = itemId + (parentId ? ':' + parentId : '') + (userId ? ':' + userId : '')
    if (this.progress.has(id)) {
      p = this.progress.get(id) as Progress
    } else {
      p = new Progress({ itemId, parentId, userId, description })
      this.progress.set(id, p)
    }
    console.log(p)
    return p
  }

  // Get a Progress item and mark as completed
  // itemId: the CMS ID of a Sett or Question
  // returns: Current number of attempts at this item
  completeProgress(itemId: string, parentId: string, description = '', userId = ''): number {
    const p = this.createProgress(itemId, parentId, description, userId)
    this.status.updatedProgressAt = new Date()
    const completed = p.complete()
    this.notSyncedProgress.push(p)
    return completed
  }
}

export interface UserData {
  _id: string
  status: {
    lastLogin: string
    browserLanguage: string
    canEditPlayers: boolean // User can make changes to Players (add, edit, remove)
    canEditGames: boolean // User can make changes to Games
  }
  profile: {
    username: string
    fullName: string
    email: string
    mobil: string
    language: string
    role: string
    ageGroup: string
    languagesSpoken: string[]
  }
  avatar: Avatar
  mqtt: {
    username: string
    password: string
  }
  // DB IDs of related Models
  groups: GroupData[]
}

export interface Avatar {
  fileKey: string
  name: string
  ref: string
}

export class User {
  _id: string
  status: {
    lastLogin: Date
    browserLanguage: string
    canEditPlayers: boolean // User can make their own Players
    canEditGames: boolean
  }
  profile: {
    username: string
    fullName: string
    password: string
    email: string
    mobil: string
    language: LanguageNames // Use a two letter code as the browser does
    role: USER_ROLE
    ageGroup: string
    languagesSpoken: string[]
  }
  avatar: Avatar
  mqtt: {
    username: string
    password: string
  }
  groups: Group[] // Populated during User request

  constructor(data?: UserData | User) {
    this._id = ''
    this.status = {
      lastLogin: new Date(),
      browserLanguage: 'no',
      canEditPlayers: false,
      canEditGames: false
    }
    this.profile = {
      username: 'initial user',
      fullName: 'initial user',
      password: '',
      email: '',
      mobil: '',
      language: LanguageNames.system, // Use a two letter code as the browser does
      role: USER_ROLE.user,
      ageGroup: '',
      languagesSpoken: []
    }
    this.avatar = {
      fileKey: '',
      name: '',
      ref: ''
    }
    this.mqtt = {
      username: '',
      password: ''
    }
    this.groups = []

    if (data) this.update(data)
  }

  public update(data: UserData | User): void {
    this._id = data._id
    this.profile = {
      username: data.profile.username,
      fullName: data.profile.fullName,
      password: '',
      mobil: data.profile.mobil,
      email: data.profile.email,
      language: (data.profile.language as LanguageNames) || LanguageNames.system,
      role: data.profile.role as USER_ROLE,
      ageGroup: data.profile.ageGroup,
      languagesSpoken: data.profile.languagesSpoken || []
    }
    this.status = {
      lastLogin: new Date(data.status.lastLogin),
      browserLanguage: data.status.browserLanguage,
      canEditPlayers: data.status.canEditPlayers,
      canEditGames: data.status.canEditGames
    }
    this.avatar = {
      name: data.avatar.name,
      fileKey: data.avatar.fileKey,
      ref: data.avatar.ref
    }
    this.mqtt = data.mqtt
    data.groups.forEach((group: Group | GroupData) => {
      const g = this.groups.find((gr) => gr._id === group._id)
      if (g) g.update(group)
      else this.groups.push(new Group(group))
    })
  }

  // Convert this to a Plain Old Javascript Object
  asPOJO() {
    const groups = this.groups.map((g) => g.asPOJO())
    return { ...this, groups }
  }
}

// ---------------  API -----------------

enum XHR_REQUEST_TYPE {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE'
}

enum XHR_CONTENT_TYPE {
  JSON = 'application/json',
  MULTIPART = 'multipart/form-data',
  URLENCODED = 'application/x-www-form-urlencoded'
}

// Augment the Error class with message and status
class HttpException extends Error {
  status: number
  message: string
  name: string
  constructor(status: number, name: string, message: string) {
    super(message)
    this.status = status
    this.name = name
    this.message = message
  }
}

interface APIRequestPayload {
  method: XHR_REQUEST_TYPE
  route: string
  credentials?: boolean
  body?: unknown | string | User | Game | GameData | Tracking | FormData
  headers?: Record<string, string>
  query?: Record<string, string>
  contentType?: string
  baseURL?: string
}

interface XHRPayload {
  url: string
  headers: Record<string, string>
  credentials: boolean
  body: string | FormData
  method: XHR_REQUEST_TYPE
}

export interface SubscriptionData {
  game_id?: string
  user: {
    id: string
    username: string
  }
  status?: {
    connections?: {
      authorised: string[]
      totalUsersInGame: number
    }
  }
}

export interface IParcel {
  parcelType: ParcelType
  _id?: string
  subscription?: SubscriptionData
  body?:
    | Game
    | GameData
    | Progress
    | TaskSync
    | TaskSyncST
    | TaskShuffleSync
    | ParticipantInformation
    | FinalDecisionStatusData
    | Blob
    | ArrayBuffer
    | string
}

export class Parcel implements IParcel {
  _id = ''
  parcelType = ParcelType.Initial
  subscription = {} as SubscriptionData
  body?:
    | Game
    | GameData
    | Progress
    | TaskSync
    | TaskSyncST
    | TaskShuffleSync
    | ParticipantInformation
    | FinalDecisionStatusData
    | Blob
    | ArrayBuffer
    | string = '' // wall, task or message object or string (id)

  constructor(spec: IParcel, user?: User, game?: Game) {
    this._id = spec._id || nanoid()
    this.parcelType = spec.parcelType // ParcelType enumerator
    if (spec.subscription?.user) this.subscription.user = spec.subscription.user
    else if (user && game) {
      this.subscription.user = {
        id: user._id,
        username: user.profile.username
      }
    }
    if (!user && !spec.subscription?.user) console.warn(`No user ID in Parcel: ${spec.parcelType}`)
    if (spec.subscription?.game_id) this.subscription.game_id = spec.subscription.game_id
    else if (game) this.subscription.game_id = game._id

    switch (spec.parcelType) {
      case ParcelType.TaskSync:
        this.body = spec.body as TaskSync
        break
      default:
        this.body = spec.body as string
    }
  }

  serialised() {
    return JSON.stringify(this)
  }

  toString() {
    const bodyString = JSON.stringify(this.body, null, 4)
    return (
      `PARCEL: ${this.parcelType} | User: ${this.subscription.user.username}` +
      ` | Body: ${bodyString}`
    )
  }
}

export type { APIRequestPayload, XHRPayload }
export { XHR_REQUEST_TYPE, HttpException, XHR_CONTENT_TYPE }
