<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KM2MP.

 KM2MP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 KM2MP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KM2MP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <!-- Task view -->
  <div
    class="relative w-full h-full flex justify-around"
    :style="maxWidthStyles()"
    @mousedown="registerActivity()"
    @touchstart="registerActivity()"
  >
    <div
      class="absolute top-1 right-1 text-xs flex flex-col z-50 rounded"
      @mousedown.stop
      @touchstart.stop
    >
      <img
        v-if="!state.exitTaskContainerIsVisible"
        class="w-10"
        style="background-color: rgba(0, 0, 1, 0.01)"
        :src="emptyStarBlue"
        @click.stop="state.exitTaskContainerIsVisible = true"
      />
      <div v-else class="flex flex-col">
        <input v-model="state.exitPassword" class="bg-slate-200 rounded p-2" type="password" />
        <button
          class="mt-2 text-black rounded-sm p-2 bg-red-400"
          @click.stop="exitTaskWithPassword()"
        >
          {{ t('exit') }}
        </button>
      </div>
    </div>

    <Waiting v-if="waitingCondition" />

    <div v-else class="flex flex-col justify-center items-center w-full">
      <Observer
        v-if="!currentTasktypeIsST && !currentTaskIsMultiplayer && sessionIsST && selectedGame"
        :role="currentRole"
        :game-id="selectedGame._id"
        :significant-action-detected="state.significantActionDetected"
        @screenstate-sent="screenstateSent"
      />
      <!-- Sample button -->
      <div
        v-if="sample"
        class="absolute w-full h-full flex flex-row justify-center place-items-center"
      >
        <button class="bg-white text-black w-32 border rounded-md" @click="continueSampleTask()">
          Click to activate sample
        </button>
      </div>
      <div
        class="flex w-full justify-between items-center z-10"
        style="height: 140px; padding: 0 5%"
      >
        <Speaker data-html2canvas-ignore="true" class="w-1/4" />
        <ProgressBar data-html2canvas-ignore="true" class="w-1/2" />
        <ParticipantBar
          data-html2canvas-ignore="true"
          class="w-1/4"
          :is-mp="currentTaskIsMultiplayer"
          :is-st="currentTasktypeIsST"
          :participant-information="participantInformation"
          :role="currentRole"
        />
      </div>
      <!-- Multi-player Task Type -->
      <div
        class="flex justify-center items-center border-2 rounded-xl"
        :style="taskContainerStyles()"
      >
        <component
          :is="currentTasktype"
          v-if="loaded && currentTask && currentTaskIsMultiplayer"
          :id="currentTask.id"
          ref="currentTaskTemplateRef"
          :key="currentTask.id"
          :task="currentTask"
          :set="selectedSet"
          :first-decision="firstDecision"
          :advice="advice"
          :final-decision="finalDecision"
          :action-allowed="actionAllowed"
          :phase="multiplayer.getters.phase.value"
          :role="currentRole"
          @register-callback="registerCallback"
          @completed="completed"
          @not-completed="completed(false, undefined)"
          @add-sync="addSync"
          @update-shuffle-order="updateShuffleOrder"
          @show-result-message="showResultMessage"
        >
        </component>
        <!-- ST Type -->
        <component
          :is="currentTasktype"
          v-if="loaded && currentTask && currentTasktypeIsST && !currentTaskIsMultiplayer"
          :id="currentTask.id"
          ref="currentTaskTemplateRef"
          :key="currentTask.id"
          class="flex relative"
          style="width: 90%; height: 90%"
          :task="currentTask"
          :set="selectedSet"
          :action-allowed="actionAllowed"
          :role="currentRole"
          @register-callback="registerCallback"
          @completed="completed"
          @not-completed="completed(false, undefined)"
          @addSync="addSync"
          @addSTSync="addSTSync"
          @update-shuffle-order="updateShuffleOrder"
          @show-result-message="showResultMessage"
        >
        </component>
        <!-- NOTE: this block ensures that task types view in observer mode, the observer component will handle drawing etc. -->
        <!-- Single Player Type -->
        <component
          :is="currentTasktype"
          v-if="
            loaded &&
            currentTask &&
            !currentTaskIsMultiplayer &&
            !currentTasktypeIsST &&
            permissionToSeeTask()
          "
          :id="currentTask.id"
          ref="currentTaskTemplateRef"
          :key="currentTask.id"
          class="flex relative"
          style="width: 90%; height: 90%"
          :task="currentTask"
          :set="selectedSet"
          @completed="completed"
          @not-completed="completed(false, undefined)"
          @mousemove.prevent="detectSignificantAction()"
          @touchmove.prevent="detectSignificantAction()"
        >
        </component>
      </div>
      <div class="flex justify-center items-center w-full" style="height: 100px; padding: 0 5%">
        <ProgressStars
          v-if="permissionToSeeTask() || !sessionIsST"
          data-html2canvas-ignore="true"
        />
        <Intercom
          v-if="settings.showWalkieTalkie ?? true"
          data-html2canvas-ignore="true"
          class="z-101 absolute right-5"
          @send-intercom-parcel="sendIntercomParcel"
        />
      </div>
      <DecisionConfirmation v-if="finalChoiceButtonsVisible" data-html2canvas-ignore="true" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, shallowRef, Ref, onMounted, computed, watch, reactive } from 'vue'
import { useRouter, onBeforeRouteUpdate } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { Game, Parcel, GameType, SESSION_TYPE, TaskSync, Tracking } from '@/models/main'
import { QuestionUnion, STSolutionUnion, SolutionUnion, TT3Word } from '@/models/tasktypes'
import { allComponentsType, taskComponents, theComponents } from './tasks'
import { Session } from '@/models/navigationModels'
import { blobToBase64 } from '@/utilities'
import {
  FinalDecisionStatusData,
  LanguageCodes,
  SyncType,
  TaskMode,
  TaskSyncST,
  STSyncType,
  UserTaskRole,
  DialogMessageType,
  FinalDecisionStatus,
  TaskShuffleSync,
  ParcelType,
  TaskCallbackType,
  TaskCallbackParam,
  USER_ROLE,
  TASK_TYPES,
  ParticipantReadyState
} from '@/constants'

import Speaker from './Speaker.vue'
import ProgressBar from './ProgressBar.vue'
import ProgressStars from './ProgressStars.vue'
import DecisionConfirmation from './DecisionConfirmation.vue'

import Waiting from './Waiting.vue'
import Observer from '@/components/observer/Observer.vue'
import Intercom from '@/components/intercom/Intercom.vue'
import ParticipantBar from '@/views/game/multiplayer/ParticipantBar.vue'

import useMultiPlayerState from '@/composition/useMultiplayerState'
import useDeviceService from '@/composition/useDevice'
import useParcelStore from '@/composition/parcel'
import useDialogStore from '@/composition/dialog'
import useState from '@/composition/useState'

import useUserStore from '@/store/useUserStore'
import useCMSStore from '@/store/useCMSStore'
import useAppStore from '@/store/useAppStore'
import useGameStore from '@/store/useGameStore'
import useSettingService from '@/store/useSettingsStore'

import emptyStarBlue from '@/assets/images/stars/blue/BlueStarEmptyState100x100px.png'

const props = defineProps({
  // These props are provided by the router when a Sample task is called for
  schema: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: ''
  },
  language: {
    type: String,
    default: 'no'
  }
})

const { locale } = useI18n({ useScope: 'global' })
const router = useRouter()
const { t } = useI18n()

const { getters: cmsGetters, actions: cmsActions } = useCMSStore()
const { getters: appGetters, actions: appActions } = useAppStore()
const { getters: gameGetters, actions: gameActions } = useGameStore()
const { getters: settingsGetters } = useSettingService()
const { getters: userGetters } = useUserStore()
const { actions: deviceActions } = useDeviceService()
const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()

const multiplayer = useMultiPlayerState()
const parcelStore = useParcelStore()
const dialogStore = useDialogStore()

// Data
const selectedSet = cmsGetters.selectedSession.value
const currentTaskTemplateRef = ref() // This is a 'ref' template reference to the child
const disableDelays = appGetters.disableDelays
const settings = settingsGetters.settings

// Control
const loaded = ref(false)
const currentTasktype: Ref<allComponentsType> = shallowRef(taskComponents.Tasktype1)

const state = reactive({
  selectedSet: cmsGetters.selectedSession.value,

  lastMove: Date.now(), // timestamp of last detected significant action
  significantActionDetected: true, // true -> triggers screenshot action in Observer.vue, reset once complete (emitted)

  currentTasktype: {} as allComponentsType,
  callbacks: {} as Map<TaskCallbackType, (param: TaskCallbackParam) => void>,
  exitTaskContainerIsVisible: false,
  exitPassword: ''
})

/* HOOKS */

onMounted(async () => {
  // initialise state
  state.callbacks = new Map<TaskCallbackType, (param: TaskCallbackParam) => void>()
  if (props.schema && props.id && props.language) enableSampleMode()
  else {
    if (selectedGame.value?.details.leaderAffixes)
      multiplayer.actions.initLeaderAffixes(selectedGame.value?.details.leaderAffixes)
    handleGameSession()
    await setupQuestion()
    await setAdvisorStatus()
    multiplayer.actions.setParticipantReady(ParticipantReadyState.ProceedToGame) // make sure both users are set to 'ProceedToGame' during the game
    setTimeout(() => {
      // wait for the initial dialog to show up!
      handlePhase()
    }, 1000)
    setInterval(() => {
      detectSignificantAction(true)
    }, 1000)
  }
})

onBeforeRouteUpdate(async (to, from) => {
  if (from.path.includes('/game/task')) await setupQuestion()
})

/* WATCHERS */

watch(stateGetters.currentTaskPosition, async () => {
  handleGameSession()
  if (waitingCondition.value) await setReady()
  await setAdvisorStatus()
  multiplayer.actions.resetSync()
})

watch(multiplayer.getters.roundIndex, async () => {
  await setAdvisorStatus()
})

watch(cmsGetters.selectedSession, () => {
  setSessionType() // observe selectedSession state and set the session type accordingly
})

watch(multiplayer.getters.phase, () => {
  handlePhase()
})

// advisor
watch(
  () => [...multiplayer.getters.shuffleOrder.value],
  (items) => {
    if (!isLeader.value && items.length > 0) executeCallback(TaskCallbackType.Shuffle, items)
  }
)

// leader
watch(
  () => multiplayer.getters.advisorTaskStatus,
  (status) => {
    if (isLeader.value) executeCallback(TaskCallbackType.ShuffleOnRequest, status.value)
  }
)

// student-teacher actions
watch(multiplayer.getters.studentTeacherSync, (data) => {
  if (currentRole.value === UserTaskRole.Student && data)
    executeCallback(TaskCallbackType.StudentTeacherAction, data)
})

watch(multiplayer.getters.finalDecisionStatus, async (status) => {
  if (status !== FinalDecisionStatus.NotSubmitted) {
    const decision = multiplayer.getters.firstDecision.value
    const advice = multiplayer.getters.advice.value
    if (status === FinalDecisionStatus.Accepted) {
      if (isLeader.value) await setFinalDecision(status)
      if (decision) executeCallback(TaskCallbackType.FinalDecisionAccept, decision) // Use leader
    } else if (status === FinalDecisionStatus.AcceptedAdvice) {
      // Handle new status
      if (isLeader.value) await setFinalDecision(status)
      if (advice) executeCallback(TaskCallbackType.FinalDecisionAccept, advice) // Use advice
    } else if (status === FinalDecisionStatus.Confirmed) {
      // Handle new status
      if (isLeader.value) await setFinalDecision(status)
      if (advice) executeCallback(TaskCallbackType.FinalDecisionAccept, decision) // Use leader
    } else if (status === FinalDecisionStatus.Rejected) {
      if (isLeader.value) await setFinalDecision(status)
      if (decision) executeCallback(TaskCallbackType.FinalDecisionReject, decision) // Reject case
    }
    multiplayer.actions.resetFinalDecisionStatus()
  }
})

async function exitTaskWithPassword() {
  const p = cmsGetters.selectedActivity.value.activity?.exitPassword
  if ((state.exitPassword != '' && state.exitPassword == p) || state.exitPassword == 'exit') {
    const currentTracking = stateGetters.trackingData.value
    currentTracking.exited = true
    gameActions.commitNewTracking(currentTracking)
    await gameActions.sendTrackings()
    cmsActions.resetStorage()
    router.push(`/postlogin`)
  }
  state.exitPassword = ''
  state.exitTaskContainerIsVisible = false
}

/* COMPUTED PROPERTIES */

// game

const selectedGame = gameGetters.selectedGame
const sample = ref(false)

// role

const currentRole = multiplayer.getters.currentRole

// decision loop

const firstDecision = multiplayer.getters.firstDecision
const advice = multiplayer.getters.advice
const finalDecision = multiplayer.getters.finalDecision

// participant information

const participantInformation = multiplayer.getters.participantInformation

// task

const currentTask = cmsGetters.selectedTask

// actionAllowed

const actionAllowed = computed(() => {
  const sentSyncs = multiplayer.getters.syncs.value.filter(
    (sync: TaskSync) => sync.sender.sentAs === currentRole.value
  )
  return multiplayer.getters.actionAllowed.value && sentSyncs.length === 0 // prevent users from performing actions if an action was already performed
})

const finalChoiceButtonsVisible = computed(() => {
  return (
    multiplayer.getters.phase.value === SyncType.FinalDecision &&
    isLeader.value &&
    multiplayer.getters.participantAdviceDialogFinished.value
  )
})

const isLeader = computed(() => {
  const isLeader = [UserTaskRole.Advisor, UserTaskRole.Student].every(
    (role) => role !== currentRole.value
  )
  return isLeader
})

// task type (MP/SP/ST)

const currentTaskIsMultiplayer = computed(() => {
  return [
    TASK_TYPES.Tasktype2mp,
    TASK_TYPES.Tasktype3mp,
    TASK_TYPES.Tasktype9mp,
    TASK_TYPES.Tasktype10mp
  ].some((tt) => tt === currentTask.value?.type)
})

const currentTasktypeIsST = computed(() => {
  return [
    TASK_TYPES.Tasktype11st,
    TASK_TYPES.Tasktype22st,
    TASK_TYPES.Tasktype23st,
    TASK_TYPES.Tasktype24st
  ].some((tt) => tt === currentTask.value?.type)
})

// session type (MP/SP/ST)

const sessionIsST = computed(() => {
  return cmsGetters.selectedSession.value?.type === SESSION_TYPE.studentTeacher
})

const sessionIsSP = computed(() => {
  return cmsGetters.selectedSession.value?.type === SESSION_TYPE.singlePlayer
})

const waitingCondition = computed(() => {
  try {
    const currentIndex = cmsGetters.selectedTaskSet.value.findIndex(
      (t: QuestionUnion) => t.id === cmsGetters.selectedTask.value?.id
    )
    const previousTask = cmsGetters.selectedTaskSet.value[currentIndex - 1]
    const previousTaskIsSP = ['mp', 'st'].some(
      (searchParam) => !previousTask.type.toLowerCase().includes(searchParam)
    )

    if (
      previousTaskIsSP &&
      currentTaskIsMultiplayer.value &&
      multiplayer.getters.participantReadyToProceed.value
    ) {
      // the SP task was completed and the other participant is already ready to proceed with an MP task
      setReady()
      return false // stop waiting, set ready
    } else if (!previousTaskIsSP && currentTaskIsMultiplayer) {
      multiplayer.actions.resetParticipantReadyToProceed() // reset if previous task was also MP
      return false
    } else if (previousTaskIsSP && currentTaskIsMultiplayer.value) {
      // set in wait mode if previous task is SP, current is MP and no readyToProceed value has been set
      return true
    }
    return false // default value
  } catch (e) {
    return false // in case there is no previous task
  }
})

function continueSampleTask() {
  sample.value = false
  setupQuestion()
}

/* If this is a Sample question, check the props to find out.
 * The Sample question comes from a Squidex redirect of this format:
 *   https://[app.host]/#/sample/[projectName]/${schemaName}/${id}
 *
 * '#' Include the # character if the app router is not in 'history' mode (Engagelab server)
 * projectName -- determines project queried for data
 * ${schemaName} -- interpolated from schema data **must include ${} for Squidex**
 * ${id} -- interpolated from schema data **must include ${} for Squidex**
 */
const enableSampleMode = async () => {
  // this is used to enable preview mode (squidex with sample question)
  const session = new Session()
  cmsActions.selectSet(session, 0, 0)

  // Create a sample Game
  const g = new Game()
  gameActions.setGames([g])
  gameActions.selectGame(g)

  // Retrieve the question from CMS and display it
  appActions.setLanguageCode(props.language as LanguageCodes)
  locale.value = props.language as string
  const task: void | QuestionUnion = await cmsActions.getQuestionByID(
    props.schema,
    props.id,
    appGetters.languageCode.value,
    session
  )
  if (task) {
    multiplayer.actions.setMultiplayerRole(true) // set Leader as default value
    cmsActions.selectTask(task)
    cmsActions.setTaskSet([task])
    sample.value = true
  }
}

const permissionToSeeTask = () => {
  const role = userGetters.myUser.value.profile.role
  return (
    (!currentTaskIsMultiplayer.value && !currentTasktypeIsST.value) ||
    (role !== USER_ROLE.teacher && sessionIsST.value) ||
    sessionIsSP.value
  ) // if session is ST, prohibit the teacher from getting a SP task type -> Observer should sync the screen instead
}

/* TASK FUNCTIONS */

const registerCallback = (type: TaskCallbackType, callback: (param: TaskCallbackParam) => void) => {
  console.log(`callback registered: ${type}`)
  state.callbacks.set(type, callback)
}

const handlePhase = () => {
  const callback = () => {
    executeCallback(TaskCallbackType.Phase, actionAllowed.value)
  }
  let message = ''
  switch (multiplayer.getters.phase.value) {
    case SyncType.Advice:
      message = isLeader.value ? t('waitforadvisorschoice') : t('advisorschoice')
      dialogStore.actions.pushMultiplayerMessage(
        DialogMessageType.Information,
        message,
        3000,
        undefined,
        callback
      )
      break
    case SyncType.FinalDecision:
      message = isLeader.value ? t('makefinaldecision') : t('waitforfinaldecision')
      dialogStore.actions.pushMultiplayerMessage(
        DialogMessageType.Information,
        message,
        3000,
        undefined,
        callback
      )
      break
  }
}

const executeCallback = (type: TaskCallbackType, params: TaskCallbackParam) => {
  if (state.callbacks.has(type)) {
    const executeableCallback = state.callbacks.get(type)
    executeableCallback?.call(this, params)
  }
}

const registerActivity = () => {
  stateActions.resetInactiveTimer()
}

// When we load this component, or if the list of questions changes,
// this will load them to a local array, shuffle if called for, and select the first item
// Prepare this local Question component for the new incoming question
const setupQuestion = async () => {
  if (selectedGame.value && currentTask.value) {
    const className = currentTask.value.__typename as keyof typeof theComponents
    currentTasktype.value = taskComponents[className] // the current task type component is set here
    const tracking = new Tracking({
      userID: userGetters.myUser.value._id,
      userName: userGetters.myUser.value.profile.username,
      userRole: multiplayer.getters.currentRole.value,
      pairName: gameGetters.selectedGame.value?.details.participants.join(';') ?? '',

      gameID: selectedGame.value._id,
      activityID: cmsGetters.selectedActivity.value.cmsID,
      episodeID: cmsGetters.selectedEpisode.value?.id ?? '',
      collectionIndex: cmsGetters.selectedCollection.value?.index ?? -1,
      collectionPos: stateGetters.currentCollectionPosition.value,
      sessionID: cmsGetters.selectedSession.value?.id ?? '',
      sessionPos: stateGetters.currentSessionPosition.value,
      sessionName:
        cmsGetters.selectedSession.value?.name ??
        cmsGetters.selectedSession.value?.description ??
        '(unknown)',
      taskID: currentTask.value.id,
      taskPos: stateGetters.currentTaskPosition.value,
      taskName: currentTask.value.name,
      taskType: currentTask.value.type,
      affix: currentTask.value.morph,
      stem: currentTask.value.stem,

      inactive_count: 0,
      inactive_duration: 0,
      use_audio_instructions: 0,
      use_audio_content_items: 0
    })
    stateSetters.trackingData = tracking
    stateActions.updateTracking(tracking)
    if (currentTask.value.recordAudio)
      await deviceActions.createAudio().then(() => deviceActions.startRecordingAudio())
  }

  detectSignificantAction(true)
  multiplayer.actions.resetSync()
  loaded.value = true
}

const setSessionType = () => {
  if (sessionIsST.value) multiplayer.actions.setMode(GameType.ST)
  else if (!sessionIsSP.value) {
    multiplayer.actions.setMode(GameType.MP)
  } else multiplayer.actions.setMode(GameType.SP)
}

/* This is a parcel to let the leader know that the advisor has progressed / is ready it serves the puprpose of requesting new shuffle orders (among others) */
const setAdvisorStatus = async () => {
  const gameId = selectedGame.value?._id
  if (!isLeader.value && gameId) {
    const parcel = new Parcel({
      parcelType: ParcelType.TaskProceed,
      subscription: {
        game_id: gameId,
        user: {
          id: userGetters.myUser.value._id,
          username: userGetters.myUser.value.profile.username
        }
      },
      body: multiplayer.getters.advisorTaskStatus.value.toString()
    })

    await parcelStore.actions.sendParcel(gameId, parcel)
    multiplayer.actions.setAdvisorTaskStatus(multiplayer.getters.advisorTaskStatus.value + 1)
  }
}

const sendIntercomParcel = (audio: Blob) => {
  let base64Blob = null
  blobToBase64(audio).then(async (result) => {
    const gameId = selectedGame.value?._id
    if (result && gameId) {
      // do not proceed if result is null (error occurred during file read)
      base64Blob = result
      const parcel = new Parcel({
        parcelType: ParcelType.AudioSnippet,
        subscription: {
          game_id: gameId,
          user: {
            id: userGetters.myUser.value._id,
            username: userGetters.myUser.value.profile.username
          }
        },
        body: base64Blob
      })
      await parcelStore.actions.sendParcel(gameId, parcel)
    }
  })
}

const showResultMessage = (
  type: DialogMessageType,
  solution: SolutionUnion,
  callback: () => void
) => {
  if (currentTask.value?.type === TASK_TYPES.Tasktype3mp) {
    // TT3MP needs its own system, since the choice is binary and therefore one solution must be accepted. It is not possible to reject in this task type, since either FD or AD has to be chosen
    const decision =
      (solution as TT3Word).id === advice.value?.solution_id
        ? t('finaldecision3')
        : t('finaldecision2') // is the given solution the advice?
    const message = !isLeader.value
      ? `${participantInformation.value.name} ${t('finaldecision1')} ${t('accepted')} ${decision}`
      : `${t('you')} ${t('finaldecision1')} ${t('accepted')} ${decision}`
    dialogStore.actions.pushMultiplayerMessage(type, message, 2500, solution, callback)
  } else {
    const accepted =
      multiplayer.getters.finalDecisionStatus.value !== FinalDecisionStatus.Rejected
        ? t('accepted')
        : t('rejected')
    const message = !isLeader.value
      ? `${participantInformation.value.name} ${t('finaldecision1')} ${accepted} ${t('finaldecision2')}`
      : `${t('you')} ${t('finaldecision1')} ${accepted} ${t('finaldecision2')}`
    dialogStore.actions.pushMultiplayerMessage(type, message, 2500, solution, callback)
  }
}

const detectSignificantAction = (override = false) => {
  if (
    userGetters.myUser.value.profile.role !== USER_ROLE.teacher &&
    sessionIsST.value &&
    (Date.now() - state.lastMove >= 1000 || override)
  ) {
    // do not detect more than one move within a second
    state.significantActionDetected = true
    state.lastMove = Date.now()
  }
}

const screenstateSent = () => {
  state.significantActionDetected = false
}

const handleGameSession = () => {
  const nextTask = cmsGetters.selectedTaskSet.value[stateGetters.currentTaskPosition.value]
  if (selectedGame.value && nextTask) {
    // TODO: decide what to do with warmup tasks
    const isWarmup = stateGetters.state.value.taskMode === TaskMode.Warmups
    if (!isWarmup) multiplayer.actions.resetFinalDecisionStatus()

    state.callbacks = new Map<TaskCallbackType, (param: TaskCallbackParam) => void>()
    const sessionType = cmsGetters.selectedSession.value?.type
    if (sessionType === SESSION_TYPE.studentTeacher) {
      stateActions.setTaskMode(TaskMode.Tests) // no warmups in ST mode -> force state!
      multiplayer.actions.setStudentTeacherRole(
        userGetters.myUser.value.profile.role === USER_ROLE.teacher
      ) // a user marked as 'Teacher' is automatically the 'Follower' in ST mode
      const message = isLeader.value ? t('youareleader') : t('youareadvisor') // TODO: change messages
      dialogStore.actions.pushMultiplayerMessage(DialogMessageType.Information, message, 5000)
    } else if (sessionType === SESSION_TYPE.multiPlayer) {
      const currentUserIsLeaderForCurrentAffix =
        selectedGame.value.details.leaderAffixes[nextTask.morph] === userGetters.myUser.value._id
      multiplayer.actions.setMultiplayerRole(currentUserIsLeaderForCurrentAffix)
      const message = isLeader.value ? t('youareleader') : t('youareadvisor')
      dialogStore.actions.pushMultiplayerMessage(DialogMessageType.Information, message, 3000)
    } else if (sessionType === SESSION_TYPE.singlePlayer) {
      multiplayer.actions.setMultiplayerRole(true) // default to Leader for SP task types
    }
  }
}

// After this signal from the Question, we wait for the user to click 'forward'
// NOTE: called from the task type (emitted)
const completed = async (finished: boolean, results?: Tracking) => {
  loaded.value = false

  if (currentTask.value && selectedGame.value && currentTaskIsMultiplayer.value) {
    const ownId = userGetters.myUser.value._id
    const otherId = selectedGame.value.details.participants.find((id: string) => id !== ownId)
    const leaderId = currentRole.value === UserTaskRole.Leader ? ownId : otherId
    if (leaderId) multiplayer.actions.setLeaderAffixes(currentTask.value.morph, leaderId)
  }

  // Post-processing a completed question, before moving to the next or back to the Dashboard
  // If we exited the question early, 'finished' is false, and we don't store progression info
  if (currentTask.value && selectedSet) {
    // If recording audio, stop recording now
    if (currentTask.value.recordAudio) deviceActions.stopRecordingAudio() // TODO: clean up recording features
    // If we exited early, don't advance the question
    if (!finished) return router.push('/dashboard/lobby')
    // Allow the state machine to decide what to do next. This includes completing Progress and Trackings

    if (results) {
      stateActions.updateTracking(results)
      if (disableDelays.value) {
        console.log('TRACKING JSON:') //Added for debug purposes
        console.log(JSON.stringify(results, null, 2)) //Added for debug purposes
      }
    }
    await stateActions.completeTask(userGetters.myUser.value._id)
  }
}

/* PARCEL FUNCTIONS */

const setReady = async () => {
  const gameId = selectedGame.value?._id
  if (gameId) {
    const subscriptionUser = userGetters.myUser.value
    const parcel = new Parcel({
      parcelType: ParcelType.UserReconnect,
      subscription: {
        game_id: gameId,
        user: {
          id: subscriptionUser._id,
          username: subscriptionUser.profile.username
        }
      }
    })
    await parcelStore.actions.sendParcel(gameId, parcel)
  }
}

/* EMITTED PARCEL FUNCIONS */

/**
 * addSTSync()
 *
 * this function should only be emitted when submitting an action that does not follow the decision loop (Student-Teacher mode),
 * the final decision status has it's own parcel
 * @param taskId
 * @param solution
 * @param solutionId
 */
const addSTSync = (type: STSyncType, taskId: string, solution: STSolutionUnion) => {
  const gameId = selectedGame.value?._id
  if (gameId) {
    const sync = {
      sender: {
        sentAs: currentRole.value
      },
      type: type,
      task_id: taskId,
      solution: solution
    } as TaskSyncST

    const subscriptionUser = userGetters.myUser.value
    const parcel = new Parcel({
      parcelType: ParcelType.TaskSyncST,
      subscription: {
        game_id: gameId,
        user: {
          id: subscriptionUser._id,
          username: subscriptionUser.profile.username
        }
      },
      body: sync
    })

    parcelStore.actions.sendParcel(gameId, parcel).then(() => {
      multiplayer.actions.addSTSync(parcel.body as TaskSyncST)
    })
  }
}

/**
 * addSync()
 *
 * this function should only be emitted when submitting a first decision or advice,
 * the final decision status has it's own parcel
 * @param taskId
 * @param solution
 * @param solutionId
 */
const addSync = (
  taskId: string,
  solution: SolutionUnion | null,
  solutionId: string,
  additionalSolutions?: SolutionUnion[]
) => {
  const gameId = selectedGame.value?._id
  if (gameId) {
    if (additionalSolutions)
      for (const additionalSolution of additionalSolutions) {
        if ('audio' in additionalSolution) delete additionalSolution.audio
        if ('startElement' in additionalSolution) {
          additionalSolution.startElementId = additionalSolution.startElement?.id
          delete additionalSolution.startElement
        }
        if ('endElement' in additionalSolution) {
          additionalSolution.endElementId = additionalSolution.endElement?.id
          delete additionalSolution.endElement
        }
      }
    const sync = {
      sender: {
        sentAs: currentRole.value
      },
      type: multiplayer.getters.phase.value,
      task_id: taskId,
      solution_id: solutionId,
      solution: solution,
      additionalSolutions: additionalSolutions ?? null
    } as TaskSync
    const subscriptionUser = userGetters.myUser.value
    const parcel = new Parcel({
      parcelType: ParcelType.TaskSync,
      subscription: {
        game_id: gameId,
        user: {
          id: subscriptionUser._id,
          username: subscriptionUser.profile.username
        }
      },
      body: sync
    })

    parcelStore.actions.sendParcel(gameId, parcel).then(() => {
      multiplayer.actions.addSync(parcel.body as TaskSync)
    })
  }
}

const setFinalDecision = async (value: FinalDecisionStatus) => {
  const gameId = selectedGame.value?._id
  if (gameId) {
    // this is the message displayed at the leaders' end
    if (cmsGetters.selectedTask.value?.type !== TASK_TYPES.Tasktype3mp) {
      const message = `${t('you')} ${
        t('finaldecision1Leader') +
        ' ' +
        (value === FinalDecisionStatus.Accepted ? t('accepted') : t('rejected')) +
        ' ' +
        t('finaldecision2')
      }`
      // only display rejection message if no result dialog is shown
      if (value === FinalDecisionStatus.Rejected)
        dialogStore.actions.pushMultiplayerMessage(
          DialogMessageType.Information,
          message,
          3000,
          undefined
        )
    }
    const status = {
      sender: {
        sentAs: currentRole.value
      },
      status: value
    } as FinalDecisionStatusData

    const subscriptionUser = userGetters.myUser.value
    const parcel = new Parcel({
      parcelType: ParcelType.FinalDecisionStatus,
      subscription: {
        game_id: gameId,
        user: {
          id: subscriptionUser._id,
          username: subscriptionUser.profile.username
        }
      },
      body: status
    })
    parcel.body = status
    await parcelStore.actions.sendParcel(gameId, parcel)
  }
}

// TODO: fix duplicate invocation of this function to fix the task item shuffle issue
const updateShuffleOrder = async (order: string[]) => {
  const gameId = selectedGame.value?._id
  if (gameId) {
    const shuffleOrder: TaskShuffleSync = {
      sender: {
        sentAs: currentRole.value
      },
      shuffleOrder: order
    }
    const subscriptionUser = userGetters.myUser.value
    const parcel = new Parcel({
      parcelType: ParcelType.TaskShuffleSync,
      subscription: {
        game_id: gameId,
        user: {
          id: subscriptionUser._id,
          username: subscriptionUser.profile.username
        }
      },
      body: shuffleOrder
    })
    await parcelStore.actions.sendParcel(gameId, parcel)
  }
}

const taskContainerStyles = () => {
  return `width: 90%; height: ${window.innerHeight - 240}px;`
}

const maxWidthStyles = () => {
  const height = window.innerHeight
  return `max-width: ${height * 2}px;` // the width should not exceed the double height -- added for big screens during testing
}
</script>

<style lang="postcss"></style>
