<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of KMMP.

KMMP is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

KMMP is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with KMMP.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div
    class="relative flex flex-col justify-center items-center fadeInOut w-full h-full"
    @touchmove="mouseMoving"
    @mousemove="mouseMoving"
    @touchend.prevent.stop="checkActionEnd"
    @mouseup.prevent.stop="checkActionEnd"
  >
    <Button
      v-if="props.actionAllowed && props.phase !== SyncType.FinalDecision"
      class="text-3xl absolute rounded-3xl border-4 z-30 -bottom-20 right-16"
      textcolour="text-cyan-500"
      bordercolour="border-cyan-500"
      backgroundcolour="bg-black"
      @mouseup.prevent.stop="sendDecision()"
      @touchend.prevent.stop="sendDecision()"
    >
      {{ t('send') }}
    </Button>
    <div class="scanner-content flex justify-center items-end z-20">
      <div class="w-full h-full flex flex-col justify-center items-center">
        <div
          class="word-wrapper taskNoUserSelect fadeInOut w-full h-full"
          :style="{ opacity: opacity }"
        >
          <div
            v-for="(word, index) in state.words"
            :id="word.text"
            :key="index"
            class="borderedTaskWordBox absolute"
            :style="calculatedWordPosition[index]"
            @mousedown="mouseDownOnWord($event, state.words[index])"
            @touchstart="mouseDownOnWord($event, state.words[index])"
          >
            <span class="pointer-events-none">{{ state.words[index].text }}</span>
          </div>

          <!-- Combined word -->
          <transition mode="out-in" name="fade">
            <div v-if="combinedWord" class="absolute z-10" style="top: 35%; left: 40%">
              <span>
                {{ combinedWord }}
              </span>
            </div>
          </transition>
        </div>

        <div v-if="discoveredCombinations.length > 0" class="completedWords taskNoUserSelect">
          <ul>
            <li
              v-for="(word, i) in discoveredCombinations"
              :key="`word-d-${i}`"
              class="text-2xl m-0 outline-none"
            >
              {{ word }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Drawing
      ref="drawing"
      style="width: 100vw; height: 100vh"
      class="z-20 pointer-events-none fixed left-0 top-0"
    ></Drawing>
    <Drawing
      ref="drawingFromParticipant"
      style="width: 100vw; height: 100vh"
      class="z-20 pointer-events-none fixed -top-2 left-2"
    ></Drawing>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  PropType,
  toRefs,
  Ref,
  onMounted,
  reactive,
  nextTick,
  onUnmounted,
  computed,
  watch
} from 'vue'
import { useI18n } from 'vue-i18n'

import { Tasktype9mp, Type9Correct, Type9WordType } from '@/models/tasktypes/Tasktype9mp'
import { LinkedWord, STSolutionUnion, Word } from '@/models/tasktypes'

import Drawing from '@/components/task/Drawing.vue'
import Button from '@/components/base/Button.vue'

import {
  SpeechSounds,
  SyncType,
  TaskCallbackType,
  TaskMode,
  TaskSyncWord,
  UserTaskRole,
  WordIndexName,
  TaskCallback,
  TaskCallbackParam
} from '@/constants'
import { SolutionUnion } from '@/models/tasktypes'
import { DialogMessageType } from '@/constants'
import { Choice, TaskSync, Tracking } from '@/models/main'
import { shuffleItems } from '@/utilities'

import useState from '@/composition/useState'
import useColorStore from '@/composition/colors'
import useDialogStore from '@/composition/dialog'
import useMultiPlayerState from '@/composition/useMultiplayerState'
import useUserStore from '@/store/useUserStore'
import { createSound } from '@/api/audioService'
import moment from 'moment'

const emit = defineEmits<{
  (e: 'completed', value: boolean, tracking: Tracking): void
  (
    e: 'addSync',
    taskId: string,
    solution: SolutionUnion | null,
    solutionId: string,
    additionalSolutions?: SolutionUnion[]
  ): void
  (e: 'updateShuffleOrder', shuffleOrder: string[]): void
  (e: 'registerCallback', type: TaskCallbackType, callback: TaskCallback): void
  (
    e: 'showResultMessage',
    messageType: DialogMessageType,
    solution: string,
    callback: () => void
  ): void
}>()

const { t } = useI18n()

const props = defineProps({
  task: { required: true, type: Object as PropType<Tasktype9mp> },
  myIndex: { required: false, type: Number, default: 0 },
  phase: { required: true, type: String },
  actionAllowed: { required: true, type: Boolean },
  firstDecision: { required: false, type: Object as PropType<TaskSyncWord | null>, default: null },
  advice: { required: false, type: Object as PropType<TaskSyncWord | null>, default: null },
  finalDecision: { required: false, type: Object as PropType<TaskSyncWord | null>, default: null },
  role: { required: true, type: String }
})

const { getters: stateGetters, setters: stateSetters, actions: stateActions } = useState()
const { getters: userGetters } = useUserStore()
const { task } = toRefs(props)

const multiplayer = useMultiPlayerState()
const color = useColorStore()
const dialog = useDialogStore()

const tracking = new Tracking(stateGetters.tracking.value)
stateSetters.trackingData = tracking
let choiceTimer = new Date()
const roundData = { correct: 0, of: 0 }

const drawing = ref<InstanceType<typeof Drawing>>()
const drawingFromParticipant = ref<InstanceType<typeof Drawing>>()
const opacity = ref(0)
const currentPhase = multiplayer.getters.phase

let linkedUnconfirmedItem1: Word | undefined
let linkedUnconfirmedItem2: Word | undefined
let linkedUnconfirmedItem3: Word | undefined
let linkedUnconfirmedItem4: Word | undefined

const combinedWord = ref('')
const discoveredCombinations: Ref<string[]> = ref([])

let move = 1
let tappedItem: Word | undefined = undefined
let audioPlaying = false
let choiceAttempt = 1

interface State {
  initialWords: Word[]
  words: Word[]
  solutions: SolutionUnion[]
  linkedWord?: LinkedWord
  styles: string[]
}

const state: State = reactive({
  initialWords: [],
  words: [],
  solutions: [],
  linkedWord: undefined,
  styles: []
})

/* HOOKS */

onMounted(async () => {
  await setupTask()
})

onUnmounted(() => {
  window.removeEventListener('resize', setWindowDimensions)
})

const wrapperDimensions: Ref<DOMRect | undefined> = ref()

const setWindowDimensions = () => {
  wrapperDimensions.value = document
    .getElementsByClassName('word-wrapper')[0]
    .getBoundingClientRect()
}

// Reset the tracking timer when the phase changes, so we are not including the other player's time
watch(multiplayer.getters.phase, () => {
  choiceTimer = new Date()
})

/* MP STATE CALLBACKS */

const phaseCallback = () => {
  nextTick(() => {
    const solutions =
      props.role === UserTaskRole.Advisor
        ? props.firstDecision?.additionalSolutions
        : props.advice?.additionalSolutions
    if (solutions) {
      // we can't send the HTML elements, therefore they need to be retrieved from the DOM by the provided Ids
      solutions.map((solution: SolutionUnion | STSolutionUnion) => {
        const convertedSolution = solution as LinkedWord
        if (convertedSolution.startElementId !== '') {
          if (convertedSolution.startElementId) {
            const startElement = document.getElementById(convertedSolution.startElementId)
            if (startElement) convertedSolution.startElement = startElement
          }
          if (convertedSolution.endElementId) {
            const endElement = document.getElementById(convertedSolution.endElementId)
            if (endElement) convertedSolution.endElement = endElement
          }
          if (convertedSolution) drawLineForPhase(convertedSolution)
        }
      })
    }
  })
}

const finalDecisionAcceptedCallback = (args: TaskCallbackParam) => {
  const decision = args as TaskSync

  if (decision.additionalSolutions) {
    if (props.role === UserTaskRole.Advisor) {
      resetLinkedItems() // if the leader accepts the first decision, the relevant values must be "replaced" at the advisors end
      const solutions = decision.additionalSolutions as LinkedWord[]
      const targetWords = []

      for (const solution of solutions) {
        // TODO: this doesn't have a start Element attached to it
        const startId = solution.startElementId
        if (startId) {
          const searchWord = getWordByElementId(startId)
          if (searchWord) {
            const target = state.initialWords.find((word) => word.id === searchWord.id)
            if (target && targetWords.findIndex((w) => w.id === target.id) === -1)
              targetWords.push(target)
          }
        }

        const endId = solution.endElementId
        if (endId) {
          const searchWord = getWordByElementId(endId)
          if (searchWord) {
            const target = state.initialWords.find((word) => word.id === searchWord.id)
            if (target && targetWords.findIndex((w) => w.id === target.id) === -1)
              targetWords.push(target)
          }
        }
      }
      for (const word of targetWords) attemptToAddNewItem(word) // only attempte to add the item if it also exists in the solution array -> words and solutions length could mismatch
    }
    confirmDecision()
  }
  stateSetters.trackingData = tracking
}

const getWordByElementId = (id: string) => {
  return state.words.find((w) => w.text === id)
}

const calculatedWordPosition = computed(() => {
  const styles: string[] = []
  const wrapperWidth = wrapperDimensions.value?.width || 0
  const wrapperHeight = wrapperDimensions.value?.height || 0
  const radius = wrapperHeight * 0.4
  const fields = state.words
  const width = wrapperWidth
  const height = wrapperHeight
  let angle = 0
  const step = (2 * Math.PI) / fields.length

  const wordElements = state.words
  for (let i = 0; i < wordElements.length; i++) {
    //const dimensions = wordElements[i].getBoundingClientRect()
    const x = Math.round(width / 2 + radius * Math.cos(angle) - 40),
      y = Math.round(height / 2 + radius * Math.sin(angle) - 20)
    const css = `left: ${x}px; top: ${y}px;`
    angle += step
    styles.push(css)
  }
  return styles
})

const finalDecisionRejectedCallback = () => {
  const cc = getCC()
  const result = getResult(cc)
  const choice = new Choice()
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.content = 'Start oppgave på nytt;Bekreft din første beslutning'
  choice.correct = result && !!result.correctRef && result.status === 'correct'
  choice.response = 'Start oppgave på nytt'
  choice.phase = Object.values(SyncType).indexOf(currentPhase.value)
  choice.target = task.value.allCorrectWords()
  choice.valid = true
  choice.committed = true
  choice.attempt = choiceAttempt
  tracking.choices.push(choice)
  stateSetters.trackingData = tracking
  choiceTimer = new Date()

  move = 1 // reset move to ensure further processing
  state.solutions = []
  multiplayer.actions.resetSync()
  choiceAttempt++
  task.value.resetMatched()
  resetLinkedItems()

  if (drawing.value) drawing.value.message({ message: 'morfologiTaskLineClear' })
  if (drawingFromParticipant.value)
    drawingFromParticipant.value.message({ message: 'morfologiTaskLineClear' })
  shuffleAndSync()
}

const shuffleCallback = (args: TaskCallbackParam) => {
  const items = args as string[]
  const shuffledItems = [] as Word[]
  let newIndex = 0
  for (const item of items) {
    const index = state.initialWords.findIndex((word) => word.text === item)
    shuffledItems[newIndex] = state.initialWords[index]
    newIndex++
  }
  state.words = shuffledItems.filter((i) => i)
}

const ShuffleOnRequestCallback = () => {
  shuffleAndSync(false) // trigger shuffle parcel after advisor has switched task/round
}

const shuffleAndSync = (shuffle = true) => {
  if (props.role === UserTaskRole.Leader) {
    if (shuffle) state.words = shuffleItems(state.words)
    state.words.sort((a, b) => {
      if (a.type === b.type) return 0
      else if (
        (a.type === Type9WordType.prefix && b.type === Type9WordType.root) ||
        (a.type === Type9WordType.root && b.type === Type9WordType.suffix)
      )
        return -1
      else return 1
    })
    const shuffleOrder = state.words.map((word) => {
      return word.text
    })
    emit('updateShuffleOrder', shuffleOrder) // sync the re-shuffled words
  }
}

function linkedWordsAsString(lws: LinkedWord[]): string {
  let wordString = ''
  lws.forEach((lw, i) => {
    if (i === 0) wordString = `${lw.word?.text || ''};${lw.endWord?.text || ''}`
    else wordString = wordString + `;${lw.endWord?.text}`
  })
  return wordString
}

function sendDecision() {
  const cc = getCC()
  const result = getResult(cc, true)
  const adjustedSolutions = state.solutions.map((s) => {
    const lw = s as LinkedWord
    return {
      startX: lw.startX,
      'startX%': lw['startX%'],
      startY: lw.startY,
      'startY%': lw['startY%'],
      startElementId: lw.startElementId,
      endX: lw.endX,
      'endX%': lw['endX%'],
      endY: lw.endY,
      'endY%': lw['endY%'],
      endElementId: lw.endElementId
    }
  })

  const choice = new Choice()
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.content = state.words.map((i) => i.text).join(';')
  choice.correct = result && !!result.correctRef && result.status === 'correct'
  choice.response = linkedWordsAsString(state.solutions as LinkedWord[]) //+ `;STATUS=${result.status}`
  choice.phase = Object.values(SyncType).indexOf(currentPhase.value)
  choice.target = task.value.allCorrectWords()
  choice.valid = true
  choice.committed = true
  choice.attempt = choiceAttempt
  tracking.choices.push(choice)
  stateSetters.trackingData = tracking
  choiceTimer = new Date()
  if (linkedUnconfirmedItem1) {
    emit('addSync', props.task.id, null, '', adjustedSolutions)
  } else dialog.actions.pushMessage(DialogMessageType.Warning, t('pleasedrawarrow'))
}

async function setupTask() {
  state.solutions = [] // reset on re-load or new round

  if (!task.value) {
    alert('A Type 9 task does not exist - check your Session layout in the CMS')
    return
  }

  const tempWords: Word[] = []
  for (let i = 0; i < task.value.words.length; i++) {
    const word = task.value.words[i]
    const audio = await createSound(word.audioURL)
    if (word.text) {
      const w: Word = {
        enabled: true,
        draggable: true,
        type: word.type as string,
        opacity: 1,
        id: word.text,
        audio,
        text: word.text,
        reference: word.reference
      }
      tempWords.push(w)
      state.initialWords.push(w)
      state.words.push(w)
    }
  }
  roundData.of = task.value.minimumCorrect
  stateActions.progress.progressShow(roundData.of)

  // Introduce with instruction audio
  setTimeout(() => {
    opacity.value = 1
    audioPlaying = true
    stateActions.speakLocalised(
      SpeechSounds.instructions.tasks.T9,
      () => {
        audioPlaying = false
      },
      1000
    )
  }, 1000)
  nextTick(() => {
    shuffleAndSync()
    setWindowDimensions()
  })

  window.addEventListener('resize', setWindowDimensions)
  emit('registerCallback', TaskCallbackType.FinalDecisionAccept, finalDecisionAcceptedCallback)
  emit('registerCallback', TaskCallbackType.FinalDecisionReject, finalDecisionRejectedCallback)
  emit('registerCallback', TaskCallbackType.Shuffle, shuffleCallback)
  emit('registerCallback', TaskCallbackType.ShuffleOnRequest, ShuffleOnRequestCallback)
  emit('registerCallback', TaskCallbackType.Phase, phaseCallback)
}

function checkActionEnd(event: MouseEvent | TouchEvent) {
  const y = event instanceof MouseEvent ? event.clientY : event.changedTouches[0].clientY
  const x = event instanceof MouseEvent ? event.clientX : event.changedTouches[0].clientX
  const element = document.elementFromPoint(x, y)
  if (element) {
    console.log('checkActionEnd')
    const id = element.id // element.id is set to word.text
    const index = state.words.map((w: Word) => w.text).findIndex((text: string) => text === id)
    if (index !== -1) mouseUpOnWord({ clientX: x, clientY: y }, state.words[index], element)
    else mouseUpOutsideBox()
  }
}

// this function ensures that the chosen word is in order of the correct answer...
/* function isInOrder(item: Word): boolean {
  if (!task.value.enforceOrder) return true
  else {
    return (
      (move === 1 && task.value.firstWords.indexOf(item.text) > -1) ||
      (move === 2 && task.value.secondWords.indexOf(item.text) > -1) ||
      (move === 3 && task.value.thirdWords.indexOf(item.text) > -1) ||
      (move === 4 && task.value.fourthWords.indexOf(item.text) > -1)
    )
  }
} */

function mouseMoving(event: MouseEvent | TouchEvent) {
  if (state.linkedWord && drawing.value)
    drawing.value.message({ event, message: 'morfologiTaskMousemove' })
}

function mouseDownOnWord(event: MouseEvent | TouchEvent, word: Word) {
  if (props.actionAllowed) {
    tappedItem = word
    console.log(tappedItem)
    // NOTE: this has been deactivated for the multiplayer mode... all options should be available for both Leader / Advisor
    //if (isInOrder(word) || props.role === UserTaskRole.Leader) {
    let x = 0
    let y = 0
    if (!event) return
    if (event.type.includes('touch')) {
      const te = event as TouchEvent
      x = te.changedTouches[0].clientX
      y = te.changedTouches[0].clientY
    } else {
      const me = event as MouseEvent
      x = me.clientX
      y = me.clientY
    }
    const el = document.elementFromPoint(x, y)
    if (el) {
      state.linkedWord = {
        word,
        startX: x,
        startY: y,
        startElement: el,
        startElementId: el.id,
        endElement: undefined,
        endX: 0,
        endY: 0
      }
      if (move === 1)
        // We won't see the first item again after mouseDown
        attemptToAddNewItem(word)
      const userColor = color.actions.selectColorAsHex(userGetters.myUser.value.profile.username)
      if (state.linkedWord && drawing.value)
        drawing.value.message(
          { message: 'morfologiTaskMousedown', word: state.linkedWord },
          userColor
        )
    }
    //}
  } else dialog.actions.pushMessage(DialogMessageType.Warning, t('waitforturn'))
}

function mouseUpOnWord(coords: { clientX: number; clientY: number }, item: Word, el: Element) {
  if (drawing.value) drawing.value.message({ message: 'morfologiTaskMouseup' })
  // Mouse up was on the same item as mouse down
  if (tappedItem === item) {
    playWordAudio()
    tracking.use_audio_content_items++
    return
    //resetLinkedItems()
  }

  // Mouse down item was not the same item as the last item in the arrow trail
  else if (move > 0 && move < 5) {
    let container = undefined
    if (move === 1) container = linkedUnconfirmedItem1
    else if (move === 2) container = linkedUnconfirmedItem2
    else if (move === 3) container = linkedUnconfirmedItem3
    else if (move === 4) container = linkedUnconfirmedItem4
    if (state.linkedWord && container && state.linkedWord.word?.id !== container.id)
      state.linkedWord = undefined
  } else if (move >= 5) state.linkedWord = undefined

  if (state.linkedWord) {
    const foundASlot = attemptToAddNewItem(item)
    state.linkedWord.endX = coords.clientX
    state.linkedWord.endY = coords.clientY
    state.linkedWord.endElement = el
    state.linkedWord.endElementId = el.id
    state.linkedWord.endWord = item

    if (foundASlot && drawing.value) {
      //const cc = getCC()
      //const result = getResult(cc, true)

      const userColor = color.actions.selectColorAsHex(userGetters.myUser.value.profile.username)
      if (move <= 5)
        drawing.value.message({ message: 'morfologiTaskLine', word: state.linkedWord }, userColor)
      if (state.linkedWord) state.solutions.push(state.linkedWord)
      move++ // increase move count regardless of truthiness
    }
  }
}

function drawLineForPhase(linkedWord: LinkedWord) {
  if (drawingFromParticipant.value) {
    const userName = multiplayer.getters.participantInformation.value.name
    const userColor = color.actions.selectColorAsHex(userName)
    if (userName && userColor) {
      drawingFromParticipant.value.message(
        { message: 'morfologiTaskLine', word: linkedWord },
        userColor
      )
    } else drawingFromParticipant.value.message({ message: 'morfologiTaskLine', word: linkedWord })
  }
}

function playWordAudio() {
  if (tappedItem && tappedItem.audio && !audioPlaying) {
    audioPlaying = true
    tappedItem.audio.onended = () => {
      tappedItem = undefined
      audioPlaying = false
    }
    tappedItem.audio.playWhenReady()
  }
}

function mouseUpOutsideBox() {
  if (drawing.value) drawing.value.message({ message: 'morfologiTaskMouseup' })
  console.log(move)
  if (move === 1) {
    linkedUnconfirmedItem1 =
      linkedUnconfirmedItem2 =
      linkedUnconfirmedItem3 =
      linkedUnconfirmedItem4 =
        undefined
  }
  const choice = new Choice()
  choice.phase = Object.values(SyncType).indexOf(currentPhase.value)
  choice.createdAt = new Date()
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.target = task.value.allCorrectWords()
  choice.content = state.words.map((w) => w.text).join(';')
  choice.round = roundData.correct

  tracking.choices.push(choice)
  choiceTimer = new Date()
}

function resetLinkedItems() {
  state.linkedWord = undefined
  linkedUnconfirmedItem1 = undefined
  linkedUnconfirmedItem2 = undefined
  linkedUnconfirmedItem3 = undefined
  linkedUnconfirmedItem4 = undefined
}

function getResult(cc: Type9Correct, noMemory = false) {
  const result: { status: string; correctRef?: Type9Correct } = task.value.hasCombinationStatus(
    cc,
    discoveredCombinations.value,
    noMemory
  ) // Result is the correct combination including audio
  return result
}

function getCC() {
  const cc: Type9Correct = {
    firstWord: (linkedUnconfirmedItem1 && linkedUnconfirmedItem1.reference) || WordIndexName.None,
    secondWord: (linkedUnconfirmedItem2 && linkedUnconfirmedItem2.reference) || WordIndexName.None,
    thirdWord: (linkedUnconfirmedItem3 && linkedUnconfirmedItem3.reference) || WordIndexName.None,
    fourthWord: (linkedUnconfirmedItem4 && linkedUnconfirmedItem4.reference) || WordIndexName.None,
    audioURL: '',
    audio: undefined,
    matched: false
  }
  return cc
}

// Returns true = correct, false = incorrect
function confirmDecision(): boolean {
  const cc = getCC()
  const result = getResult(cc)

  const choice = new Choice()
  choice.duration = moment().diff(moment(choiceTimer), 'milliseconds')
  choice.content = 'Start oppgave på nytt;Bekreft din første beslutning'
  choice.correct = result && !!result.correctRef && result.status === 'correct'
  choice.response = 'Bekreft din første beslutning'
  choice.phase = Object.values(SyncType).indexOf(currentPhase.value)
  choice.target = task.value.allCorrectWords()
  choice.valid = true
  choice.committed = true
  choice.attempt = choiceAttempt
  tracking.choices.push(choice)
  stateSetters.trackingData = tracking
  choiceTimer = new Date()

  if (result.status === 'correct' && result.correctRef) {
    // Correct combination

    linkedUnconfirmedItem1 =
      linkedUnconfirmedItem2 =
      linkedUnconfirmedItem3 =
      linkedUnconfirmedItem4 =
        undefined
    move = 1
    combinedWord.value = task.value.combinedWordFromCC(result.correctRef)
    if (discoveredCombinations.value.indexOf(combinedWord.value) === -1) {
      // Did we already find this word?
      multiplayer.actions.proceedRoundIndex() // round is completed -> proceed
      roundData.correct++
      const callback = () => {
        stateActions.progress.completeAStar()
        discoveredCombinations.value.push(combinedWord.value)
        state.solutions = []
        resetLinkedItems()
      }
      emit('showResultMessage', DialogMessageType.ResultSplashCorrect, combinedWord.value, callback)
    } else {
      // TODO: add already discovered message
    }
    // Remove lines from screen
    setTimeout(() => {
      combinedWord.value = ''
      if (drawing.value) drawing.value.message({ message: 'morfologiTaskLineClear' })
      if (drawingFromParticipant.value)
        drawingFromParticipant.value.message({ message: 'morfologiTaskLineClear' })
    }, 2000)

    if (roundData.of === roundData.correct) {
      setTimeout(() => {
        if (stateGetters.state.value.taskMode === TaskMode.Warmups) {
          stateActions.speakLocalised(
            SpeechSounds.instructions.warmups.T9,
            () => completeTask(),
            1000,
            false
          )
        } else completeTask()
      }, 1500)
    }
    return true
  } else {
    const callback = () => {
      //Incorrect choice
      state.solutions = []
      resetLinkedItems()
      if (drawing.value) drawing.value.message({ message: 'morfologiTaskLineClear' })
      if (drawingFromParticipant.value)
        drawingFromParticipant.value.message({ message: 'morfologiTaskLineClear' })
      state.linkedWord = undefined
      multiplayer.actions.resetSync()
      choiceAttempt++

      shuffleAndSync()
      linkedUnconfirmedItem1 =
        linkedUnconfirmedItem2 =
        linkedUnconfirmedItem3 =
        linkedUnconfirmedItem4 =
          undefined
      move = 1
    }
    emit('showResultMessage', DialogMessageType.ResultSplashWrong, combinedWord.value, callback)
    return false
  }
}

// Ensure each linked item is a different word
function attemptToAddNewItem(item: Word): boolean {
  let success = false
  if (!linkedUnconfirmedItem1) {
    linkedUnconfirmedItem1 = item
    success = true
  } else if (linkedUnconfirmedItem1 === item) success = false
  else if (!linkedUnconfirmedItem2) {
    if (linkedUnconfirmedItem1.reference !== item.reference) linkedUnconfirmedItem2 = item
    success = true
  } else if (linkedUnconfirmedItem2 === item) success = false
  else if (!linkedUnconfirmedItem3) {
    if (
      linkedUnconfirmedItem1.reference !== item.reference &&
      linkedUnconfirmedItem2.reference !== item.reference
    )
      linkedUnconfirmedItem3 = item
    success = true
  } else if (linkedUnconfirmedItem3 === item) success = false
  else if (!linkedUnconfirmedItem4) {
    if (
      linkedUnconfirmedItem1.reference !== item.reference &&
      linkedUnconfirmedItem2.reference !== item.reference &&
      linkedUnconfirmedItem3.reference !== item.reference
    )
      linkedUnconfirmedItem4 = item

    success = true
  } else if (linkedUnconfirmedItem4 === item) success = false

  if (!success && move === 1) linkedUnconfirmedItem1 = undefined

  return success
}

const completeTask = () => {
  opacity.value = 0
  setTimeout(() => {
    emit('completed', true, tracking)
  }, 1000)
}
</script>

<style scoped lang="postcss">
.scanner-content {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/images/circular-background.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 65vh;
}
.completedWords {
  position: absolute;
  top: 50%;
  right: 10%;
  color: #00ff45;
  font-size: 14pt;
  text-align: left;
  outline: none;

  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px 15px 10px 10px;
  border-radius: 20px;
}
</style>
