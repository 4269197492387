const globalTranslations = {
  no: {
    cancel: 'Avbryt',
    accept: 'Bekreft',
    done: 'Ferdig',
    back: 'Tilbake',
    confirm: 'Bekreft',
    send: 'Send',
    save: 'Lagre',
    add: 'Legg til',
    remove: 'Fjerne',
    edit: 'Rediger',
    continue: 'Fortsett',
    ready: 'Klar',
    start: 'Start',
    next: 'Neste',
    exit: 'Avslutt',
    previous: 'Forrige',
    finaldecision1: 'har',
    finaldecision1Leader: 'har',
    accepted: 'godkjent',
    rejected: 'avvist',
    finaldecision2: 'løsningen',
    finaldecision3: 'rådet',
    you: 'Du',
    waitforturn: 'Vennligst vent for din tur',
    wrongdecision: 'Denne løsningen var feil, prøv igjen',
    waitingonparticipant: 'Venter på annen deltaker',
    loading: 'Laster data',
    participantready: 'Deltaker er klar!',
    waitforadvisorschoice: 'Vent på rådgiverens råd.',
    advisorschoice: 'Du er rådgiveren, du kan velge nå.',
    makefinaldecision:
      'Rådgiveren har gitt sitt råd, du kan bestemme om du vil godta din første beslutning.',
    waitforfinaldecision: 'Vent inntil lederen har gitt sin beslutning.',
    //youareleader: 'Du er lederen, du kan velge først.',
    //youareadvisor: 'Du er rådgiveren, vennligst vent for lederens første beslutning.',
    youareleader: 'Du er lederen!',
    youareadvisor: 'Du er rådgiveren!',
    firstdecision: 'Første beslutning',
    advice: 'Råd',
    finaldecision: 'Beslutning',
    startgame: 'Start spill',
    loginwithpassword: 'Passord',
    loginwithfeide: 'FEIDE',
    loginwithsms: 'SMS',
    acceptadvice: 'Godta råd',
    chooseagain: 'Start oppgaven på nytt',
    confirmchoice: 'Bekreft valg',
    acceptfirstdecision: 'Bekreft din første beslutning',
    correct: 'Riktig',
    wrong: 'Feil',
    singleplayer: 'Enspiller',
    multiplayer: 'Flerspiller',
    studentteacher1: 'Koblet til læreren',
    studentteacher2: 'Koblet til eleven',
    logout: 'Logg av',
    chooseavatar: 'Velg avatar',
    monitor: 'Admin side',
    gameleft: 'forlot spillet',
    nogameavailable: 'Ingen spill tilgjengelig',
    clicktostart: 'Klikk på spillet nedenfor for å starte',
    pairingupdated: 'Spill-valget ditt har blitt oppdatert av en lærer',
    greeting: 'Heisann!',
    lackingdetails:
      'Du mangler noen profildetaljer, vennligst oppgi alder og førstespråk før du går videre.',
    agegroup: 'Alder',
    firstlanguage: 'Mitt førstespråk',
    pleasedrawarrow: 'Vennligst dra minst en pil før du sender inn ditt svar',
    sessioncompleted: 'Økten ble gjennomført, bra jobba!',
    youreLeader: 'Du er leder',
    youreAdvisor: 'Du er rådgiver'
  },
  sv: {
    cancel: 'Avbryt',
    done: 'Färdig',
    back: 'Tillbaka',
    save: 'Spara',
    add: 'Lägg till',
    remove: 'Ta bort',
    edit: 'Redigera',
    continue: 'Fortsätt',
    start: 'Start',
    next: 'Nästa',
    chooseavatar: 'Väjl avatar',
    monitor: 'Admin panel'
  },
  en: {
    cancel: 'Cancel',
    accept: 'Accept',
    done: 'Done',
    back: 'Back',
    confirm: 'Confirm',
    send: 'Send',
    save: 'Save',
    add: 'Add',
    remove: 'Remove',
    edit: 'Edit',
    continue: 'Continue',
    ready: 'Ready',
    start: 'Start',
    next: 'Next',
    exit: 'Exit',
    previous: 'Previous',
    finaldecision1: 'has',
    finaldecision1Leader: 'have',
    accepted: 'accepted',
    rejected: 'rejected',
    finaldecision2: 'the solution',
    finaldecision3: 'the advice',
    you: 'You',
    waitforturn: 'Please wait for your turn',
    wrongdecision: 'This solution was wrong, try again',
    waitingonparticipant: 'Waiting on participant',
    loading: 'Loading',
    participantready: 'Participant is ready',
    waitforadvisorschoice: "Wait for the advisors' choice",
    advisorschoice: 'You are the advisor, make your choice!',
    makefinaldecision: 'The advisor has made their choice, you can decide on the final decision!',
    waitforfinaldecision: "Wait until the leader has made it's final decision",
    //youareleader: "You're the leader, make your first decision!",
    //youareadvisor: "You are the advisor, please wait for the leaders' first decision",
    youareleader: "You're the leader!",
    youareadvisor: 'You are the advisor!',
    firstdecision: 'First decision',
    advice: 'Advice',
    finaldecision: 'Final decision',
    startgame: 'Start game',
    loginwithpassword: 'Password',
    loginwithfeide: 'FEIDE',
    loginwithsms: 'SMS',
    acceptadvice: 'Accept advice',
    confirmchoice: 'Confirm choice',
    chooseagain: 'Choose again',
    acceptfirstdecision: 'Accept first decision',
    correct: 'Correct',
    wrong: 'Wrong',
    singleplayer: 'Single player',
    multiplayer: 'Multiplayer',
    studentteacher1: 'Connected with teacher',
    studentteacher2: 'Connected with student',
    logout: 'Log out',
    chooseavatar: 'Choose avatar',
    monitor: 'Admin panel',
    gameleft: 'left the game',
    nogameavailable: 'No game available',
    clicktostart: 'Click on the available game to start',
    pairingupdated: 'Your game selection was changed by a teacher',
    greeting: 'Hey there!',
    lackingdetails:
      'You are lacking some information, please enter age and first language before you continue.',
    agegroup: 'Age',
    firstlanguage: 'My first language',
    pleasedrawarrow: 'Please drag at least one arrow before sending your choice',
    sessioncompleted: 'The sesssion was completed, good job!',
    youreLeader: 'You are the leader',
    youreAdvisor: 'You are the advisor'
  }
}

export { globalTranslations }
